<script setup lang="ts">
import moment from 'moment';
import { ref, onMounted } from 'vue';
import NocRepository from '../../repositories/noc.repository';

interface INocLog {
  createdAt: string;
  id: number;
  message: string;
  origin: string;
  request: string;
  statusCode: number;
  updatedAt: string;
}

interface ILogRequest {
  method: string;
  path: string;
  body: Record<string, any>;
  query: Record<string, any>;
  params: Record<string, any>;
  headers: Record<string, string>;
}

const logsMailRepository = new NocRepository();
const nocLogsData = ref<INocLog[]>([]);
const showModal = ref<boolean>(false);
const logRequest = ref<ILogRequest | null>(null);
const paginationData = ref();
const currentPage = ref<number>();

const getNocLogsAPI = async (page: number) => {
  const { data } = await logsMailRepository.getlogsMail({
    page,
  });
  nocLogsData.value = data.nocLogs ?? [];
  paginationData.value = data.pagination;
};

onMounted(async () => {
  getNocLogsAPI(1);
});

const fieldsTable: any = ref([
  {
    key: 'id',
    label: 'Id',
    sortable: true,
  },
  {
    key: 'origin',
    label: 'Origin',
  },
  {
    key: 'statusCode',
    label: 'Status Code',
  },
  {
    key: 'message',
    label: 'Mensaje',
  },
  {
    key: 'createdAt',
    label: 'Fecha',
    formatter: (e: any) =>
      e ? moment(e).format('DD-MM-YYYY HH:mm') : 'Sin fecha',
  },
  {
    key: 'actions',
    label: 'Acciones',
  },
]);
const onChangePage = async (page: any) => {
  getNocLogsAPI(page);
  currentPage.value = page;
};

const handleOpenModal = (item: any) => {
  showModal.value = true;
  logRequest.value = JSON.parse(item.request);
};
const handleCloseModal = () => {
  showModal.value = false;
  logRequest.value = null;
};
</script>

<template>
  <Layout title="NOC" :textColor="'color-secondary'">
    <template #layout>
      <Table
        :fields="fieldsTable"
        :items="nocLogsData"
        @update:model-value-page="onChangePage"
        :totalRows="paginationData?.count"
        :perPage="20"
        :currentPage="paginationData?.current_page"
      >
        <template v-if="true" v-slot:cell(actions)="logsData">
          <div>
            <button
              class="background-pink p-1 border-0 w-100 text-white rounded-4"
              @click="handleOpenModal(logsData.data.item)"
            >
              <span>Ver Request</span>
            </button>
          </div>
        </template>
      </Table>
      <BModal
        :modelValue="showModal"
        hide-footer
        :centered="true"
        @hide="handleCloseModal"
        title="Request Data"
        size="xl"
        static
        v-if="logRequest"
      >
        <p><strong>Method:</strong> {{ logRequest.method }}</p>
        <p><strong>Path:</strong> {{ logRequest.path }}</p>
        <p><strong>Body:</strong> {{ logRequest.body }}</p>
        <p><strong>Query:</strong> {{ logRequest.query }}</p>
        <p><strong>Params:</strong> {{ logRequest.params }}</p>

        <h5>Headers:</h5>

        <ul v-for="(value, key) in logRequest.headers" :key="key">
          <li>
            <strong>{{ key }}:</strong> {{ value }}
          </li>
        </ul>
      </BModal>
    </template>
  </Layout>
</template>
