import moment from 'moment';
import momentTimeZone from 'moment-timezone';
const CHUBB_TIME_ZONE = import.meta.env.VITE_CHUBB_TIME_ZONE

function isTimeGreaterThan90MinutesFromNow(dateStr: Date, timeStr: string, timeZone: string) {
    const targetTime = momentTimeZone.tz(`${dateStr} ${timeStr}`, timeZone);
    const currentTime = moment().tz(timeZone);
  
    // Calculate the difference in minutes between the target time and the current time in the comparison time zone
    const differenceInMinutes = targetTime.diff(currentTime, 'minutes');
  
    // Check if the difference is greater than 90 minutes
    return differenceInMinutes < 90;
  }
  
  
  export const wrongDepartureDateTime = (date: Date, time: string) => {
    const result = isTimeGreaterThan90MinutesFromNow(date, time, CHUBB_TIME_ZONE);
  
    return result
  }