import axios from 'axios';

interface DolarAPIResponse {
  venta: string;
}

interface BCRAResponse {
  results: { idVariable: number; valor: string }[];
}

const getDolarArgentina = async (): Promise<number> => {
  let exchangeRate: number | null = null;

  try {
    const { data: consulta1 } = await axios.get<DolarAPIResponse>(
      'https://dolarapi.com/v1/dolares/oficial',
    );
    exchangeRate = parseFloat(consulta1.venta);
  } catch (error) {
    console.error(
      'Failed to fetch from DolarAPI, attempting backup API',
      error,
    );
    try {
      const { data: consulta2 } = await axios.get<BCRAResponse>(
        'https://api.bcra.gob.ar/estadisticas/v1/principalesvariables',
      );
      const variable = consulta2.results.find((e) => e.idVariable === 4);
      if (variable) {
        exchangeRate = parseFloat(variable.valor);
      } else {
        throw new Error('Exchange rate not found in BCRA response');
      }
    } catch (backupError) {
      console.error('Failed to fetch from BCRA API', backupError);
      throw new Error('Unable to fetch exchange rate from both APIs');
    }
  }

  if (exchangeRate === null) {
    throw new Error('Exchange rate is null after both API attempts');
  }

  return exchangeRate;
};

export default getDolarArgentina;
