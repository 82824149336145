<script setup lang="ts">
import { toRefs, ref, onMounted, watch, reactive, onUpdated } from 'vue';

// pinia
import StoreAuth from '@store/auth';
import Store from '@store/dataProvider';

// composables
import { useMessage } from '../../../composables/useMessage';
import { useToast } from '../../../composables/useToast';

// types
import { IUser, IRole, IPermission } from '../../../types/user.type';
import { IAgents } from './../../../types/agents.type';
import { ITemplateUI } from '../../../types/templatesUI.type';

// repositories
import UserApi from '@repositories/user.repository';

// validations
import { insertHtmlValidations, validateFormat } from './handleValidator';
import { ICountry } from '../../../types/countries.type';
import CountriesRepository from '../../../repositories/countries.repository';

const store = Store();
const storeAuthProvider = StoreAuth();

const userApiRepository = new UserApi();

const { $toast } = useToast();
const { messageHTML, showMessage } = useMessage();

const props = defineProps({
  userData: {
    type: Object as () => IUser,
    default: () => ({
      user: {
        apiKey: {},
        apiKeyId: '',
        createdAt: '',
        email: '',
        id: '',
        permissionDescriptions: [],
        rolesDescriptions: [],
        countries: [],
      },
    }),
  },
  agents: {
    type: Object as () => Array<IAgents>,
  },
  templatesUI: {
    type: Object as () => Array<ITemplateUI>,
    default: () => ({
      templatesUI: {
        id: '',
        name: '',
      },
    }),
  },
});

const emit = defineEmits<{
  (e: 'closeModal'): void;
}>();

const RolesData = ref<IRole[]>([]);
const isLoading = ref(true);
const filterRolesRef = ref<IRole[]>([]);
const countriesData = ref<ICountry[]>([]);
const permissionsActive = ref<IPermission[]>([]);
const selectedRoles = ref<IRole[]>([]);
const toogleViewRoles = ref<boolean>(true);
const passwordValidate: any = reactive({
  containNumber: /.*\d+.*/,
  containUppercase: /[A-Z]/,
  containLowercase: /[a-z]/,
  containSymbol: /.*[\W_].*/,
  minLength: /^.{8,}$/,
});

onMounted(async () => {
  const data = await userApiRepository.getRoles();
  const dataCountries = await CountriesRepository.getcountries();
  countriesData.value = dataCountries.data.countries.map((e: ICountry) => ({
    id: e.id,
    nombre: e.description,
  }));
  RolesData.value = data;
  isLoading.value = false;
});

onUpdated(async () => {
  insertHtmlValidations(passwordValidate);
});

const { userData, agents, templatesUI } = toRefs(props);
const user = userData.value;
watch(RolesData, (newRolesData) => {
  if (!isLoading.value && userData.value && userData.value.rolesDescriptions) {
    const namesToFilter = userData.value.rolesDescriptions;
    filterRolesRef.value = newRolesData.filter((role: any) =>
      namesToFilter.includes(role.nombre),
    );
  }
});

const fieldsUpdateUser: any = ref([
  {
    key: 'email',
    title: 'Email del Usuario',
    type: 'email',
    validations: {
      rules: {
        required: true,
        email: true,
      },
    },
    placeholder: 'Email del Cliente',
    value: user.email,
    cols: 6,
  },
  {
    key: 'fullName',
    title: 'Nombre Completo',
    type: 'text',
    validations: {
      rules: {
        required: true,
      },
    },
    placeholder: 'Nombre Completo',
    value: user.fullName,
    cols: 6,
  },
  {
    key: 'apiKeyId',
    title: 'Compañía',
    type: 'select',
    placeholder: 'Elije la compañia',
    value: [
      { nombre: user.apiKey?.appName || 'Ninguna', id: user.apiKey?.id || 3 },
    ],
    selectLabel: null,
    cols: 6,
    options: [
      { nombre: 'Vink', id: 1 },
      { nombre: 'ControlMatch', id: 2 },
      { nombre: 'Ninguna', id: 3 },
    ],
  },
  {
    key: 'agents',
    customLabel: (e: any) => `${e.name} - [${e.rfc}]`,
    searchable: true,
    title: 'Selecciona los agentes asociados',
    type: 'select',
    placeholder: 'selecciona uno, varios o ninguno',
    value: user.agents,
    cols: 6,
    options: agents?.value,
    multiple: true,
  },
  {
    key: 'countries',
    title: 'Selecciona los paises del usuario',
    selectLabel: null,
    type: 'select',
    placeholder: 'Seleccionar Paises',
    value: user.countries.map((e: ICountry) => ({
      id: e.id,
      nombre: e.description,
    })),
    cols: 6,
    options: countriesData,
    validations: {
      rules: {
        required: true,
      },
    },
    hidden: false,
    multiple: true,
    listenSelect: false,
    listenRemove: false,
  },
  {
    key: 'roles',
    title: 'Selecciona los roles del usuario',
    selectLabel: null,
    type: 'select',
    placeholder: 'Seleccionar Roles',
    value: filterRolesRef,
    cols: 6,
    options: RolesData,
    validations: {
      rules: {
        required: true,
      },
    },
    hidden: false,
    multiple: true,
    listenSelect: true,
    listenRemove: true,
  },
  {
    key: 'hasTwoFactorAuthentication',
    title: 'Segundo factor de autenticación',
    type: 'select',
    placeholder: 'Elije una opcion',
    value: [
      {
        nombre: !user.hasTwoFactorAuthentication ? 'NO' : 'SI',
        id: user.hasTwoFactorAuthentication,
      },
    ],
    selectLabel: null,
    cols: 6,
    options: [
      { nombre: 'NO', id: 0 },
      { nombre: 'SI', id: 1 },
    ],
  },
  {
    key: 'templateId',
    title: 'Plantilla',
    type: 'select',
    placeholder: 'Elije una opcion',
    value: user.templateId
      ? [
          {
            nombre: templatesUI.value.find((e: any) => e.id == user.templateId)
              ?.name,
            id: user.templateId,
          },
        ]
      : [{ nombre: 'Ninguna', id: 0 }],
    selectLabel: null,
    cols: 6,
    options: templatesUI?.value
      ?.map((e) => ({ nombre: e.name, id: e.id }))
      .concat([{ nombre: 'Ninguna', id: 0 }]),
  },
]);

const fieldsUpdatePassword: any = ref([
  {
    key: 'password',
    title: 'Contraseña',
    type: 'password',
    validations: {
      rules: {
        required: true,
        min: 8,
        verifyExpreRegular:
          /^(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\-])(?=.*[A-Z])(?=.*[a-z])(?=.*\d).+$/,
      },
    },
    placeholder: 'Ejemplo.123',
    value: null,
    cols: 6,
    customClass: 'inputs-password',
    formatValue: (e: any) => validateFormat(e, passwordValidate),
  },
  {
    key: 'repeatPassword',
    title: 'Repetir Contraseña',
    type: 'password',
    validations: {
      rules: {
        required: true,
      },
    },
    placeholder: 'Repetir contraseña',
    value: null,
    cols: 6,
  },
]);

const handleSelect = async (e: any) => {
  if (selectedRoles.value.length === 0) {
    selectedRoles.value = e.value;
    const { data } = await userApiRepository.getPermissionsByRoleId(
      e.value[0].id,
    );
    permissionsActive.value = data;
  } else {
    const differentObject = e.value.find(
      (itemA: any) =>
        !selectedRoles.value.some((itemB) => itemA.id === itemB.id),
    );

    if (differentObject) {
      selectedRoles.value = e.value;

      const { data } = await userApiRepository.getPermissionsByRoleId(
        differentObject.id,
      );

      permissionsActive.value = [...permissionsActive.value, ...data];
      permissionsActive.value = permissionsActive.value.filter(
        (item, index, self) =>
          index === self.findIndex((obj) => obj.id === item.id),
      );
    }
  }
};

const handleRemove = async (e: any) => {
  selectedRoles.value = selectedRoles.value.filter((i) => i.id !== e.value.id);

  if (!selectedRoles.value.length) {
    permissionsActive.value = [];
  }

  for (const role of selectedRoles.value) {
    const { data } = await userApiRepository.getPermissionsByRoleId(role.id);

    permissionsActive.value = data;
  }
};

const handleUpdateUser = async ({ items, isFormValid }: any) => {
  if (!isFormValid) return;

  const updateUserData = {
    ...items,
    templateId: items.templateId.id,
    apiKeyId:
      items.apiKeyId.id === 3 || !items.apiKeyId.id ? null : items.apiKeyId.id,
    hasTwoFactorAuthentication: items.hasTwoFactorAuthentication.id,
  };

  try {
    const { data } = await userApiRepository.updateUser(
      user.id,
      updateUserData,
    );
    if (!data) throw new Error();
    if (
      storeAuthProvider.user &&
      data.templateId &&
      data.id === storeAuthProvider.user.id
    ) {
      const template = templatesUI?.value?.find(
        (e) => e.id === data.templateId,
      );
      if (template) {
        storeAuthProvider.setProp({
          key: 'user',
          index: 'template',
          value: template,
        });
      }
    } else {
      storeAuthProvider.setProp({
        key: 'user',
        index: 'template',
        value: null,
      });
    }
    let index = store.users.findIndex((value: any) => value.id === data.id);
    if (index > -1) {
      store.setProp({ key: 'users', index, value: data });
    }
    emit('closeModal');
  } catch (error: any) {
    showMessage(error.message, 'error');
  }
};

const handleToggleButtonClick = () => {
  toogleViewRoles.value = !toogleViewRoles.value;
};

const handleUpdatePassword = async ({ items, isFormValid }: any) => {
  if (!isFormValid) return;
  if (items.password !== items.repeatPassword) {
    return showMessage('Las contraseñas deber ser iguales', 'error');
  } else {
    showMessage(null);
  }

  try {
    await userApiRepository.updateUser(user.id, { password: items.password });

    $toast?.success(`La contraseña ha sido cambiada correctamente`);

    emit('closeModal');
  } catch (error: any) {
    showMessage(error.message, 'error');
  }
};
</script>

<template>
  <CButton
    class="background-pink button__form mt-3 w-100"
    style="padding: 0.5rem !important"
    type="button"
    @click="handleToggleButtonClick"
  >
    <template #content>{{
      toogleViewRoles ? 'Ir a cambiar contraseña' : 'Ir a actualizar usuario'
    }}</template>
  </CButton>
  <div v-if="toogleViewRoles">
    <Form
      ref="refTableForm"
      :inputs="fieldsUpdateUser"
      @onSubmit="handleUpdateUser"
      @select="handleSelect"
      @remove="handleRemove"
    >
      <template #buttons>
        <p v-if="permissionsActive.length">
          Permisos Activos para roles asignados
        </p>
        <ul v v-for="permission in permissionsActive" :key="permission.id">
          <li>{{ permission.descripcion }}</li>
        </ul>
        <div v-if="messageHTML" v-html="messageHTML" />
        <div class="d-flex">
          <CButton class="w-100 fs-6 p-2" background="background-blue">
            <template #content>
              <div>
                <span>Actualizar</span>
              </div>
            </template>
          </CButton>
        </div>
      </template>
    </Form>
  </div>
  <div v-if="!toogleViewRoles">
    <p class="m-0">Usuario: {{ user.email }}</p>
    <Form
      ref="refTableForm"
      :inputs="fieldsUpdatePassword"
      @onSubmit="handleUpdatePassword"
    >
      <template #buttons>
        <div v-if="messageHTML" v-html="messageHTML" />
        <div class="d-flex">
          <CButton class="w-100 fs-6 p-2" background="background-blue">
            <template #content>
              <div>
                <span>Actualizar Contraseña</span>
              </div>
            </template>
          </CButton>
        </div>
      </template>
    </Form>
  </div>
</template>
