<script setup lang="ts">
import { ref, onMounted } from 'vue';

// Pinia
import StoreAuth from '@store/auth';
import Store from '@store/dataProvider';
import { storeToRefs } from 'pinia';

// Composables
import { useToast } from '../../../composables/useToast';
import useAuthStore from '@store/auth';

// Types
import { ITemplateUI } from '../../../types/templatesUI.type';

// Repositories
import TemplatesUIDataProviderRepository from '../../../repositories/templatesUI.repository';

// Components
import ModalCreateTemplate from './ModalCreateTemplate.vue';
import ModalUpdateTemplate from './ModalUpdateTemplate.vue';
import ModalDialogTemplate from './ModalDialogTemplate.vue';

const store = Store();
const storeAuthProvider = StoreAuth();
const { templatesUI }: any = storeToRefs(store);
const { user } = useAuthStore();

const { $toast } = useToast();

const templatesUIRepository = new TemplatesUIDataProviderRepository();

const templatesUIData = ref<ITemplateUI[]>([]);
const paginationData = ref();
const currentPage = ref<number>();
const isModalOpen = ref<boolean>(false);
const modalType = ref<string | null>(null);
const modalTitle = ref<string>();
const selectedTemplateUIInfo = ref<ITemplateUI | null>(null);

const getTemplateUIAPI = async (page: number) => {
  const data = await templatesUIRepository.getTemplates({
    page,
  });

  if (!data) return;
  if (Array.isArray(data?.data)) {
    templatesUIData.value = data.data;
    store.setProp({ key: 'templatesUI', value: templatesUIData.value });
  } else {
    templatesUIData.value = [];
  }
};

onMounted(async () => {
  getTemplateUIAPI(1);
});

const fieldsTable: any = ref([
  {
    key: 'id',
    label: 'Id',
    sortable: true,
  },
  {
    key: 'name',
    label: 'Nombre',
  },
  {
    key: 'urlDomain',
    label: 'Url de dominio',
  },
  {
    key: 'primaryColour',
    label: 'Color primario',
  },
  {
    key: 'userInfo',
    label: 'Editar',
  },
  {
    key: 'statusBlock',
    label: 'Estado',
  },
]);

const onChangePage = async (page: any) => {
  getTemplateUIAPI(page);
  currentPage.value = page;
};

const handleOpenModalCreate = () => {
  modalTitle.value = 'Crear Template';
  isModalOpen.value = true;
  modalType.value = 'modalCreateTemplate';
};

const handleClose = () => {
  modalTitle.value = undefined;
  isModalOpen.value = false;
  modalType.value = null;
};

const handleSelectTemplate = (templateInfo: ITemplateUI) => {
  modalTitle.value = 'Actualizar template';
  (isModalOpen.value = true), (modalType.value = 'modalUpdateTemplateUI');
  selectedTemplateUIInfo.value = templateInfo;
};

const handleChangeStatusTemplate = async (e: any) => {
  if (!e.deletedAt) {
    try {
      const { data } = await templatesUIRepository.blockTemplate(e.id);
      if (!data) throw new Error();
      let index = store.templatesUI.findIndex(
        (value: any) => value.id === data.id,
      );

      if (index > -1) {
        store.setProp({ key: 'templatesUI', index, value: data });
      }
      if (e.id === user?.template?.id) {
        storeAuthProvider.setProp({
          key: 'user',
          index: 'template',
          value: data,
        });
      }
      isModalOpen.value = true;
      modalType.value = 'modalDialogTemplate';

      $toast?.success(`Plantilla bloqueada con exito`);
      return;
    } catch (error) {
      $toast?.error(`Ocurrio un error`);
    }
    return;
  }
  try {
    const { data } = await templatesUIRepository.unBlockTemplate(e.id);
    if (!data) throw new Error();
    let index = store.templatesUI.findIndex(
      (value: any) => value.id === data.id,
    );
    if (index > -1) {
      store.setProp({ key: 'templatesUI', index, value: data });
    }
    if (e.id === user?.template?.id) {
      storeAuthProvider.setProp({
        key: 'user',
        index: 'template',
        value: data,
      });
    }

    $toast?.success(`Plantilla desbloqueada con exito`);
  } catch (error) {
    $toast?.error(`Ocurrio un error`);
  }
};
</script>

<template>
  <Layout title="Plantillas" :textColor="'color-secondary'">
    <template #layout>
      <div class="d-flex gap-2 justify-content-end">
        <CButton
          class="background-pink button__form"
          style="padding: 0.5rem !important"
          type="button"
          @click="handleOpenModalCreate"
        >
          <template #content> Crear Template </template>
        </CButton>
      </div>
      <Table
        :fields="fieldsTable"
        :items="templatesUI"
        @update:model-value-page="onChangePage"
        :totalRows="paginationData?.count"
        :perPage="20"
        :currentPage="paginationData?.current_page"
      >
        <template v-if="true" v-slot:cell(primaryColour)="templatesData">
          <div
            class="colour-circle"
            :style="
              ' background-color: ' + templatesData.data.item.primaryColour
            "
          ></div>
        </template>

        <template v-if="true" v-slot:cell(userInfo)="templatesData">
          <div
            @click="handleSelectTemplate(templatesData.data.item)"
            class="button-icon"
          >
            <img src="/icons/editar.svg" />
          </div>
        </template>
        <template v-if="true" v-slot:cell(statusBlock)="templatesData">
          <div @click="handleChangeStatusTemplate(templatesData.data.item)">
            <img
              class="cursor-pointer"
              :src="
                templatesData.data.item.deletedAt
                  ? '/icons/cross-circle.svg'
                  : '/icons/check-circle.svg'
              "
              style="width: 40px"
            />
          </div>
        </template>
      </Table>
      <BModal
        :modelValue="isModalOpen"
        hide-footer
        :centered="true"
        @hide="handleClose"
        static
        :title="modalTitle"
      >
        <template v-if="modalType === 'modalCreateTemplate'">
          <ModalCreateTemplate @closeModal="handleClose" />
        </template>

        <template v-if="modalType === 'modalUpdateTemplateUI'">
          <ModalUpdateTemplate
            v-if="selectedTemplateUIInfo"
            :templateUI="selectedTemplateUIInfo"
            @closeModal="handleClose"
          />
        </template>

        <template v-if="modalType === 'modalDialogTemplate'">
          <ModalDialogTemplate @closeModal="handleClose" />
        </template>
      </BModal>
    </template>
  </Layout>
</template>

<style scoped>
.colour-circle {
  margin-left: 30px;
  margin-top: -10px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
}
</style>
