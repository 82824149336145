<script setup lang="ts">
import { ref, onBeforeMount, computed, watch } from 'vue';

// Pinia
import Store from '@store/dataProvider';
import { storeToRefs } from 'pinia';

//composables
import { useToast } from '../../composables/useToast';
import { IAgents } from './../../types/agents.type';

//types
import { FieldsTable, IPaginationResp } from '../../types/index';
import { IClient } from './../../types/client.type';
import { ITemplateUI } from '../../types/templatesUI.type';

// repositories
import AgentsDataProviderRepository from '@repositories/agents.repository';
import ClientsDataProviderRepository from '@repositories/clients.repository';
import TemplatesUIDataProviderRepository from './../../repositories/templatesUI.repository';

// utils
import { fieldsAgents } from '@services/forms/fieldsAgents';

const store = Store();
const { agents }: any = storeToRefs(store);

const { $toast } = useToast();

const agentsProviderController = new AgentsDataProviderRepository();
const clientsProviderController = new ClientsDataProviderRepository();
const templatesUIRepository = new TemplatesUIDataProviderRepository();

const agentToDelete = ref<IAgents | null>(null);
const agentToEdit = ref<IAgents | null>(null);
const clientsData = ref<Array<IClient>>([]);
const templateData = ref<Array<ITemplateUI>>([]);
const isModalOpen = ref<boolean>(false);
const modalType = ref<string | null>(null);
const modalTitle = ref<string>();
const paginationData = ref<IPaginationResp>({
  current_page: 1,
});
const fieldsActives = ref<Array<FieldsTable>>([]);
const fieldsInactives = ref<Array<FieldsTable>>([]);
const valueMultiselect = ref<Array<any>>([]);
const deletedValues = ref<boolean>(false);

const fieldsUpdateUser = computed(() => {
  return [
    {
      key: 'name',
      title: 'Nombre del Agente',
      type: 'text',
      validations: {
        rules: {
          required: true,
        },
      },
      placeholder: 'Nuevo Agente',
      value: agentToEdit.value?.name,
      cols: 12,
    },
    {
      key: 'email',
      title: 'Email del Agente',
      type: 'email',
      validations: {
        rules: {
          required: true,
          email: true,
        },
      },
      placeholder: 'mail@ejemplo.com',
      value: agentToEdit.value?.email,
      cols: 12,
    },
    {
      key: 'rfc',
      title: 'RFC del Agente',
      type: 'text',
      validations: {
        rules: {
          required: true,
        },
      },
      placeholder: 'ABC123',
      value: agentToEdit.value?.rfc,
      cols: 12,
    },
    {
      key: 'templateId',
      label: 'name',
      value: agentToEdit.value?.templateId,
      searchable: true,
      title: 'Template',
      type: 'select',
      placeholder: 'Opcional',
      formatValue: (val:any) => val.id,
      cols: 12,
      options: templateData,
    },
    {
      key: 'clients',
      // label: 'tradeName',
      customLabel: (e: any) => `${e.tradeName} - [${e.rfc}]`,
      searchable: true,
      title: 'Selecciona los clientes asociados',
      type: 'select',
      placeholder: 'debes incluir mínimo uno',
      value: valueMultiselect.value,
      cols: 12,
      validations: {
        rules: {
          required: true,
        },
      },
      options: clientsData,
      multiple: true,
    },
  ];
});

watch(agentToEdit, (newValue) => {
  if (newValue?.clients && Array.isArray(newValue?.clients)) {
    valueMultiselect.value = newValue?.clients.filter((elem: any) => elem.id);
  } else {
    valueMultiselect.value = [];
  }
});

const getAgentsApi = async (page: number, isDeleted?: boolean) => {
  const response = await agentsProviderController.indexAgents(page, isDeleted);
  if (!response) return;
  if (Array.isArray(response?.data) && response?.pagination) {
    paginationData.value = response.pagination;
    store.setProp({ key: 'agents', value: response.data });
  } else {
    store.setProp({ key: 'agents', value: [] });
  }
};

const getClientsAPI = async () => {
  const response = await clientsProviderController.indexClient();
  if (!response) return;
  if (Array.isArray(response?.data)) {
    clientsData.value = response.data;
  } else {
    clientsData.value = [];
  }
};

onBeforeMount(async () => {
  await getAgentsApi(1);
  await getClientsAPI();
  const response = await templatesUIRepository.getTemplates();
  if(response?.data) templateData.value = response.data;
  fieldsActives.value = fieldsAgents;
  fieldsInactives.value = fieldsAgents.slice();
  fieldsInactives.value.splice(4, 1);
  fieldsInactives.value.splice(4, 1, {
    key: 'delete',
    label: 'Restaurar',
    thClass: 'text-center',
  });
});

const handleModalDelete = (item: IAgents) => {
  modalTitle.value = !deletedValues.value
    ? `Borrar el Agente con id: ${item.id}`
    : `Restaurar el Agente con id: ${item.id}`;
  modalType.value = 'modalDelete';
  agentToDelete.value = item;
  isModalOpen.value = true;
};

const handleModalClose = () => {
  modalTitle.value = undefined;
  isModalOpen.value = false;
  modalType.value = null;
  agentToEdit.value = null;
  agentToDelete.value = null;
};

const handleModalEdit = (item: IAgents) => {
  modalTitle.value = `Editar el agente con id: ${item.id}`;
  modalType.value = 'modalUpdate';
  agentToEdit.value = item;
  isModalOpen.value = true;
};

const handleModalCreate = () => {
  modalTitle.value = 'Crear nuevo Agente';
  modalType.value = 'modalCreate';
  isModalOpen.value = true;
};

const handleDelete = async () => {
  if (agentToDelete?.value?.id) {
    const response = await agentsProviderController.deleteAgent(
      agentToDelete.value.id,
      deletedValues.value
    );
    if (response?.message) {
      $toast?.error(response.message);
    } else {
      getAgentsApi(paginationData.value.current_page, deletedValues.value);
      $toast?.success(`Agente eliminado con exito`);
    }
    agentToDelete.value = null;
    handleModalClose();
  }
};

const handleActive = () => {
  if (!deletedValues.value) return;
  getAgentsApi(1);
  deletedValues.value = false;
};

const handleInactive = () => {
  if (!!deletedValues.value) return;
  getAgentsApi(1, true);
  deletedValues.value = true;
};

const handleUpdateAgent = async ({ items, isFormValid }: any) => {
  let payload = items
  if (!isFormValid) return;
  if(!items?.templateId) payload = {...payload, templateId: null}
  if (agentToEdit.value?.id) {
    const response = await agentsProviderController.updateAgent(
      payload,
      agentToEdit.value.id
    );
    if (!response?.errores) {
      getAgentsApi(paginationData.value?.current_page);
      $toast?.success(`El agente a sido editado correctamente`);
    } else {
      if (response?.errors && response.errors.length > 0) {
        response.errors.forEach((elem) => {
          $toast?.error(elem.msg);
        });
      }
      if (response?.message) {
        $toast?.error(response.message);
      }
    }
  }
  handleModalClose();
};

const handleCreateAgent = async ({ items, isFormValid }: any) => {
  let payload = items
  if (!isFormValid) return;
  if(!items?.templateId) payload = {...payload, templateId: null}
  const response = await agentsProviderController.createAgent(payload);
  if (!response?.errores) {
    $toast?.success(`El agente a sido creado correctamente`);
    getAgentsApi(paginationData.value?.current_page);
  } else {
    if (response?.errors && response?.errors.length > 0) {
      response.errors.forEach((elem: any) => {
        $toast?.error(elem.msg);
      });
    }
    if (response?.message) {
      $toast?.error(response.message);
    }
  }
  handleModalClose();
};

const onChangePage = async (page: number) => {
  getAgentsApi(page, deletedValues.value);
};
</script>

<template>
  <Layout title="Agentes" :textColor="'color-secondary'">
    <template #layout>
      <div class="d-flex gap-2 justify-content-between">
        <div>
          <CButton
            class="background-blue button__form"
            style="padding: 0.5rem !important; margin-right: 15px"
            type="button"
            @click="handleActive"
          >
            <template #content>Activos</template>
          </CButton>
          <CButton
            class="background-pink button__form"
            style="padding: 0.5rem !important"
            type="button"
            @click="handleInactive"
          >
            <template #content>Inactivos</template>
          </CButton>
        </div>
        <CButton
          class="background-pink button__form"
          style="padding: 0.5rem !important"
          type="button"
          @click="handleModalCreate"
        >
          <template #content>Crear Agente</template>
        </CButton>
      </div>
      <Table
        :fields="!deletedValues ? fieldsActives : fieldsInactives"
        :items="agents"
        @update:model-value-page="onChangePage"
        :totalRows="paginationData?.count"
        :perPage="20"
        :currentPage="paginationData?.current_page"
      >
        <template v-slot:cell(edit)="operationsData">
          <div class="d-flex justify-content-center">
            <CButton
              background="bg-white"
              :customClass="`p-1`"
              @click="handleModalEdit(operationsData.data.item)"
              type="button"
            >
              <template v-slot:content>
                <img src="/icons/editar.svg" alt="editar" />
              </template>
            </CButton>
          </div>
        </template>
        <template v-slot:cell(delete)="operationsData">
          <div class="d-flex justify-content-center">
            <CButton
              :background="!deletedValues ? 'bg-white' : 'bg-blue'"
              :customClass="`p-1`"
              type="button"
              @click="handleModalDelete(operationsData.data.item)"
            >
              <template v-slot:content>
                <img
                  :src="
                    !deletedValues
                      ? '/icons/basura.svg'
                      : '/icons/restaurar.svg'
                  "
                  alt="eliminar"
                />
              </template>
            </CButton>
          </div>
        </template>
      </Table>
      <BModal
        :modelValue="isModalOpen"
        hide-footer
        :centered="true"
        @hide="handleModalClose"
        static
        :title="modalTitle"
      >
        <template v-if="modalType === 'modalDelete'">
          <h5 class="text-center">
            {{
              !deletedValues
                ? `¿Está seguro que desea borrar el agente ${agentToDelete?.name}?`
                : `¿Está seguro que desea restaurar el agente ${agentToDelete?.name}?`
            }}
          </h5>
          <div class="d-flex mt-3">
            <CButton
              class="w-100 fs-6 p-2"
              background="background-pink"
              @click="handleDelete"
            >
              <template #content>
                <div>
                  <span>Confirmar</span>
                </div>
              </template>
            </CButton>
          </div>
        </template>
        <template v-if="modalType === 'modalUpdate'">
          <Form
            ref="refTableForm"
            :inputs="fieldsUpdateUser"
            @onSubmit="handleUpdateAgent"
          >
            <template #buttons>
              <div class="d-flex">
                <CButton class="w-100 fs-6 p-2" background="background-blue">
                  <template #content>
                    <div>
                      <span>Actualizar</span>
                    </div>
                  </template>
                </CButton>
              </div>
            </template>
          </Form>
        </template>
        <template v-if="modalType === 'modalCreate'">
          <Form
            ref="refTableForm"
            :inputs="fieldsUpdateUser"
            @onSubmit="handleCreateAgent"
          >
            <template #buttons>
              <div class="d-flex">
                <CButton class="w-100 fs-6 p-2" background="background-blue">
                  <template #content>
                    <div>
                      <span>Crear Agente</span>
                    </div>
                  </template>
                </CButton>
              </div>
            </template>
          </Form>
        </template>
      </BModal>
    </template>
  </Layout>
</template>

<style scoped></style>
