import { defineStore } from 'pinia';
import { SetProp } from '../types/auth.type';
import { TypeDataProvider } from 'src/types/dataProvider.type';

export const useDataProviderStore = defineStore('dataProvider', {
  state: (): TypeDataProvider => ({
    operations: [],
    companiasGPS: [],
    users: [],
    agents: [],
    templatesUI: [],
    countries: [],
  }),
  actions: {
    setProp(payload: SetProp) {
      return new Promise((resolve) => {
        if (payload.index !== undefined) {
          if (payload.prop !== undefined) {
            // @ts-ignore
            this[payload.key as keyof TypeDataProvider][payload.index][payload.prop] = payload.value;
            // @ts-ignore
            resolve(this[payload.key as keyof TypeDataProvider][payload.index][payload.prop]);
          } else {
            // @ts-ignore
            this[payload.key as keyof TypeDataProvider][payload.index] = payload.value;
            // @ts-ignore
            resolve(this[payload.key as keyof TypeDataProvider][payload.index]);
          }
        } else {
          // @ts-ignore
          this[payload.key as keyof TypeDataProvider] = payload.value;
          resolve(this[payload.key as keyof TypeDataProvider]);
        }
      });
    },
    deleteItem(payload: SetProp) {
      if (payload?.key && payload?.value) {
        const indexItem = this[payload.key as keyof TypeDataProvider].findIndex(
          elem => elem.id === payload.value
        )
        this[payload.key as keyof TypeDataProvider].splice(indexItem, 1)
      }
    }
  },
  getters: {
    getState: (state) => (key: string): any => {
      return state[key as keyof object];
    },
  },
});

export default useDataProviderStore;
