import $http from "../services/AxiosInstance";
import { ErrorApi } from "../types/api.type";
import { User, UserApi as UserApiEntity } from "../types/user.type";


interface ResponseLogin {
  error?: {
    errors: any
  },
  token?: string,
  refreshToken?: string,
  user?: User,
  msg?: string,
}

class AuthenticationApi {
  async login(params: any): Promise<ResponseLogin> {
    try {
      const response = await $http.post("auth/login", params);
      return response.data;
    } catch (error: any) {
      return { error };
    }
  }
  async sendCodeRecovery(params: any): Promise<ResponseLogin> {
    try {
      const response = await $http.post("auth/restore-password", params);
      return response.data;
    } catch (error: any) {
      return { error };
    }
  }
  async verifyToken(token: any): Promise<ResponseLogin> {
    try {
      const response = await $http.get(`auth/restore-password?token=${token}`);
      return response.data;
    } catch (error: any) {
      return { error };
    }
  }
  async changePassowrd(params: any): Promise<ResponseLogin> {
    try {
      const response = await $http.patch("auth/restore-password", params);
      return response.data;
    } catch (error: any) {
      return { error };
    }
  }
  async login2fa(params: any): Promise<ResponseLogin> {
    try {
      const response = await $http.post("auth/login2fa", params);
      return response.data;
    } catch (error: any) {
      return { error };
    }
  }

  async logout(): Promise<ResponseLogin> {
    try {
      const response = await $http.post("auth/logout");
      return response.data;
    } catch (error: any) {
      return { error };
    }
  }

  async checkToken(): Promise<ResponseLogin> {
    try {
      const response = await $http.post("api/checkToken");
      return response.data;
    } catch (error: any) {
      return { error };
    }
  }

}

export default AuthenticationApi;
