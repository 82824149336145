<script lang="ts" setup>
import './Home.scss';
import './Contratacion.scss';
import { ref, computed, onMounted } from 'vue';
import Footer from './components/Footer.vue';
import {
  findClientByTributaryCode,
  findCompaniesGPS,
  findZipCode,
  createOrUpdateClient,
  createPolicy,
  getPDFCoverage,
} from '../../repositories/landing.repository';
import {
  validationRFC,
  evaluateNames,
  wrongDepartureDateTime,
  today,
} from '../../helpers';
import storePolicy from '@store/policy';
import { useRouter } from 'vue-router';
import { BModal } from 'bootstrap-vue-next';
import WhatsappButton from './components/WhatsappButton.vue';
import CaptchaGoogle from './components/CaptchaGoogle.vue';

const store = storePolicy();
const router = useRouter();
const refForm1 = ref<any>(null);
const inputsHidden = ref<boolean>(true);
const isOpenModal = ref<boolean>(false);
const loading = ref<boolean>(false);
const policyId = ref<number | null>(1);
const error = ref<string>('');
const companySelectedByParams = ref<{ nombre?: string; id: number } | null>(
  null,
);
const tokenCaptcha = ref<string | null>(null);

onMounted(() => {
  const thereAreNullProps = Object.values(store.data).some(
    (valor) => valor === null,
  );

  if (thereAreNullProps) {
    router.push('/');
  }
});

onMounted(async () => {
  const comapaniesGPS = await findCompaniesGPS();
  fieldsForm2.value = fieldsForm2.value.map((field: any) => {
    if (field.key === 'companyGPSId') {
      field.options = comapaniesGPS;
      const { gpsCompany } = store.data.queryParams;
      const company = comapaniesGPS.find((company) => {
        return company.slug === gpsCompany;
      });
      if (company && gpsCompany) {
        companySelectedByParams.value = company;
        field.value = company;
        field.disabled = true;
        field.customClass =
          'multiselect--disabled .multiselect__select inputLanding';
      }
    }
    return field;
  });
});

computed(() => fieldsForm3.value);

const fieldsForm1: any = ref([
  {
    key: 'rfc',
    title: 'RFC del cliente',
    type: 'text',
    validations: {
      rules: {
        required: true,
      },
    },
    placeholder: 'RFC del cliente',
    value: null,
    cols: 12,
    customClass: 'inputLanding text-uppercase',
    hidden: false,
    formatValue: (e: string) => e?.toUpperCase() ?? '',
    errors: [],
  },
]);

const fieldsForm2: any = ref([
  {
    key: 'tradeName',
    title: 'Nombre del cliente',
    type: 'text',
    validations: {
      rules: {
        required: true,
      },
    },
    placeholder: 'Nombre del cliente',
    value: null,
    cols: 12,
    customClass: 'inputLanding',
    formatValue: (e: string) => e?.toUpperCase() ?? '',
  },
  {
    key: 'email',
    title: 'Email del Cliente',
    type: 'email',
    validations: {
      rules: {
        required: true,
        email: true,
      },
    },
    placeholder: 'Email del Cliente',
    value: store.data.email ?? null,
    cols: 12,
    customClass: 'inputLanding',
  },
  {
    key: 'phone1',
    title: 'Telefono del Cliente',
    type: 'text',
    validations: {
      rules: {
        required: true,
        max: 12,
        min: 8,
      },
    },
    placeholder: 'Telefono del Cliente',
    value: null,
    cols: 12,
    customClass: 'inputLanding',
  },
  {
    key: 'companyGPSId',
    title: 'Empresa GPS',
    selectLabel: null,
    type: 'select',
    validations: {
      rules: {
        required: true,
      },
    },
    disabled: false,
    placeholder: 'Elije la empresa gps',
    value: null,
    cols: 12,
    customClass: 'inputSelect',
    options: [],
  },
  {
    key: 'address',
    title: 'Dirección del Cliente',
    type: 'text',
    validations: {
      rules: {
        required: true,
      },
    },
    placeholder: 'Dirección del Cliente',
    value: null,
    cols: 12,
    customClass: 'inputLanding',
  },
  {
    key: 'postCode',
    title: 'C.P.',
    type: 'text',
    validations: {
      rules: {
        required: true,
      },
    },
    placeholder: 'Codigo Postal',
    value: null,
    cols: 12,
    customClass: 'inputLanding',
    listenChange: true,
  },
  {
    key: 'colony',
    title: 'Colonia',
    selectLabel: null,
    type: 'select',
    validations: {
      rules: {
        required: true,
      },
    },
    placeholder: 'Elije la colonia',
    value: null,
    cols: 12,
    customClass: 'inputSelect',
    options: [],
  },
  {
    key: 'city',
    title: 'Ciudad',
    type: 'text',
    validations: {
      rules: {
        required: true,
      },
    },
    placeholder: 'Ciudad',
    value: null,
    cols: 12,
    customClass: 'inputLanding',
    disabled: true,
  },
  {
    key: 'state',
    title: 'Estado',
    type: 'text',
    validations: {
      rules: {
        required: true,
      },
    },
    placeholder: 'Estado',
    value: null,
    cols: 12,
    customClass: 'inputLanding',
    disabled: true,
  },
]);

const fieldsForm3: any = ref([
  { slot: true, key: 'title' },
  {
    key: 'assetId',
    title: 'Tipo de Mercancía',
    type: 'text',
    validations: {
      rules: {
        required: true,
      },
    },
    placeholder: 'Tipo de Mercancías',
    value: store.data?.asset?.nombre,
    cols: 12,
    customClass: 'inputLanding',
    disabled: true,
  },
  {
    key: 'indemnificationLimit',
    title: 'Límite de indemnización',
    type: 'number',
    validations: {
      rules: {
        required: true,
      },
    },
    placeholder: 'Límite de indemnización',
    value: store.data.indemnificationLimit,
    cols: 6,
    customClass: 'inputLanding',
    disabled: true,
  },
  {
    key: 'indemnificationLimit-calc',
    title: 'Precio',
    type: 'text',
    value: `$${store.data.endPrice} + IVA`,
    validations: {
      rules: {
        required: true,
      },
    },
    placeholder: 'Precio + IVA',
    disabled: true,
    cols: 6,
    customClass: 'inputLanding',
  },
  {
    key: 'sourceCityZipCodeId',
    title: 'Ciudad de origen',
    type: 'number',
    validations: {
      rules: {
        required: true,
      },
    },
    placeholder: 'Codigo postal',
    value: null,
    cols: 6,
    customClass: 'inputLanding',
    listenChange: true,
  },
  {
    key: 'sourceCityZipCodeIdCalc',
    title: 'Ciudad de origen',
    type: 'text',
    validations: {
      rules: {
        required: true,
      },
    },
    placeholder: 'Ciudad de Origen',
    value: null,
    cols: 6,
    customClass: 'inputLanding',
    disabled: true,
  },
  {
    key: 'destinationCityZipCodeId',
    title: 'Ciudad destino final',
    type: 'number',
    validations: {
      rules: {
        required: true,
      },
    },
    placeholder: 'Codigo postal',
    value: null,
    cols: 6,
    customClass: 'inputLanding',
    listenChange: true,
  },
  {
    key: 'destinationCityZipCodeIdCalc',
    title: 'Ciudad destino final',
    type: 'text',
    validations: {
      rules: {
        required: true,
      },
    },
    placeholder: 'Ciudad destino final',
    value: null,
    cols: 6,
    customClass: 'inputLanding',
    disabled: true,
  },
  {
    key: 'stops',
    title: 'Paradas entre Origen y Destino Final*',
    selectLabel: null,
    type: 'select',
    placeholder: 'Tiene paradas?',
    value: null,
    cols: 3,
    customClass: 'inputSelect',
    validations: {
      rules: {
        required: true,
      },
    },
    options: [
      { nombre: 'Si', id: 1 },
      { nombre: 'No', id: 0 },
    ],
    listenSelect: true,
  },
  {
    key: 'stop1',
    title: 'Parada 1',
    type: 'text',
    placeholder: 'Parada 1',
    value: null,
    cols: 3,
    disabled: true,
    validations: {
      rules: {
        required: false,
      },
    },
    customClass: 'inputLanding inputLanding--mt',
  },
  {
    key: 'stop2',
    title: 'Parada 2',
    type: 'text',
    placeholder: 'Parada 2',
    value: null,
    cols: 3,
    disabled: true,
    customClass: 'inputLanding inputLanding--mt',
  },
  {
    key: 'stop3',
    title: 'Parada 3',
    type: 'text',
    placeholder: 'Parada 3',
    value: null,
    cols: 3,
    disabled: true,
    customClass: 'inputLanding inputLanding--mt',
  },
  {
    key: 'startDate',
    title: 'Fecha de inicio del viaje',
    type: 'date',
    placeholder: 'Fecha de inicio',
    value: null,
    cols: 6,
    min: today(),
    validations: {
      rules: {
        required: true,
      },
    },
    customClass: 'inputLanding',
  },
  {
    key: 'startTime',
    title: 'Hora de inicio del viaje',
    type: 'time',
    placeholder: 'Hora de inicio',
    value: null,
    cols: 6,
    validations: {
      rules: {
        required: true,
      },
    },
    customClass: 'inputLanding',
    listenFocus: true,
  },
  {
    key: 'endDate',
    title: 'Fecha de fin del Viaje (aproximada)',
    type: 'date',
    placeholder: 'Fecha de fin',
    value: null,
    cols: 6,
    min: today(),
    validations: {
      rules: {
        required: true,
      },
    },
    customClass: 'inputLanding',
  },
  {
    key: 'endTime',
    title: 'Hora de fin del viaje',
    type: 'time',
    placeholder: 'Hora de fin',
    value: null,
    cols: 6,
    validations: {
      rules: {
        required: true,
      },
    },
    customClass: 'inputLanding',
  },
  {
    key: 'documentDescription',
    title: 'Documento del viaje',
    type: 'text',
    placeholder: 'Documento del Viaje',
    value: null,
    cols: 6,
    validations: {
      rules: {
        required: true,
      },
    },
    customClass: 'inputLanding',
  },
  {
    key: 'document',
    title: 'Adjuntar documento',
    type: 'file',
    placeholder: 'Adjuntar documento',
    value: null,
    cols: 6,
    hidden: false,
    customClass: 'inputFile',
  },
  {
    key: 'truckPlate',
    title: 'Placa Tractor',
    type: 'text',
    placeholder: 'Placa Tractor',
    value: null,
    cols: 4,
    validations: {
      rules: {
        required: true,
      },
    },
    customClass: 'inputLanding',
  },
  {
    key: 'box-trailer',
    title: 'Caja de Carga',
    selectLabel: null,
    type: 'select',
    placeholder: 'Caja de Carga',
    value: null,
    cols: 4,
    options: [
      { nombre: 'Si', id: 1 },
      { nombre: 'No', id: 0 },
    ],
    validations: {
      rules: {
        required: true,
      },
    },
    hidden: false,
    customClass: 'inputSelect',
    listenSelect: true,
  },
  {
    key: 'trailerPlate',
    title: 'Placa caja',
    type: 'text',
    placeholder: 'Placa caja',
    value: null,
    cols: 4,
    customClass: 'inputLanding',
    validations: {
      rules: {
        required: false,
      },
    },
    disabled: true,
  },
  {
    key: 'driverName',
    title: 'Nombre del conductor',
    type: 'text',
    placeholder: 'Nombre del conductor',
    value: null,
    cols: 12,
    validations: {
      rules: {
        required: true,
      },
    },
    customClass: 'inputLanding',
  },
  {
    key: 'driverCellphone',
    title: 'Móvil del conductor',
    type: 'text',
    placeholder: 'Móvil del conductor',
    value: null,
    cols: 12,
    validations: {
      rules: {
        required: true,
        max: 12,
        min: 8,
      },
    },
    customClass: 'inputLanding',
  },
  {
    key: 'driverEmail',
    title: 'Email del conductor',
    type: 'email',
    placeholder: 'Email del conductor',
    value: null,
    cols: 12,
    validations: {
      rules: {
        email: true,
      },
    },
    hidden: false,
    customClass: 'inputLanding',
  },
  { slot: true, key: 'endForm' },
  {
    key: 'auth-check',
    type: 'checkbox',
    value: null,
    validations: {
      rules: {
        required: true,
      },
    },
    slotContent: `<p class="text-white">Autorizo que la señal del dispositivo de geolocalización del vehículo transportador 
                  sea compartido con Chubb Tracking Services 
                  / Assistcargo para el monitoreo y seguridad de mi viaje.</p>`,
  },
  {
    key: 'faq-check',
    type: 'checkbox',
    value: null,
    validations: {
      rules: {
        required: true,
      },
    },
    slotContent: `<p class="text-white">Acepto los 
                    <a  
                      target="_blank" 
                      href="https://www.chubb.com/content/dam/chubb-sites/chubb-com/mx-es/condiciones-generales/global/documents/pdf/ad33-aba-d-transporte-de-carga-especifica.pdf" 
                      class="text-black"
                    >
                      Términos y Condiciones
                    </a>
                  </p>`,
  },
  {
    key: 'docs-check',
    type: 'checkbox',
    value: null,
    validations: {
      rules: {
        required: true,
      },
    },
    slotContent: `<p class="text-white">Acepto el recibir la documentación contractual vía correo electrónico</p>`,
  },
  {
    key: 'private-check',
    type: 'checkbox',
    value: null,
    validations: {
      rules: {
        required: true,
      },
    },
    slotContent: `<p class="text-white">Acepto que mis datos sean utilizados de conformidad con el aviso de Privacidad mismo que puedo consultar 
                  <a href="https://www.chubb.com/content/dam/chubb-sites/chubb-com/mx-es/footer/privacy-notices/documents/pdf/aviso-de-privacidad-asegurados-chubb-seguros-mexico.pdf" target="_blank" class="text-black">aquí</a></p>`,
  },
]);

onMounted(() => {
  const windowWidth = window.innerWidth;
  if (windowWidth <= 480) {
    fieldsForm3.value.forEach((field: any) => {
      field.cols = 12;
    });
  }
});

const handleOnSubmitRFC = async (e: any) => {
  const isValidRfc = validationRFC(e.items.rfc);
  if (!e.isFormValid || !isValidRfc) {
    return (fieldsForm1.value[0].errors = ['rfc es invalido']);
  }
  const { locations, client } = await findClientByTributaryCode(e.items.rfc);

  if (client) {
    store.setProp({ key: 'client', value: client });
  }

  if (locations && client) {
    const updatesClientInputs = [
      { key: 'tradeName', value: client.tradeName },
      { key: 'phone1', value: client.phone1 },
      {
        key: 'companyGPSId',
        value: {
          id: companySelectedByParams.value?.id ?? client.companyGPS.id,
          nombre:
            companySelectedByParams.value?.nombre ??
            client.companyGPS.description,
        },
      },
      { key: 'address', value: client.address },
      { key: 'postCode', value: client.postCode },
      {
        key: 'colony',
        options: locations,
        value: {
          id: client.neighborhood.id,
          nombre: client.neighborhood.description,
        },
      },
      { key: 'city', value: client.city.description },
      { key: 'state', value: client.state.description },
    ];

    updateFieldsInArray(fieldsForm2.value, updatesClientInputs, null);
  }
  fieldsForm1.value.find((field: any) => field.key === 'rfc').disabled = true;

  inputsHidden.value = false;

  if (!client) {
    store.setProp({ key: 'client', value: { rfc: e.items.rfc } });
  }
};

const handleChange = async (e: any) => {
  if (e.value.length < 4 || e.value.length > 7) return;

  const location = await findZipCode(e.value, false);

  const storeDate = store.data;

  // Si no existe el codigo postal dejamos una bandera en el input
  if (!location.data?.length) {
    if (e.input.key === 'sourceCityZipCodeId') {
      store.setProp({
        key: 'data',
        value: { ...storeDate, sourceCityZipCodeId: null },
      });
      fieldsForm3.value.find(
        (field: any) => field.key === 'sourceCityZipCodeIdCalc',
      ).value = 'CP INVALIDO';
    } else {
      store.setProp({
        key: 'data',
        value: { ...storeDate, destinationCityZipCodeId: null },
      });
      fieldsForm3.value.find(
        (field: any) => field.key === 'destinationCityZipCodeIdCalc',
      ).value = 'CP INVALIDO';
    }
    return;
  }

  // se estable el nombre de la locacion en el input
  if (e.input.key === 'sourceCityZipCodeId') {
    store.setProp({
      key: 'data',
      value: { ...storeDate, sourceCityZipCodeId: location.data[0].id },
    });
    fieldsForm3.value.find(
      (field: any) => field.key === 'sourceCityZipCodeIdCalc',
    ).value = location.data[0].MunicipioNombre;
  } else {
    store.setProp({
      key: 'data',
      value: { ...storeDate, destinationCityZipCodeId: location.data[0].id },
    });
    fieldsForm3.value.find(
      (field: any) => field.key === 'destinationCityZipCodeIdCalc',
    ).value = location.data[0].MunicipioNombre;
  }
};

const handleSelectChange = (e: any) => {
  const updateFields = [];

  if (e.input.key === 'box-trailer') {
    updateFields.push(
      { key: 'trailerPlate', disabled: !e.value.id },
      { key: 'trailerPlate', 'validations.rules.required': e.value.id },
    );
  } else {
    updateFields.push(
      { key: 'stop1', disabled: !e.value.id },
      { key: 'stop1', 'validations.rules.required': e.value.id },
      { key: 'stop2', disabled: !e.value.id },
      { key: 'stop3', disabled: !e.value.id },
    );
  }

  updateFieldsInArray(fieldsForm3.value, updateFields, null);
};

const handleChangeColony = async (e: any) => {
  const formProps = await refForm1.value.getValues();

  const location = await findZipCode(e.value, false);

  if (!location?.data?.length) return;

  const locations = location.data.map((loc: any) => ({
    nombre: loc.ColoniaDsc,
    id: loc.id,
  }));

  const preservedValues = {
    tradeName: formProps.items.tradeName,
    email: formProps.items.email,
    phone1: formProps.items.phone1,
    companyGPSId: formProps.items.companyGPSId,
    address: formProps.items.address,
  };

  updateFieldsInArray(
    fieldsForm2.value,
    [
      { key: 'colony', options: locations, value: { id: null } },
      { key: 'state', value: location.data[0].EstadoNombre },
      { key: 'city', value: location.data[0].MunicipioNombre },
      { key: 'postCode', value: location.data[0].code },
    ],
    preservedValues,
  );

  const values = {
    country: { id: location.data[0].PaisID },
    city: { id: location.data[0].MunicipioId },
    neighborhood: { id: location.data[0].ColoniaId },
    postCode: location.data[0].code,
    rfc: store.client.rfc,
    state: { id: location.data[0].EstadoID },
  };

  store.setProp({
    key: 'client',
    value: { ...store.client, ...values },
  });
};

const updateFieldsInArray = (
  array: any,
  updates: any,
  preservedValues: any,
) => {
  for (const update of updates) {
    const field = array.find((field: any) => field.key === update.key);
    if (field) {
      if ('value' in update) {
        field.value = update.value;
      }
      if ('options' in update) {
        field.options = update.options;
      }
      if ('disabled' in update) {
        field.disabled = update.disabled;
      }
    }
  }

  if (preservedValues) {
    for (const key in preservedValues) {
      const field = array.find((field: any) => field.key === key);
      if (field) {
        field.value = preservedValues[key];
      }
    }
  }
};

const handleOnSubmitPolicies = async ({ items, isFormValid }: any) => {
  const { items: itemsForm1, isFormValid: isFormValidForm1 } =
    await refForm1.value.getValues();

  if (!tokenCaptcha.value) {
    return (error.value = 'Por favor, verifica el reCAPTCHA primero');
  }

  if (!isFormValidForm1) {
    return (error.value = 'El formulario debe estar completo');
  }
  if (!isFormValid) {
    return (error.value = 'El formulario debe estar completo');
  }

  const { address, companyGPSId, email, phone1, tradeName } = itemsForm1;

  const {
    indemnificationLimit,
    truckPlate,
    trailerPlate,
    driverName,
    documentDescription,
    driverCellphone,
    driverEmail,
    startDate,
    endDate,
    startTime,
    endTime,
    stops,
    stop1,
    stop2,
    stop3,
    destinationCityZipCodeIdCalc,
    sourceCityZipCodeIdCalc,
    document,
  } = items;

  const isOkDepartureDateTime = wrongDepartureDateTime(startDate, startTime);

  if (isOkDepartureDateTime) {
    return (error.value =
      'La hora de salida no puede ser menor a dentro de 90 minutos.');
  }

  if (
    destinationCityZipCodeIdCalc === 'CP INVALIDO' ||
    sourceCityZipCodeIdCalc === 'CP INVALIDO'
  ) {
    return (error.value = 'Codigo postal no valido');
  }

  const validationRfcAndName = evaluateNames(store.client.rfc, tradeName);

  const {
    clientMotherSurname,
    clientFathersSurname,
    clientNames,
    clientBirthOrConstDate,
    taxIdVerificationCode,
    namesError,
    isClientAPhysicalPerson,
  } = validationRfcAndName;

  if (namesError) {
    return (error.value =
      'Ingrese su nombre completo, seguido de su apellido paterno y luego su apellido materno.');
  }

  try {
    loading.value = true;

    const createOrUpdateClientData = {
      isClientAPhysicalPerson,
      clientMotherSurname,
      clientFathersSurname,
      clientNames,
      clientBirthOrConstDate,
      taxIdVerificationCode,
      namesError,
      address,
      email,
      tradeName,
      phone1,
      countryId: store.client.country.id,
      cityId: store.client.city.id,
      neighborhoodId: store.client.neighborhood.id,
      postCode: store.client.postCode,
      rfc: store.client.rfc,
      stateId: store.client.state.id,
      companyGPSId: store.client?.companyGPS?.id ?? companyGPSId.id,
      urlDomain: window.location.origin,
    };

    const client = await createOrUpdateClient(
      store.client.id,
      createOrUpdateClientData,
      'MX',
    );
    store.setProp({
      key: 'client',
      value: { ...store.client, id: client.data.client.id },
    });

    const travelStartDate = new Date(`${startDate}T${startTime}`).toISOString();
    const travelEndDate = new Date(`${endDate}T${endTime}`).toISOString();
    const assetId = store.data.asset.id;

    const createPolicyData = {
      tradeName,
      rfc: store.client.rfc,
      assetId,
      indemnificationLimit,
      sourceCityZipCodeId: store.data.sourceCityZipCodeId,
      destinationCityZipCodeId: store.data.destinationCityZipCodeId,
      companyGPSId: companyGPSId.id,
      truckPlate,
      trailerPlate,
      driverName,
      documentDescription,
      driverCellphone,
      driverEmail,
      travelStartDate,
      travelEndDate,
      hasStops: stops.nombre,
      stop1,
      stop2,
      stop3,
      file: document,
      token: tokenCaptcha.value,
    };

    const newPolicyId = await createPolicy(
      client.data.client.id,
      client.data.manager.id,
      createPolicyData,
    );

    loading.value = false;

    if (newPolicyId) {
      policyId.value = newPolicyId;
      isOpenModal.value = true;
    }
  } catch (e) {
    error.value = 'Ocurrió un Error.';
    loading.value = false;
  }
};

const hideModal = () => {
  isOpenModal.value = false;
  return router.push('/');
};

const handleGetPDFCoverage = () => {
  getPDFCoverage(policyId.value);
};

const handleTokenCaptcha = (token: string) => {
  tokenCaptcha.value = token;
};

const handleOnExpired = () => {
  tokenCaptcha.value = null;
};
</script>

<template>
  <h1 class="landing__title">Seguro de Mercancias por trayecto</h1>
  <h2 class="landing__subtitle">¡YA FALTA MENOS!</h2>
  <h2 class="landing__subtitle">AYUDANOS COMPLETANDO EL DETALLE DEL VIAJE</h2>
  <div class="contratacion">
    <div class="contratacion__container">
      <h2 class="text-center text-white">Datos del cliente</h2>
      <Form
        ref="refTableForm"
        :inputs="fieldsForm1"
        @onSubmit="handleOnSubmitRFC"
        @change="handleChangeColony"
      >
        <template #buttons>
          <div class="d-flex" v-if="inputsHidden">
            <CButton
              class="p-4 w-100 my-2 px-2"
              background="background-green"
              style="font-size: 1rem"
            >
              <template #content> Comprobar RFC </template>
            </CButton>
          </div>
        </template>
      </Form>
      <template v-if="!inputsHidden">
        <Form
          ref="refForm1"
          :inputs="fieldsForm2"
          @change="handleChangeColony"
        ></Form>
        <Form
          :inputs="fieldsForm3"
          @onSubmit="handleOnSubmitPolicies"
          @change="handleChange"
          @select="handleSelectChange"
        >
          <template #title>
            <h2 class="text-center text-white mt-5">Datos del viaje</h2>
          </template>
          <template #endForm>
            <h2 class="text-center text-white mt-2 fs-5">
              Seguro propiedad de y operado por Chubb Seguros México S.A. Venta
              del seguro es operada y controlada por Chubb Seguros México S.A.
            </h2>
          </template>

          <template #buttons>
            <p v-if="error" class="text-danger-emphasis mt-3">{{ error }}</p>
            <CaptchaGoogle
              @tokenCaptcha="handleTokenCaptcha"
              @onExpired="handleOnExpired"
            />
            <div class="d-flex">
              <CButton
                class="p-4 w-100 my-2 px-2"
                background="background-green"
                style="font-size: 1.5rem"
              >
                <template #content>
                  <div>
                    <BSpinner v-if="loading" />
                    <span v-else>Contratar el seguro</span>
                  </div>
                </template>
              </CButton>
            </div>
          </template>
        </Form>
      </template>
    </div>
  </div>
  <Footer />
  <WhatsappButton />
  <BModal
    :modelValue="isOpenModal"
    hide-footer
    static
    @hide="hideModal"
    size="lg"
    v-if="policyId"
  >
    <h3>Felicitaciones, tu solicitud de póliza ha sido generada</h3>
    <p>Número de Transacción: {{ policyId }}/{{ new Date().getFullYear() }}</p>
    <p>
      Se ha enviado la carta de cobertura al correo ingresado, igualmente puedes
      descargarla
      <a class="underline-link cursor-pointer" @click="handleGetPDFCoverage"
        >aquí</a
      >
    </p>
    <p>
      Ahora solo necesitas realizar el pago del seguro para que la cobertura sea
      activada
    </p>
    <h3>Datos Bancarios para deposito de costo de seguro:</h3>
    <ul>
      <li>Chubb Seguros México S.A.</li>
      <li>Banco Nacional de México S.A.</li>
      <li>Cuenta: 6502-2051185</li>
      <li>Clave: 002180650220511854</li>
      <li>Referencia: {{ policyId }}/{{ new Date().getFullYear() }}</li>
    </ul>
    <p style="color: red">
      ATENCION: El seguro contratado será válido únicamente si el costo es
      pagado antes del inicio del viaje solicitado a amparar.
    </p>
    <p>
      Si necesitas de ayuda con tu pago, contactas a:
      <a class="underline-link">+52 55 4619 4648</a>
    </p>
  </BModal>
</template>
