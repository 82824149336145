import { RouteRecordRaw, createRouter, createWebHistory } from "vue-router";
import storeAuth from "../store/auth";
import guard from "../middlewares/Guard";
import PublicRoutes from "./PublicRoutes";
import PrivateRoutes from "./PrivateRoutes";

const routes: Array<RouteRecordRaw> = [
  ...PublicRoutes,
  ...PrivateRoutes,
  {
    path: "/:catchAll(.*)",
    redirect: "/login"
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  await guard(to, from, router, storeAuth);
  next();
});

export default router;
