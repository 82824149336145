import { defineStore } from 'pinia';
import { Auth, SetProp } from '../types/auth.type';

export const useAuthStore = defineStore('auth', {
  state: (): Auth => ({
    user: null,
    token: '',
    refreshToken: '',
    status: false,
  }),
  actions: {
    logout() {
      this.user = null;
      this.token = '';
      this.refreshToken = '';
      this.status = false;
      localStorage.clear();
    },
    login(payload: any) {
      const { user, token, refreshToken } = payload;
      if (token) {
        this.token = token;
        localStorage.setItem('token', token);
        localStorage.setItem('user', JSON.stringify(user));
        localStorage.setItem('refreshToken', refreshToken);
      }
      if (user) {
        this.user = user;
      }
      if (refreshToken) {
        this.refreshToken = refreshToken;
      }
    },
    refresh(payload: any) {
      const { user, token } = payload;
      if (token) {
        this.token = token;
        localStorage.setItem('user', JSON.stringify(user));
        this.status = true;
      }
      if (user) {
        this.user = user;
      }
    },
    setProp(payload: SetProp) {
      return new Promise((resolve) => {
        if (payload.index) {
          if (payload.prop) {
            // @ts-ignore
            this[payload.key as keyof Auth][payload.index] = payload.value;
            resolve(
              // @ts-ignore
              this[payload.key as keyof Auth][payload.index][payload.prop],
            );
          } else {
            // @ts-ignore
            this[payload.key as keyof Auth][payload.index] = payload.value;
            // @ts-ignore
            resolve(this[payload.key as keyof Auth][payload.index]);
          }
        } else {
          // @ts-ignore
          this[payload.key as keyof Auth] = payload.value;
          resolve(this[payload.key as keyof Auth]);
        }
      });
    },
  },
});

export default useAuthStore;
