<script lang="ts" setup>
import { ref, watch, onMounted } from 'vue';
import { inputsStep1 } from './utils/inputsForm';
import { getAssets } from '../../repositories/landing.repository';
import { calculatePolicyPrice } from '../../helpers';
import Slider from '../Landing/components/Slider.vue';
import NuevaContratacionMX from './NuevaContratacionMX.vue';
import NuevaContratacionAR from './NuevaContratacionAR.vue';
import NuevaContratacionCL from './NuevaContratacionCL.vue';
import { useToast } from '../../composables/useToast';
import storePolicy from '@store/policy';
import getDolarArgentina from '../../helpers/exchangeArgentina';
import { getDolarChile } from '../../helpers/exchangeChile';
import storeAuth from '@store/auth';

const store = storePolicy();
const storeUser = storeAuth();
const toogleForm2 = ref<boolean>(false);
const fieldsStep1: any = ref(inputsStep1);
const endPrice = ref<number>(calculatePolicyPrice(200000));
const indemnificationLimit = ref<number>(200000);
const dataForm1 = ref<any>();
const dolarPrice = ref<number | null>(null);
const UFvalue = ref<number | null>(null);
const isButtonDisabled = ref<boolean>(false);
const loading = ref<boolean>(false);
const country = ref({ id: 1, nombre: 'MEXICO' });
const countryOptions = ref<Array<{ id: number; nombre: string }>>([]);
const { $toast } = useToast();

const fetchDolarArgentina = async () => {
  try {
    loading.value = true;
    const cotizations = await getDolarArgentina();
    dolarPrice.value = cotizations;
    store.setProp({ key: 'pricing', value: { dolar: dolarPrice.value } });
    handleSliderChange(dolarPrice.value * 1000);
  } catch (error) {
    $toast?.error('Hay un error en la cotización de divisas');
  } finally {
    loading.value = false;
  }
};

const fetchDolarChile = async () => {
  try {
    loading.value = true;
    const cotizations = await getDolarChile();
    if (!cotizations || !cotizations.dolar || !cotizations.uf) {
      $toast?.error('Hay un error en la cotización de divisas');
      return;
    }

    dolarPrice.value = cotizations.dolar;
    UFvalue.value = cotizations.uf;
    store.setProp({
      key: 'pricing',
      value: { dolar: dolarPrice.value, uf: UFvalue.value },
    });

    if (!dolarPrice.value || !UFvalue.value) {
      isButtonDisabled.value = true;
    } else {
      handleSliderChange(Math.round((1000 * dolarPrice.value) / UFvalue.value));
    }
  } catch (error) {
    $toast?.error('Hay un error en la cotización de divisas');
  } finally {
    loading.value = false;
  }
};

const handleCountrySpecificLogic = async (country: string) => {
  if (country === 'ARGENTINA') {
    await fetchDolarArgentina();
  } else if (country === 'CHILE') {
    await fetchDolarChile();
  } else if (country === 'MEXICO') {
    endPrice.value = calculatePolicyPrice(200000);
  }
};

onMounted(() => {
  countryOptions.value = storeUser.user?.countries ?? [];
  country.value = countryOptions.value[0];
});

watch(
  country,
  async (newCountry) => {
    if (newCountry && newCountry.nombre) {
      await handleCountrySpecificLogic(newCountry.nombre);
      const assets = await getAssets(country.value.nombre.substring(0, 3));
      fieldsStep1.value = fieldsStep1.value.map((field: any) => {
        if (field.key === 'asset') {
          field.options = assets;
        }
        return field;
      });
    }
  },
  {
    immediate: true,
  },
);

const handleValidateSlider = () => {
  if (country.value.nombre === 'CHILE' && dolarPrice.value && UFvalue.value) {
    return true;
  } else if (country.value.nombre === 'ARGENTINA' && dolarPrice.value) {
    return true;
  } else if (country.value.nombre === 'MEXICO') {
    return true;
  }

  isButtonDisabled.value = false;
  return false;
};

const handleSliderChange = (data: number) => {
  // Calculo de precio final para Argentina

  if (country.value.nombre === 'ARGENTINA') {
    indemnificationLimit.value = data;

    const primaNeta = (data * 0.315) / 100;
    const recargosFinacieros = (primaNeta * 6) / 100;

    if (!dolarPrice.value) {
      return (isButtonDisabled.value = true);
    }
    //    if (
    // 	 (data / 1000 <= dolarPrice.value || data <= Math.round(dolarPrice.value * 250000)) ||
    // 	//  !toogleIndemnizationLimit.value
    //    ) {
    // 	 isButtonDisabled.value = false;
    //    } else {
    // 	 isButtonDisabled.value = true;
    //    }

    const endPriceValue = primaNeta + recargosFinacieros;
    return (endPrice.value = parseFloat(endPriceValue.toFixed(2)));
  }

  // Calculo de precio final para Chile
  if (country.value.nombre === 'CHILE') {
    indemnificationLimit.value = data;
    const value = data * 0.31;

    return (endPrice.value = parseFloat(value.toFixed(2)));
  }

  // Calculo de precio final para Mexico
  indemnificationLimit.value = data;
  const value = calculatePolicyPrice(data);
  endPrice.value = parseFloat(value.toFixed(2));
};

const handleOnSubmit = ({ items, isFormValid }: any) => {
  if (!isFormValid) return;

  const newAudit = {
    email: items.email,
    phone: items.phone,
    asset: items.asset.nombre,
  };

  dataForm1.value = {
    ...newAudit,
    asset: items.asset,
    indemnificationLimit: indemnificationLimit.value,
    endPrice: endPrice.value,
  };

  toogleForm2.value = true;
};

const handleReset = () => {
  toogleForm2.value = false;
};
</script>

<template>
  <Layout
    title="Nueva Operación"
    :textColor="'color-secondary'"
    v-if="!toogleForm2"
  >
    <template #layout
      ><div v-if="countryOptions.length > 1">
        <label class="p-2 fs-5">País</label>
        <Select
          class="col-4"
          :options="countryOptions"
          :value="country"
          v-model="country"
        />
      </div>

      <Form ref="refTableForm" :inputs="fieldsStep1" @onSubmit="handleOnSubmit">
        <template #buttons>
          <div class="container col-6">
            <div class="row justify-content-center border-black p-4">
              <div class="col-12 px-5">
                <p class="mb-5 text-center">
                  Seleccione límite de indemnización
                </p>
                <Overlay
                  :show="loading"
                  rounded="lg"
                  borderColor="#40a4c6"
                  circleColor="#40a4c6"
                  class="p-5"
                />
                <template v-if="!loading">
                  <div
                    class="form__slider background-blue"
                    v-if="handleValidateSlider()"
                  >
                    <Slider
                      @value="handleSliderChange"
                      :countryInitial="country.nombre"
                      :dolarPrice="dolarPrice ?? undefined"
                      :UFvalue="UFvalue ?? undefined"
                    />
                  </div>
                  <div class="d-flex justify-content-center mt-2">
                    <p>
                      <span>Precio Final:</span>
                      <span class="color-pink">$ {{ endPrice }}</span> + iva
                    </p>
                  </div>
                  <CButton
                    class="col-12 mt-5"
                    background="background-pink"
                    style="font-size: 2rem"
                  >
                    <template #content> CARGAR PÓLIZA </template>
                  </CButton>
                </template>
              </div>
            </div>
          </div>
        </template>
      </Form>
    </template>
  </Layout>
  <template v-else-if="country.nombre === 'MEXICO'">
    <NuevaContratacionMX :dataForm1="dataForm1" @firstView="handleReset" />
  </template>
  <template v-else-if="country.nombre === 'ARGENTINA'">
    <NuevaContratacionAR  :dataForm1="dataForm1" @firstView="handleReset" />
  </template>
  <template v-else-if="country.nombre === 'CHILE'">
    <NuevaContratacionCL  :dataForm1="dataForm1" @firstView="handleReset" />
  </template>
</template>
