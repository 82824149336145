import { ref } from 'vue';

export function useMessage() {
  const messageHTML = ref<string | null>(null);

  function showMessage(msg: string | null, type?: string) {
    if (msg === null) {
      messageHTML.value = null;
      return;
    }

    let color = type === 'error' ? '#ff436f' : type === 'info' ? '#40a4c6' : '';
    messageHTML.value = `<p style="color: ${color}">${msg}</p>`;
  }

  return {
    messageHTML,
    showMessage,
  };
}
