<script lang="ts" setup>


</script>

<template>
  <div class="container-fluid">
    <div class="my-4">
      <h3>En construcción</h3>
    </div>
  </div>
</template>
