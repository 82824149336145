import $http from '../services/AxiosInstance';
import { ICountry } from 'src/types/countries.type';

interface Params {
  page?: number;
  search?: string;
  deleted?: boolean;
}

class CountriesRepository {
  static async getcountries(params: Params = {}) {
    try {
      let url = `/countries`;
      if(params.page){
        url += `?page=${params.page}`;
      }
      if (params.search) {
        url += `&search=${params.search}`;
      }
      if (params.deleted) {
        url += `&deleted=${params.deleted}`;
      }
      const response = await $http.get(url);
      return response.data;
    } catch (errors: any) {
      throw Error;
    }
  }
  static async createCountry(data: ICountry): Promise<ICountry> {
    try {
      const response = await $http.post('/countries', data);

      return response.data;
    } catch (error) {
      throw Error;
    }
  }
  static async updateCountry(data: ICountry): Promise<ICountry> {
    try {
      const response = await $http.patch(`/countries/${data.id}`, data);
      return response.data;
    } catch (error) {
      throw Error;
    }
  }
  static async deleteCountry(id: number): Promise<number> {
    try {
      const response = await $http.delete(`/countries/${id}`);
      return response.status;
    } catch (error) {
      throw Error;
    }
  }
  static async restoreCountry(id: number): Promise<number> {
    try {
      const response = await $http.post(`/countries/restore/${id}`);
      return response.status;
    } catch (error) {
      throw Error;
    }
  }
}

export default CountriesRepository;
