export interface IFinalCalculation {
  primaNeta: number;
  IVA: number;
  primaTotal: number;
}

const roundToTwoDecimals = (num: number): number => {
  return Math.round(num * 100) / 100;
};

const convertirUfAPesos = (valorUF: number, valorActualUF: number) => {
  return valorUF * valorActualUF;
};

export function finalCalculationCH(
  endPrice: number,
  valorActualUF: number,
): IFinalCalculation {
  const valorEnPesos = convertirUfAPesos(endPrice, valorActualUF);

  const primaNeta = roundToTwoDecimals(valorEnPesos);
  const IVA = roundToTwoDecimals(valorEnPesos * 0.19);
  const primaTotal = roundToTwoDecimals(primaNeta + IVA);

  return {
    primaNeta,
    IVA,
    primaTotal,
  };
}
