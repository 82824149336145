<script setup lang="ts">
import { onBeforeUnmount, ref } from 'vue';

// Pinia
import Store from '@store/dataProvider';
import { storeToRefs } from 'pinia';

// Composables
import { useMessage } from '../../../composables/useMessage';
import { useToast } from '../../../composables/useToast';

// repositories
import TemplatesUIDataProviderRepository from '../../../repositories/templatesUI.repository';

const { $toast } = useToast();
const { messageHTML, showMessage } = useMessage();

const store = Store();
const { templatesUI }: any = storeToRefs(store);

const templatesUIRepository = new TemplatesUIDataProviderRepository();

const emit = defineEmits<{
  (e: 'closeModal'): void;
}>();

const refTableForm = ref<any>(null);
const fields: any = ref([
  {
    key: 'name',
    title: 'Nombre',
    type: 'text',
    validations: {
      rules: {
        required: true,
      },
    },
    placeholder: 'Nombre de la plantilla',
    value: null,
    cols: 12,
  },
  {
    key: 'urlDomain',
    title: 'Url de dominio',
    type: 'text',
    validations: {
      rules: {
        required: true,
        verifyExpreRegular: /^(ftp|http|https):\/\/[^ "]+(?<!\/)$/,
      },
    },
    placeholder: 'https://example.com',
    value: null,
    cols: 12,
  },
  {
    key: 'primaryColour',
    title: 'Color principal',
    type: 'color',
    validations: {
      rules: {
        required: true,
      },
    },
    placeholder: 'Color principal',
    value: '#000',
    cols: 12,
  },
  {
    key: 'imagePath',
    title: 'Logo',
    type: 'file',
    placeholder: 'Elije el logo',
    value: null,
    cols: 12,
    validations: {
      rules: {
        required: true,
        max: 1,
      },
    },
  },
]);

const handleOnSubmit = async ({ items, isFormValid }: any) => {
  if (!isFormValid) return;
  console.log('items', items);

  try {
    const { data } = await templatesUIRepository.createTemplate(items);
    store.setProp({ key: 'templatesUI', value: [...templatesUI.value, data] });
    $toast?.success(`Plantilla creada con exito`);
    emit('closeModal');
  } catch (error: any) {
    showMessage(error.message, 'error');
  }
};

onBeforeUnmount(() => {
  fields.value.forEach((field: any) => {
    field.value = null;
  });
});
</script>

<template>
  <Form ref="refTableForm" :inputs="fields" @onSubmit="handleOnSubmit">
    <template #buttons>
      <div v-if="messageHTML" v-html="messageHTML" />
      <div class="d-flex">
        <CButton class="w-100 fs-6 p-2" background="background-blue">
          <template #content>
            <div>
              <span>Crear</span>
            </div>
          </template>
        </CButton>
      </div>
    </template>
  </Form>
</template>
