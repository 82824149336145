import isCanAccess from '../helpers/Auth/isCanAccess'
import { useAuth } from '../composables/useAuth'


async function guard(to: any, from: any, router: any, storeAuth: any) {
  const { status, user, logout } = storeAuth()

  if (user?.permission) {
    const isAuth = isCanAccess(to, user)

    if (!isAuth) {
      router.push({ name: user.permission[1] });
    }
  }

  if (!status && to.meta.gate) {
    try {
      const { handleAuthenticated } = useAuth()
      const isAuthenticate = await handleAuthenticated()

      if (isAuthenticate?.user) {
        const isAuth = isCanAccess(to, isAuthenticate.user)

        if (!isAuth) {
          if (isAuthenticate.user.permission[1]) {
            router.push({ name: isAuthenticate.user.permission[1] });
          } else {
            logout()
            router.push({ name: 'Login' });
          }
        }
      }

      if (!isAuthenticate || isAuthenticate.error) {
        router.push({ name: 'Login' });
      }

    } catch (error) {
      router.push({ name: 'Login' });
    }
  }

}

export default guard;
