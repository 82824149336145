const APP_CLIENT_ARG: string = import.meta.env.VITE_APP_CLIENT_ARG;
const APP_CLIENT_CHI: string = import.meta.env.VITE_APP_CLIENT_CHI;
const APP_CLIENT_MEX: string = import.meta.env.VITE_APP_CLIENT_MEX;
const APP_CLIENT: string = import.meta.env.VITE_APP_CLIENT;

interface UrlToImageMap {
  [key: string]: string;
}

const urlToImageMap: UrlToImageMap = {
  [APP_CLIENT]: '/img/flag-mx.png',
  [APP_CLIENT_MEX]: '/img/flag-mx.png',
  [APP_CLIENT_CHI]: '/img/flag-cl.png',
  [APP_CLIENT_ARG]: '/img/flag-ar.png',
};

const getImageFlag = (url: string): string => {
  return urlToImageMap[url];
};

export default getImageFlag;