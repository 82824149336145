import $http from "../services/AxiosInstance";
import { ErrorApi } from "../types/api.type";
import { ApiResponseIUser, IPermission, IRole, User, UserApi as UserApiEntity } from "../types/user.type";
import storeAuth from '@store/auth';

export interface GetAuthResponse extends ErrorApi {
  user?: User;
  token?: string;
}
export interface GetUserResponse extends ErrorApi {
  data?: User;
  
}

export interface GetPermissionResponse extends ErrorApi {
  data?: IPermission[];  
}
export interface GetRoleResponse extends ErrorApi {
  data?: IRole[];  
}

interface Params {
  page?: number,
}

class UserApi implements UserApiEntity {
  async getAuth(): Promise<GetAuthResponse | null> {
    const store = storeAuth();

    const getUserLocalStorage = localStorage.getItem('user')
    const refreshToken = localStorage.getItem('refreshToken')

    if (!refreshToken || !getUserLocalStorage) return null

    const userId = JSON.parse(getUserLocalStorage).id

    try {
      const { data } = await $http.post("auth/refresh", { userId, refreshToken });
      store.refresh(data)
      return data;
    } catch (error: any) {
      return { error };
    }
  }

  async getUsers(params: Params): Promise<ApiResponseIUser> {

    try {
      const { data } = await $http.get(`users?page=${params.page}`);

      return data;
    } catch (error: any) {
      throw error;
    }
  }

  async getPermissions(): Promise<GetPermissionResponse> {
    try {
      const { data } = await $http.get(`users/permissions/all`);
      return data;
    } catch (error: any) {
      return { error };
    }
  }

  async getRoles(): Promise<IRole[]> {
    try {
      const { data } = await $http.get(`users/roles/all`);
      const rolesForSelect = data.data.map((e: IRole) => ({
        id: e.id,
        nombre: e.title
      }))
      return rolesForSelect;
    } catch (error: any) {
       throw error;
    }
  }
  
  async getPermissionsByRoleId(id: number) {
    try {
      const { data } = await $http.get(`users/roles/${id}/permissions`);
      return data;
    } catch (error: any) {
      return { error };
    }
  }
  
  async createRole(body: any) {
    try {
      const { data } = await $http.post('/users/roles', body);

      return data;
    } catch (error: any) {
      throw error;
    }
  }
  
  async createUser(body: any): Promise<GetUserResponse> {
    try {
      const { data } = await $http.post('users', body);

      return data;
    } catch (error: any) {      
      throw error;
    }
  }
  
  async updateUser(id: number, body: any): Promise<GetUserResponse> {    
    try {
      const { data } = await $http.patch(`users/${id}`, body);

      return data;
    } catch (error: any) {      
      throw error;
    }
  }

  async updateRole(id: number, body: any) {
    try {
      const { data } = await $http.patch(`users/roles/${id}`, body);

      return data;
    } catch (error: any) {      
      throw error;
    }
  }

  async blockUser(id: number): Promise<GetUserResponse> {
    try {
      const { data } = await $http.delete(`users/${id}`);

      return data;
    } catch (error: any) {
      throw error;
    }
  }

  async unBlockUser(id: number): Promise<GetUserResponse> {
    try {
      const { data } = await $http.patch(`users/restore/${id}`);
      return data;
    } catch (error: any) {
      throw error;
    }
  }
}

export default UserApi;
