<script setup lang="ts">
import moment from 'moment';
import { ref, onMounted } from 'vue';
import LogsMailRepository from '../../repositories/logsmail.repository';
import { useToast } from '../../composables/useToast';

interface Loading {
  status: boolean;
  itemId: number | null;
}

const { $toast } = useToast();
const logsMailRepository = new LogsMailRepository();
const logsMailData = ref([]);
const loading = ref<Loading>({ status: false, itemId: null });
const showModal = ref<boolean>(false);
const emailDetailHTML = ref<string>('');
const showSubject = ref<boolean>(false);
const paginationData = ref();
const currentPage = ref<number>();
const inputSearch = ref<string>('');
const searchFormRef = ref<any>(null);

const getAuditsAPI = async (page: number, search: string) => {
  const { data } = await logsMailRepository.getlogsMail({
    page,
    search,
  });
  logsMailData.value = data.logsMail ?? [];
  paginationData.value = data.pagination;
};

onMounted(async () => {
  getAuditsAPI(1, inputSearch.value);
});

const fields: any = ref([
  {
    key: 'search',
    title: 'Buscar',
    type: 'text',
    placeholder: 'Buscar email por asunto',
    value: null,
    cols: 3,
  },
]);

const fieldsTable: any = ref([
  {
    key: 'id',
    label: 'Id',
    sortable: true,
  },
  {
    key: 'from',
    label: 'de',
  },
  {
    key: 'to',
    label: 'para',
  },
  {
    key: 'subject',
    label: 'asunto',
  },
  {
    key: 'sentAt',
    label: 'Fecha de envio',
    formatter: (e: any) =>
      e ? moment(e).format('DD-MM-YYYY HH:mm') : 'Sin fecha',
  },
  {
    key: 'sentAt',
    label: 'Estado',
    formatter: (e: any) => (e ? 'Enviado' : 'No enviado'),
  },
  {
    key: 'error',
    label: 'Error',
    formatter: (e: any) => e ?? 'Sin Error',
  },
  {
    key: 'retry',
    label: 'Reintentos',
  },
  {
    key: 'actions',
    label: 'Acciones',
  },
  {
    key: 'seeEmail',
    label: 'Ver',
  },
]);
const onChangePage = async (page: any) => {
  getAuditsAPI(page, inputSearch.value);
  currentPage.value = page;
};

const handleSendEmail = async ({ id }: { id: number }) => {
  try {
    loading.value = { status: true, itemId: id };
    await logsMailRepository.reSendEmail(id);
    $toast?.success(`Email reenviado con exito`);
  } catch (error) {
    $toast?.error('Ocurrió un error al querer reenviar el email');
  } finally {
    getAuditsAPI(paginationData.value.current_page, inputSearch.value);
    loading.value = { status: false, itemId: null };
  }
};
const toggleShowSubject = () => {
  showSubject.value = !showSubject.value;
};
const handleOpenModal = (item: any) => {
  showModal.value = true;
  emailDetailHTML.value = item.body;
};
const handleCloseModal = () => {
  showModal.value = false;
  emailDetailHTML.value = '';
};
const handleOnSubmit = async ({ items }: any) => {
  inputSearch.value = items.search;
  getAuditsAPI(1, items.search);
};
const handleReset = async () => {
  if (!searchFormRef.value) return;
  await searchFormRef.value.resetValues();
  inputSearch.value = '';
  getAuditsAPI(1, inputSearch.value);
};
</script>

<template>
  <Layout title="Logs Emails" :textColor="'color-secondary'">
    <template #layout>
      <div class="form__logEmail">
        <Form
          ref="searchFormRef"
          @onSubmit="handleOnSubmit"
          :inputs="fields"
          customClass="poliza__form"
        >
          <template #buttons>
            <CButton
              class="background-blue button__form"
              style="padding: 0.6rem !important; margin: 0.6rem"
            >
              <template #content> Buscar </template>
            </CButton>
            <CButton
              class="background-blue button__form"
              style="padding: 0.6rem !important; margin: 0.6rem"
              type="button"
              @click="handleReset"
            >
              <template #content> Limpiar filtro </template>
            </CButton>
          </template>
        </Form>
      </div>
      <Table
        :fields="fieldsTable"
        :items="logsMailData"
        @update:model-value-page="onChangePage"
        :totalRows="paginationData?.count"
        :perPage="20"
        :currentPage="paginationData?.current_page"
      >
        <template v-if="true" v-slot:cell(actions)="logsData">
          <div>
            <button
              class="background-pink p-1 border-0 w-100 text-white rounded-4"
              @click="handleSendEmail(logsData.data.item)"
              :disabled="
                loading.status && loading.itemId !== logsData.data.item.id
              "
            >
              <BSpinner
                v-if="
                  loading.status && loading.itemId === logsData.data.item.id
                "
                small="true"
              />
              <span v-else>Renviar email</span>
            </button>
          </div>
        </template>
        <template v-if="true" v-slot:cell(seeEmail)="logsData">
          <div>
            <button
              class="background-blue border-0 text-white rounded-4"
              @click="handleOpenModal(logsData.data.item)"
            >
              <img
                src="/icons/eye.svg"
                style="width: 30px; height: 30px; cursor: pointer"
              />
            </button>
          </div>
        </template>
        <template v-if="true" v-slot:cell(subject)="subject">
          <div class="d-flex align-self-center" @click="toggleShowSubject">
            <span
              :class="[showSubject ? '' : 'truncatedText']"
              :title="subject.data.value"
            >
              {{ subject.data.value }}
            </span>
          </div>
        </template>
      </Table>
      <BModal
        :modelValue="showModal"
        hide-footer
        :centered="true"
        @hide="handleCloseModal"
        title="Email"
        size="xl"
        static
      >
        <div class="modal-content">
          <div class="modal-body" style="overflow-y: auto">
            <div v-html="emailDetailHTML"></div>
          </div>
        </div>
      </BModal>
    </template>
  </Layout>
</template>

<style scoped>
.truncatedText {
  max-width: 15rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  margin: 0;
}
.form__logEmail {
  width: 50%;
}
</style>
