import baseApiUrl from "../BaseApiUrl";

function createRoute(path: string) {
  return {
    INDEX: baseApiUrl + '/api/' + path,
    STORE: baseApiUrl + '/api/' + path,
    UPDATE: baseApiUrl + '/api/' + path,
    SHOW: baseApiUrl + '/api/' + path,
    DELETE: baseApiUrl + '/api/' + path,

  };
}

export const Routes = {
  DATA_PROVIDER: {
    INDEX: baseApiUrl + '/api/dataProviders',
  },
  TRANSPORTADORES: createRoute('transportadores'),
  DOMICILIOS: createRoute('domicilios'),
  TERMINALES: createRoute('terminales'),
  CONTACTOS: createRoute('contactos'),
  DANIOS: createRoute('danios'),
  PARTES: createRoute('partes'),
  MODELOS: createRoute('modelos'),
  TIPO_EVENTOS: createRoute('eventos'),
  OPERACIONES: {
    INDEX: baseApiUrl + '/clients/policies'
  },
  COMPANIASGPS: {
    INDEX: baseApiUrl + '/companiesGPS',
    STORE: baseApiUrl + '/companiesGPS',
    UPDATE: baseApiUrl + '/companiesGPS',
    SHOW: baseApiUrl + '/companiesGPS',
    DELETE: baseApiUrl + '/companiesGPS',
    ALL: baseApiUrl + '/companiesGPS/all',
  },
  AGENTES: {
    INDEX: baseApiUrl + '/agents',
    STORE: baseApiUrl + '/agents',
    UPDATE: baseApiUrl + '/agents',
    SHOW: baseApiUrl + '/agents',
    DELETE: baseApiUrl + '/agents',
    RESTORE: baseApiUrl + '/agents/restore',
    ALL: baseApiUrl + '/agents/all',
  },
  CLIENTES: {
    INDEX: baseApiUrl + '/clients',
    STORE: baseApiUrl + '/clients',
    UPDATE: baseApiUrl + '/clients',
    SHOW: baseApiUrl + '/clients',
    DELETE: baseApiUrl + '/clients',
    ALL: baseApiUrl + '/clients',
  }
};
