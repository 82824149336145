<script setup lang="ts">
import { onBeforeUnmount, onMounted, ref, toRefs, reactive } from 'vue';

// Pinia
import Store from '@store/dataProvider';
import { storeToRefs } from 'pinia';

// Composables
import { useMessage } from '../../../composables/useMessage';

//types
import { IRole, IPermission } from '../../../types/user.type';
import { IAgents } from './../../../types/agents.type';
import { ITemplateUI } from '../../../types/templatesUI.type';

//repositories
import UserApi from '@repositories/user.repository';

//validations
import { insertHtmlValidations, validateFormat } from './handleValidator';
import CountriesRepository from '../../../repositories/countries.repository';
import { ICountry } from '../../../types/countries.type';

const store = Store();
const { users }: any = storeToRefs(store);

const { messageHTML, showMessage } = useMessage();

const props = defineProps({
  agents: {
    type: Object as () => Array<IAgents>,
  },
  templatesUI: {
    type: Object as () => Array<ITemplateUI>,
    default: () => ({
      templatesUI: {
        id: '',
        name: '',
      },
    }),
  },
});
const { agents, templatesUI } = toRefs(props);

const emit = defineEmits<{
  (e: 'closeModal'): void;
}>();

const userApiRepository = new UserApi();
const rolesData = ref<IRole[]>([]);
const countriesData = ref<ICountry[]>([]);
const selectedRoles = ref<IRole[]>([]);
const permissionsActive = ref<IPermission[]>([]);
const valueSelectAgents = ref<any>([]);
const valueSelectCountries = ref<any>([]);
const refTableForm = ref<any>(null);
const passwordValidate: any = reactive({
  containNumber: /.*\d+.*/,
  containUppercase: /[A-Z]/,
  containLowercase: /[a-z]/,
  containSymbol: /.*[\W_].*/,
  minLength: /^.{8,}$/,
});

onMounted(async () => {
  const data = await userApiRepository.getRoles();
  const dataCountries = await CountriesRepository.getcountries();

  countriesData.value = dataCountries.data.countries.map((e: ICountry) => ({
    id: e.id,
    nombre: e.description,
  }));

  rolesData.value = data;
  insertHtmlValidations(passwordValidate);
});

const fields: any = ref([
  {
    key: 'email',
    title: 'Email del Usuario',
    type: 'email',
    validations: {
      rules: {
        required: true,
        email: true,
      },
    },
    placeholder: 'Email del Cliente',
    value: null,
    cols: 6,
  },
  {
    key: 'fullName',
    title: 'Nombre Completo',
    type: 'text',
    validations: {
      rules: {
        required: true,
      },
    },
    placeholder: 'Nombre Completo',
    value: null,
    cols: 6,
  },
  {
    key: 'password',
    title: 'Contraseña',
    type: 'password',
    validations: {
      rules: {
        required: true,
        min: 8,
        verifyExpreRegular:
          /^(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\-])(?=.*[A-Z])(?=.*[a-z])(?=.*\d).+$/,
      },
    },
    placeholder: 'Ejemplo.123',
    value: null,
    cols: 6,
    customClass: 'inputs-password',
    formatValue: (e: any) => validateFormat(e, passwordValidate),
  },
  {
    key: 'repeatPassword',
    title: 'Repetir Contraseña',
    type: 'password',
    validations: {
      rules: {
        required: true,
      },
    },
    placeholder: 'Repetir contraseña',
    value: null,
    cols: 6,
  },
  {
    key: 'countries',
    title: 'Selecciona los paises del usuario',
    selectLabel: null,
    type: 'select',
    placeholder: 'Seleccionar Paises',
    value: valueSelectCountries,
    cols: 6,
    options: countriesData,
    validations: {
      rules: {
        required: true,
      },
    },
    hidden: false,
    multiple: true,
    listenSelect: false,
    listenRemove: false,
  },
  {
    key: 'apiKeyId',
    title: 'Compañía',
    type: 'select',
    placeholder: 'Elije la compañia',
    value: [{ nombre: 'Ninguna', id: 3 }],
    selectLabel: null,
    cols: 6,
    options: [
      { nombre: 'Vink', id: 1 },
      { nombre: 'ControlMatch', id: 2 },
      { nombre: 'Ninguna', id: 3 },
    ],
  },
  {
    key: 'agents',
    customLabel: (e: any) => `${e.name} - [${e.rfc}]`,
    searchable: true,
    title: 'Selecciona los agentes asociados',
    type: 'select',
    placeholder: 'selecciona uno, varios o ninguno',
    value: valueSelectAgents,
    cols: 6,
    options: agents?.value,
    multiple: true,
    listenSelect: false,
    listenRemove: false,
  },

  {
    key: 'roles',
    title: 'Selecciona los roles del usuario',
    selectLabel: null,
    type: 'select',
    placeholder: 'Seleccionar Roles',
    value: selectedRoles,
    cols: 6,
    options: rolesData,
    validations: {
      rules: {
        required: true,
      },
    },
    hidden: false,
    multiple: true,
    listenSelect: true,
    listenRemove: true,
  },
  {
    key: 'templateId',
    title: 'Plantilla',
    type: 'select',
    placeholder: 'Elije una opcion',
    value: null,
    selectLabel: null,
    cols: 12,
    options: templatesUI?.value?.map((e: ITemplateUI) => ({
      nombre: e.name,
      id: e.id,
    })),
  },
]);

const handleSelect = async (e: any) => {
  const respose = await refTableForm.value.getValues();

  valueSelectAgents.value = respose.items.agents;
  valueSelectCountries.value = respose.items.countries;

  if (selectedRoles.value.length === 0) {
    selectedRoles.value = e.value;
    const { data } = await userApiRepository.getPermissionsByRoleId(
      e.value[0].id,
    );
    permissionsActive.value = data;
  } else {
    const differentObject = e.value.find(
      (itemA: any) =>
        !selectedRoles.value.some((itemB) => itemA.id === itemB.id),
    );

    if (differentObject) {
      selectedRoles.value = e.value;

      const { data } = await userApiRepository.getPermissionsByRoleId(
        differentObject.id,
      );

      permissionsActive.value = [...permissionsActive.value, ...data];
      permissionsActive.value = permissionsActive.value.filter(
        (item, index, self) =>
          index === self.findIndex((obj) => obj.id === item.id),
      );
    }
  }
};

const handleRemove = async (e: any) => {
  selectedRoles.value = selectedRoles.value.filter((i) => i.id !== e.value.id);
  const respose = await refTableForm.value.getValues();

  valueSelectAgents.value = respose.items.agents;
  valueSelectCountries.value = respose.items.countries;

  if (!selectedRoles.value.length) {
    permissionsActive.value = [];
  }

  for (const role of selectedRoles.value) {
    const { data } = await userApiRepository.getPermissionsByRoleId(role.id);

    permissionsActive.value = data;
  }
};

const handleOnSubmit = async ({ items, isFormValid }: any) => {
  if (!isFormValid) return;
  if (items.password !== items.repeatPassword) {
    return showMessage('Las contraseñas deber ser iguales', 'error');
  } else {
    showMessage(null);
  }

  const newUser = {
    ...items,
    templateId: items.templateId ? items.templateId.id : null,
    apiKeyId:
      items.apiKeyId.id === 3 || !items.apiKeyId.id ? null : items.apiKeyId.id,
  };

  try {
    const { data } = await userApiRepository.createUser(newUser);
    store.setProp({ key: 'users', value: [...users.value, data] });
    emit('closeModal');
  } catch (error: any) {
    showMessage(error.message, 'error');
  }
};

onBeforeUnmount(() => {
  fields.value.forEach((field: any) => {
    field.value = null;
  });
});
</script>

<template>
  <Form
    ref="refTableForm"
    :inputs="fields"
    @onSubmit="handleOnSubmit"
    @select="handleSelect"
    @remove="handleRemove"
  >
    <template #buttons>
      <p v-if="permissionsActive.length">
        Permisos Activos para roles asignados
      </p>
      <ul v v-for="permission in permissionsActive" :key="permission.id">
        <li>{{ permission.descripcion }}</li>
      </ul>
      <div v-if="messageHTML" v-html="messageHTML" />
      <div class="d-flex">
        <CButton class="w-100 fs-6 p-2" background="background-blue">
          <template #content>
            <div>
              <span>Crear</span>
            </div>
          </template>
        </CButton>
      </div>
    </template>
  </Form>
</template>
