import PublicRoutes from './PublicRoutes';
import AjustesRoute from './AjustesRoute';
import Operaciones from '../views/Operaciones/Operaciones.vue';
import NuevaOperacion from '../views/NuevaOperacion/NuevaOperacion.vue';
import Polizas from '../views/Polizas/Polizas.vue';
import NumeracionPolizas from '../views/NumeracionPolizas/NumeracionPolizas.vue';
import SeguimientoWeb from '../views/SeguimientoWeb/SeguimientoWeb.vue';
import LogsEmails from '../views/LogsEmails/LogsEmails.vue';
import LogsPoliciesApi from '../views/LogsPoliciesApi/LogsPoliciesApi.vue';
import HomePanel from '../views/HomePanel/HomePanel.vue';
import NocLog from '../views/Noc/NocLogs.vue';
import { RouteRecordRaw } from 'vue-router';

const PrivateRoutes: Array<RouteRecordRaw> = [
  {
    path: '/panel',
    name: 'Panel',
    meta: { gate: true },
    children: [
      ...AjustesRoute,
      {
        path: 'home',
        name: 'HomePanel',
        component: HomePanel,
      },
      {
        path: 'operaciones',
        name: 'Operaciones',
        component: Operaciones,
      },
      {
        path: 'nueva-operacion',
        name: 'Nueva Operacion',
        component: NuevaOperacion,
      },
      {
        path: 'polizas',
        name: 'Listar Polizas',
        component: Polizas,
      },
      {
        path: 'numeracion-polizas',
        name: 'Actualizar poliza Chubb',
        component: NumeracionPolizas,
      },
      {
        path: 'seguimiento-web',
        name: 'Seguimiento web',
        component: SeguimientoWeb,
      },
      {
        path: 'log-emails',
        name: 'Logs Emails',
        component: LogsEmails,
      },
      {
        path: 'log-policies-api',
        name: 'Logs Polizas API',
        component: LogsPoliciesApi,
      },
      {
        path: 'noc-log',
        name: 'Noc',
        component: NocLog,
      },
    ],
  },
];

export default PrivateRoutes;
