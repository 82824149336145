import Ajustes from '@/views/Ajustes/Ajustes.vue'
import CompaniasGPS from "../views/Ajustes/CompaniasGPS.vue";
import Usuarios from "../views/Ajustes/Usuarios/Usuarios.vue";
import Agentes from "../views/Ajustes/Agentes.vue";
import Plantillas from "../views/Ajustes/PlantillasUI/PlantillasUI.vue";
import Paises from '../views/Ajustes/Paises.vue';
import storeAuth from "../store/auth";

const AjustesRouter = [
  {
    path: 'ajustes',
    name: 'Ajustes',
    component: Ajustes,
    children: [
      {
        path: 'companias-gps',
        name: 'CompaniasGps',
        component: CompaniasGPS,
        beforeEnter: (to: any, from: any, next: any) => {
          const { user } = storeAuth()

          if (user?.permission.includes(to.name)) {
            next()
          } else {
            next('/panel/ajustes');
          }
        }
      },
      {
        path: 'usuarios',
        name: 'Usuarios',
        component: Usuarios,
      },
      {
        path: 'agentes',
        name: 'Agentes',
        component: Agentes,
      },
      {
        path: 'plantillas',
        name: 'Plantillas',
        component: Plantillas,
      },
      {
        path: 'paises',
        name: 'Paises',
        component: Paises,
      },
    ],
  },
];
export default AjustesRouter;
