import $http from "../services/AxiosInstance";
import { saveAs } from 'file-saver';

interface Params {
  page?: number,
}

class AuditsRepository {
  async getAudits(params: Params) {
    try {
      const response = await $http.get(`/audits?page=${params.page}`);
      return response.data;
    } catch (errors: any) {
      return errors;
    }
  }
  async getAuditsExcel() {
    try {
      const config = {
        responseType: 'blob' as any,
      };
      const { data } = await $http.get(`/audits/excel`, config);
     
      saveAs(data, `Seguimiento-web.xlsx`);

    } catch (errors: any) {
      return errors;
    }
  }
}

export default AuditsRepository;
