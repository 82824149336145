<script lang="ts" setup>
import './Home.scss';
import './Contratacion.scss';
import { ref, computed, onMounted } from 'vue';
import Footer from './components/Footer.vue';
import {
  findCompaniesGPS,
  createOrUpdateClient,
  createPolicyForTrygerLatam,
  findClientByTributaryCode,
} from '../../repositories/landing.repository';
import { wrongDepartureDateTime, today } from '../../helpers';
import storePolicy from '@store/policy';
import { useRouter } from 'vue-router';
import { BModal } from 'bootstrap-vue-next';
import WhatsappButton from './components/WhatsappButton.vue';
import { validationRut } from '../../helpers/validationRUT';
import GeoRefChileRepository from '../../repositories/geoRefChile.repository';
import { IRegion } from '../../repositories/geoRefChile.repository';
import {
  finalCalculationCH,
  IFinalCalculation,
} from '../../helpers/finalCalculationCH';
import CaptchaGoogle from './components/CaptchaGoogle.vue';

const store = storePolicy();
const router = useRouter();
const refForm2 = ref<any>(null);
const refForm3 = ref<any>(null);
const inputsHidden = ref<boolean>(true);
const isOpenModal = ref<boolean>(false);
const checkedAuth = ref<boolean>(false);
const loading = ref<boolean>(false);
const policyId = ref<number | null>(1);
const error = ref<string>('');
const regionesCHI = ref<IRegion[]>([]);
const companySelectedByParams = ref<{ nombre?: string; id: number } | null>(
  null,
);
const finalCalculation = ref<IFinalCalculation | null>(null);
const tokenCaptcha = ref<string | null>(null);

onMounted(() => {
  const thereAreNullProps = Object.values(store.data).some(
    (valor) => valor === null,
  );

  if (thereAreNullProps) {
    router.push('/');
  }
});

onMounted(async () => {
  const regiones = await GeoRefChileRepository.getRegiones();

  regionesCHI.value = regiones;
  const comapaniesGPS = await findCompaniesGPS();
  fieldsForm2.value = fieldsForm2.value.map((field: any) => {
    if (field.key === 'companyGPSId') {
      field.options = comapaniesGPS;
      const gpsCompany = store.data.queryParams.gpsCompany;
      if (gpsCompany) {
        const company = comapaniesGPS.find((company) => {
          return company.slug === gpsCompany;
        });
        if (company) {
          companySelectedByParams.value = company;
          field.value = company;
          field.disabled = true;
          field.customClass =
            'multiselect--disabled .multiselect__select inputLanding';
        }
      }
    }
    if (field.key === 'region') {
      field.options = regiones;
    }

    return field;
  });
});

computed(() => fieldsForm3.value);

const fieldsForm1: any = ref([
  {
    key: 'rut',
    title: 'RUT del cliente',
    type: 'text',
    validations: {
      rules: {
        required: true,
      },
    },
    placeholder: 'RUT del cliente (sin guiones)',
    value: null,
    cols: 12,
    customClass: 'inputLanding text-uppercase',
    hidden: false,
    errors: [],
  },
]);

const fieldsForm2: any = ref([
  {
    key: 'tradeName',
    title: 'Nombre del cliente',
    type: 'text',
    validations: {
      rules: {
        required: true,
      },
    },
    placeholder: 'Nombre del cliente',
    value: null,
    cols: 12,
    customClass: 'inputLanding',
    formatValue: (e: string) => e?.toUpperCase() ?? '',
  },
  {
    key: 'email',
    title: 'Email del Cliente',
    type: 'email',
    validations: {
      rules: {
        required: true,
        email: true,
      },
    },
    placeholder: 'Email del Cliente',
    value: store.data.email ?? null,
    cols: 12,
    customClass: 'inputLanding',
  },
  {
    key: 'phone1',
    title: 'Teléfono del Cliente',
    type: 'text',
    placeholder: 'Teléfono del Cliente',
    value: null,
    cols: 12,
    customClass: 'inputLanding',
  },
  {
    key: 'companyGPSId',
    title: 'Empresa GPS',
    selectLabel: null,
    type: 'select',
    validations: {
      rules: {
        required: true,
      },
    },
    disabled: false,
    placeholder: 'Elije la empresa gps',
    value: null,
    cols: 12,
    customClass: 'inputSelect',
    options: [],
  },
  {
    key: 'address',
    title: 'Dirección del Cliente',
    type: 'text',
    validations: {
      rules: {
        required: true,
      },
    },
    placeholder: 'Dirección del Cliente',
    value: null,
    cols: 12,
    customClass: 'inputLanding',
  },
  {
    key: 'postCode',
    title: 'C.P.',
    type: 'text',
    validations: {
      rules: {
        required: true,
      },
    },
    placeholder: 'Codigo Postal',
    value: null,
    cols: 12,
    customClass: 'inputLanding',
  },
  {
    key: 'region',
    title: 'Región',
    selectLabel: null,
    type: 'select',
    validations: {
      rules: {
        required: true,
      },
    },
    placeholder: 'Elije una región',
    value: null,
    cols: 12,
    customClass: 'inputSelect',
    options: [],
    listenSelect: true,
  },
  {
    key: 'comuna',
    title: 'Comuna',
    type: 'select',
    validations: {
      rules: {
        required: true,
      },
    },
    placeholder: 'Elije una comuna',
    value: null,
    options: [],
    cols: 12,
    customClass: 'inputSelect',
  },
]);

const fieldsForm3: any = ref([
  { slot: true, key: 'title' },
  {
    key: 'typeOfMerchandise',
    title: 'Tipo de Mercancía',
    type: 'text',
    validations: {
      rules: {
        required: true,
      },
    },
    placeholder: 'Tipo de Mercancías',
    value: store.data?.asset?.nombre,
    cols: 12,
    customClass: 'inputLanding',
    disabled: true,
  },
  {
    key: 'indemnificationLimit',
    title: 'Límite de indemnización',
    type: 'number',
    validations: {
      rules: {
        required: true,
      },
    },
    placeholder: 'Límite de indemnización',
    value: store.data.indemnificationLimit,
    cols: 6,
    customClass: 'inputLanding',
    disabled: true,
  },
  {
    key: 'indemnificationLimit-calc',
    title: 'Precio',
    type: 'text',
    value: `$${store.data.endPrice} + IVA`,
    validations: {
      rules: {
        required: true,
      },
    },
    placeholder: 'Precio + IVA',
    disabled: true,
    cols: 6,
    customClass: 'inputLanding',
  },
  {
    key: 'originRegion',
    title: 'Región de origen',
    type: 'select',
    validations: {
      rules: {
        required: true,
      },
    },
    options: regionesCHI,
    placeholder: 'Elije la región de origen',
    value: null,
    cols: 6,
    customClass: 'inputSelect',
    listenSelect: true,
  },
  {
    key: 'originComuna',
    title: 'Comuna de origen',
    type: 'select',
    validations: {
      rules: {
        required: true,
      },
    },
    placeholder: 'Elije la comuna de Origen',
    value: null,
    cols: 6,
    options: [],
    customClass: 'inputSelect',
    disabled: true,
  },
  {
    key: 'destinationRegion',
    title: 'Región destino final',
    type: 'select',
    validations: {
      rules: {
        required: true,
      },
    },
    placeholder: 'Elije la región de destino',
    value: null,
    options: regionesCHI,
    cols: 6,
    customClass: 'inputSelect',
    listenSelect: true,
  },
  {
    key: 'destinationComuna',
    title: 'Comuna destino final',
    type: 'select',
    validations: {
      rules: {
        required: true,
      },
    },
    placeholder: 'Elije la comuna de Destino',
    value: null,
    cols: 6,
    options: [],
    customClass: 'inputSelect',
    disabled: true,
  },
  {
    key: 'stops',
    title: 'Paradas entre Origen y Destino Final*',
    selectLabel: null,
    type: 'select',
    placeholder: 'Tiene paradas?',
    value: null,
    cols: 3,
    customClass: 'inputSelect',
    validations: {
      rules: {
        required: true,
      },
    },
    options: [
      { nombre: 'Si', id: 1 },
      { nombre: 'No', id: 0 },
    ],
    listenSelect: true,
  },
  {
    key: 'stop1',
    title: 'Parada 1',
    type: 'text',
    placeholder: 'Parada 1',
    value: null,
    cols: 3,
    disabled: true,
    validations: {
      rules: {
        required: false,
      },
    },
    customClass: 'inputLanding inputLanding--mt',
  },
  {
    key: 'stop2',
    title: 'Parada 2',
    type: 'text',
    placeholder: 'Parada 2',
    value: null,
    cols: 3,
    disabled: true,
    customClass: 'inputLanding inputLanding--mt',
  },
  {
    key: 'stop3',
    title: 'Parada 3',
    type: 'text',
    placeholder: 'Parada 3',
    value: null,
    cols: 3,
    disabled: true,
    customClass: 'inputLanding inputLanding--mt',
  },
  {
    key: 'startDate',
    title: 'Fecha de inicio del viaje',
    type: 'date',
    placeholder: 'Fecha de inicio',
    value: null,
    cols: 6,
    min: today(),
    validations: {
      rules: {
        required: true,
      },
    },
    customClass: 'inputLanding',
  },
  {
    key: 'startTime',
    title: 'Hora de inicio del viaje',
    type: 'time',
    placeholder: 'Hora de inicio',
    value: null,
    cols: 6,
    validations: {
      rules: {
        required: true,
      },
    },
    customClass: 'inputLanding',
    listenFocus: true,
  },
  {
    key: 'endDate',
    title: 'Fecha de fin del Viaje (aproximada)',
    type: 'date',
    placeholder: 'Fecha de fin',
    value: null,
    cols: 6,
    min: today(),
    validations: {
      rules: {
        required: true,
      },
    },
    customClass: 'inputLanding',
  },
  {
    key: 'endTime',
    title: 'Hora de fin del viaje',
    type: 'time',
    placeholder: 'Hora de fin',
    value: null,
    cols: 6,
    validations: {
      rules: {
        required: true,
      },
    },
    customClass: 'inputLanding',
  },
  {
    key: 'documentDescription',
    title: 'Documento del viaje',
    type: 'text',
    placeholder: 'Documento del Viaje',
    value: null,
    cols: 6,
    validations: {
      rules: {
        required: true,
      },
    },
    customClass: 'inputLanding',
  },
  {
    key: 'document',
    title: 'Adjuntar documento',
    type: 'file',
    placeholder: 'Adjuntar documento',
    value: null,
    cols: 6,
    hidden: false,
    customClass: 'inputFile',
  },
  {
    key: 'truckPlate',
    title: 'Placa Tractor',
    type: 'text',
    placeholder: 'Placa Tractor',
    value: null,
    cols: 4,
    validations: {
      rules: {
        required: true,
      },
    },
    customClass: 'inputLanding',
  },
  {
    key: 'box-trailer',
    title: 'Caja de Carga',
    selectLabel: null,
    type: 'select',
    placeholder: 'Caja de Carga',
    value: null,
    cols: 4,
    options: [
      { nombre: 'Si', id: 1 },
      { nombre: 'No', id: 0 },
    ],
    validations: {
      rules: {
        required: true,
      },
    },
    hidden: false,
    customClass: 'inputSelect',
    listenSelect: true,
  },
  {
    key: 'trailerPlate',
    title: 'Placa caja',
    type: 'text',
    placeholder: 'Placa caja',
    value: null,
    cols: 4,
    customClass: 'inputLanding',
    validations: {
      rules: {
        required: false,
      },
    },
    disabled: true,
  },
  {
    key: 'driverName',
    title: 'Nombre del conductor',
    type: 'text',
    placeholder: 'Nombre del conductor',
    value: null,
    cols: 12,
    validations: {
      rules: {
        required: true,
      },
    },
    customClass: 'inputLanding',
  },
  {
    key: 'driverCellphone',
    title: 'Móvil del conductor',
    type: 'text',
    placeholder: 'Móvil del conductor',
    value: null,
    cols: 12,
    validations: {
      rules: {
        required: true,
        max: 12,
        min: 8,
      },
    },
    customClass: 'inputLanding',
  },
  {
    key: 'driverEmail',
    title: 'Email del conductor',
    type: 'email',
    placeholder: 'Email del conductor',
    value: null,
    cols: 12,
    validations: {
      rules: {
        email: true,
      },
    },
    hidden: false,
    customClass: 'inputLanding',
  },
  { slot: true, key: 'finalCalculation' },
  { slot: true, key: 'endForm' },
]);

onMounted(() => {
  const windowWidth = window.innerWidth;
  if (windowWidth <= 480) {
    fieldsForm3.value.forEach((field: any) => {
      field.cols = 12;
    });
  }
});

const handleOnSubmitRUT = async (e: any) => {
  // 19876543-0 42.245.781-K
  const isValidRUT = validationRut(e.items.rut);
  if (!e.isFormValid || !isValidRUT) {
    return (fieldsForm1.value[0].errors = ['RUT es invalido']);
  }
  const rutWithoutSpecialChars = e.items.rut.replace(/[-.]/g, '');
  const { client } = await findClientByTributaryCode(rutWithoutSpecialChars);

  if (client) {
    store.setProp({ key: 'client', value: client });
  }

  if (client) {
    const updatesClientInputs = [
      { key: 'tradeName', value: client.tradeName },
      { key: 'phone1', value: client.phone1 },
      {
        key: 'companyGPSId',
        value: {
          id: companySelectedByParams.value?.id ?? client.companyGPS.id,
          nombre:
            companySelectedByParams.value?.nombre ??
            client.companyGPS.description,
        },
      },
      { key: 'address', value: client.address },
      { key: 'region', value: { nombre: client.regionProvince } },
      { key: 'comuna', value: { nombre: client.location } },
      { key: 'postCode', value: client.postCode },
    ];

    updateFieldsInArray(fieldsForm2.value, updatesClientInputs, null);
  }
  fieldsForm1.value.find((field: any) => field.key === 'rut').disabled = true;

  inputsHidden.value = false;

  if (!client) {
    store.setProp({ key: 'client', value: { rut: e.items.rut } });
  }
};
// Handles form changes for refForm3
const handleSelectChange = async (e: any) => {
  const updateFields: {
    key: string;
    disabled?: boolean;
    'validations.rules.required'?: boolean;
    options?: any[];
  }[] = [];

  const updateCommonFields = (key: string, isEnabled: boolean) => {
    updateFields.push(
      { key, disabled: !isEnabled },
      { key, 'validations.rules.required': isEnabled },
    );
  };

  switch (e.input.key) {
    case 'box-trailer':
      updateCommonFields('trailerPlate', !!e.value.id);
      break;

    case 'originRegion':
    case 'destinationRegion':
      const cities = await GeoRefChileRepository.getComunas(e.value.id);
      const cityKey =
        e.input.key === 'originRegion' ? 'originComuna' : 'destinationComuna';
      await refForm3.value.setValue({ key: cityKey, value: null });
      updateFieldsInArray(
        fieldsForm3.value,
        [{ key: cityKey, options: cities, disabled: false }],
        null,
      );
      return;
    default:
      ['stop1', 'stop2', 'stop3'].forEach((stop) =>
        updateCommonFields(stop, !!e.value.id),
      );
      break;
  }

  updateFieldsInArray(fieldsForm3.value, updateFields, null);
};

// La funcion busca las localidades al elejir la provincia
// Al obtener de que provincia es el cliente ya podemos generar el calculo final de la poliza
const handleGetCities = async () => {
  const { items } = await refForm2.value.getValues();
  const comunas = await GeoRefChileRepository.getComunas(items.region.id);
  await refForm2.value.setValue({ key: 'comuna', value: null });

  const preservedValues = {
    tradeName: items.tradeName,
    email: items.email,
    phone1: items.phone1,
    companyGPSId: items.companyGPSId,
    address: items.address,
    region: items.region,
  };

  updateFieldsInArray(
    fieldsForm2.value,
    [{ key: 'comuna', options: comunas, value: null }],
    preservedValues,
  );

  finalCalculation.value = finalCalculationCH(
    store.data.endPrice,
    store.pricing.uf,
  );
};

const updateFieldsInArray = (
  array: any,
  updates: any,
  preservedValues: any,
) => {
  for (const update of updates) {
    const field = array.find((field: any) => field.key === update.key);
    if (field) {
      if ('type' in update) {
        field.type = update.type;
      }
      if ('options' in update) {
        field.options = update.options;
      }
      if ('value' in update) {
        field.value = update.value;
      }
      if ('disabled' in update) {
        field.disabled = update.disabled;
      }

      if ('customClass' in update) {
        field.customClass = update.customClass;
      }
    }
  }

  if (preservedValues) {
    for (const key in preservedValues) {
      const field = array.find((field: any) => field.key === key);
      if (field) {
        field.value = preservedValues[key];
      }
    }
  }
};

// Submit form with all values
const handleOnSubmitPolicies = async ({ items, isFormValid }: any) => {
  const { items: itemsForm2, isFormValid: isFormValidForm2 } =
    await refForm2.value.getValues();

  if (true) {
    return (error.value = 'Api de chile aun sin funcionar');
  }

  if (!tokenCaptcha.value) {
    return (error.value = 'Por favor, verifica el reCAPTCHA primero');
  }

  if (!checkedAuth.value) {
    return (error.value = 'Términos y Condiciones no aceptados');
  }

  if (!isFormValidForm2) {
    return (error.value = 'El formulario debe estar completo');
  }
  if (!isFormValid) {
    return (error.value = 'El formulario debe estar completo');
  }

  const {
    address,
    companyGPSId,
    email,
    phone1,
    tradeName,
    comuna,
    region,
    postCode,
  } = itemsForm2;

  const {
    originComuna,
    typeOfMerchandise,
    destinationComuna,
    indemnificationLimit,
    truckPlate,
    trailerPlate,
    driverName,
    documentDescription,
    driverCellphone,
    driverEmail,
    startDate,
    endDate,
    startTime,
    endTime,
    stops,
    stop1,
    stop2,
    stop3,
    document,
    originRegion,
    destinationRegion,
  } = items;

  const isOkDepartureDateTime = wrongDepartureDateTime(startDate, startTime);

  if (isOkDepartureDateTime) {
    return (error.value =
      'La hora de salida no puede ser menor a dentro de 90 minutos.');
  }
  if (tradeName.split(' ').length < 2) {
    return (error.value = 'Debe proporcionar al menos un nombre y un apellido');
  }

  try {
    loading.value = true;
    const rutWithoutSpecialChars = store.client.rut.replace(/[-.]/g, '');

    const createOrUpdateClientData = {
      tradeName,
      address,
      email,
      phone1,
      location: comuna.nombre,
      postCode,
      rut: rutWithoutSpecialChars,
      regionProvince: region.nombre,
      companyGPSId: store.client?.companyGPS?.id ?? companyGPSId.id,
      urlDomain: window.location.origin,
    };

    const client = await createOrUpdateClient(
      store.client.id,
      createOrUpdateClientData,
      'CL',
    );
    store.setProp({
      key: 'client',
      value: { ...store.client, id: client.data.client.id },
    });

    const travelStartDate = new Date(`${startDate}T${startTime}`).toISOString();
    const travelEndDate = new Date(`${endDate}T${endTime}`).toISOString();
    const assetId = store.data.asset.id;

    const createPolicyData: any = {
      tradeName,
      assetId,
      phone1,
      typeOfMerchandise,
      indemnificationLimit,
      originRegionProvince: originRegion.nombre,
      destinationRegionProvince: destinationRegion.nombre,
      originLocation: originComuna.nombre ?? originComuna,
      destinationLocation: destinationComuna.nombre ?? destinationComuna,
      stop1,
      stop2,
      stop3,
      trailerPlate,
      truckPlate,
      driverName,
      documentDescription,
      driverCellphone,
      driverEmail,
      travelStartDate,
      travelEndDate,
      hasStops: stops.nombre,
      companyGPSId: companyGPSId.id,
      document: document?.length ? document[0].image : null,
      clientName: tradeName,
      tributaryCode: store.client.rut,
      address,
      postalCode: postCode,
      location: comuna.nombre,
      regionProvince: region,
      email,
      endPrice: finalCalculation?.value?.primaTotal,
      token: tokenCaptcha.value,
    };

    const urlForPayment = await createPolicyForTrygerLatam(
      client.data.client.id,
      client.data.manager.id,
      createPolicyData,
    );

    window.location.replace(urlForPayment);
    loading.value = false;
  } catch (e) {
    error.value = 'Ocurrió un Error.';
    loading.value = false;
  }
};

const hideModal = () => {
  isOpenModal.value = false;
};
const handleTokenCaptcha = (token: string) => {
  tokenCaptcha.value = token;
};

const handleOnExpired = () => {
  tokenCaptcha.value = null;
};
</script>

<template>
  <h1 class="landing__title">Seguro de Mercancias por trayecto</h1>
  <h2 class="landing__subtitle">¡YA FALTA MENOS!</h2>
  <h2 class="landing__subtitle">AYUDANOS COMPLETANDO EL DETALLE DEL VIAJE</h2>
  <div class="contratacion">
    <div class="contratacion__container">
      <h2 class="text-center text-white">Datos del cliente</h2>
      <Form
        ref="refTableForm"
        :inputs="fieldsForm1"
        @onSubmit="handleOnSubmitRUT"
      >
        <template #buttons>
          <div class="d-flex" v-if="inputsHidden">
            <CButton
              class="p-4 w-100 my-2 px-2"
              background="background-green"
              style="font-size: 1rem"
            >
              <template #content> Comprobar RUT </template>
            </CButton>
          </div>
        </template>
      </Form>
      <template v-if="!inputsHidden">
        <Form
          ref="refForm2"
          :inputs="fieldsForm2"
          @select="handleGetCities"
        ></Form>
        <Form
          ref="refForm3"
          :inputs="fieldsForm3"
          @onSubmit="handleOnSubmitPolicies"
          @select="handleSelectChange"
        >
          <template #title>
            <h2 class="text-center text-white mt-5">Datos del viaje</h2>
          </template>
          <template #endForm>
            <h2 class="text-white fs-5 text-decoration-underline">
              Términos y condiciones
            </h2>
            <b-form-checkbox v-model="checkedAuth" name="check-button" switch>
              <p class="text-white">
                Declaro haber leído y aceptado las
                <span
                  @click="isOpenModal = true"
                  class="text-decoration-underline"
                  >Condiciones Generales y Particulares</span
                >
                de esta póliza.
              </p>
            </b-form-checkbox>
          </template>
          <template #finalCalculation>
            <div v-if="finalCalculation" class="container p-0 mt-2">
              <b-card class="rounded-4">
                <h2 class="text-center text-primary">
                  Cálculo Prima Final y Pago
                </h2>
                <b-row class="mt-3">
                  <b-col>
                    <b-list-group>
                      <b-list-group-item
                        class="d-flex justify-content-between align-items-center"
                      >
                        <span>Prima Neta:</span>
                        <span class="font-weight-bold"
                          >${{ finalCalculation.primaNeta }}</span
                        >
                      </b-list-group-item>
                      <b-list-group-item
                        class="d-flex justify-content-between align-items-center"
                      >
                        <span>IVA:</span>
                        <span class="font-weight-bold"
                          >${{ finalCalculation.IVA }}</span
                        >
                      </b-list-group-item>
                      <b-list-group-item
                        class="d-flex justify-content-between align-items-center bg-primary text-white"
                      >
                        <span><strong>Prima Total:</strong></span>
                        <span class="font-weight-bold"
                          >${{ finalCalculation.primaTotal }}</span
                        >
                      </b-list-group-item>
                    </b-list-group>
                  </b-col>
                </b-row>
              </b-card>
            </div>
          </template>

          <template #buttons>
            <p v-if="error" class="text-danger-emphasis mt-3">{{ error }}</p>
            <CaptchaGoogle
              @tokenCaptcha="handleTokenCaptcha"
              @onExpired="handleOnExpired"
            />
            <div class="d-flex">
              <CButton
                class="p-4 w-100 my-2 px-2"
                background="background-green"
                style="font-size: 1.5rem"
              >
                <template #content>
                  <div>
                    <BSpinner v-if="loading" />
                    <span v-else>Pagar el seguro</span>
                  </div>
                </template>
              </CButton>
            </div>
          </template>
        </Form>
      </template>
    </div>
  </div>
  <Footer />
  <WhatsappButton />
  <BModal
    :modelValue="isOpenModal"
    hide-footer
    static
    @hide="hideModal"
    size="lg"
    v-if="policyId"
  >
    <h4 class="text-center">Términos y Condiciones</h4>
    <ul>
      <li class="mb-4">
        Chubb es una subsidiaria de una compañía estadounidense. Como resultado,
        Chubb Seguros Chile S.A. está sujeto a ciertas leyes y regulaciones de
        los EE. UU., además de las restricciones de sanciones nacionales, de la
        UE y de la ONU, que pueden prohibirle proporcionar cobertura o pagar
        reclamaciones de siniestros a ciertas personas o entidades o asegurar
        ciertos tipos de actividades relacionadas con ciertos países como Irán,
        Siria, Corea del Norte, Región de Crimea y Cuba.
      </li>
      <li class="mb-4">
        El asegurado declara, acepta y autoriza expresamente a Chubb Seguros
        Chile S.A., y a las demás sociedades del grupo Chubb, para que puedan
        administrar para si y transferir los datos proporcionados en este
        documento a terceros que sean contratados o que estén vinculados o
        relacionados con Chubb Seguros Chile S.A., para que los datos obtenidos
        en la contratación de este seguro puedan ser utilizados en la
        administración, análisis, cumplimiento y/o o auditoría del mismo o para
        el otorgamiento de los servicios y coberturas señalados en la póliza, ya
        sea que esta transferencia se realice dentro de Chile o fuera del
        territorio nacional. Asimismo, otorgo autorización expresa a Chubb
        Seguros Chile S.A., para que de manera directa o a través de terceros
        contratados, pueda contactarme a través del email o teléfono que
        dispuse, con el objeto de proporcionarme información periódica en
        relación a ofertas y promociones de distintos tipos de seguros y
        coberturas que esta aseguradora ofrece al público, todo lo anterior
        conforme a la Ley 19.628, sobre Protección de Datos de Carácter
        Personal, y la Ley de Protección de los Derechos del Consumidor, o
        aquellas que las reemplacen en el futuro.
      </li>
      <li class="mb-4">
        El asegurado declara y acepta a Chubb Seguros Chile S.A. al envío de
        todo tipo de comunicación derivada o relacionada al seguro contratado,
        sea efectuada al correo electrónico consignado en este documento o a
        través de mensaje de texto al teléfono celular que dispuse. Solo en caso
        de que no cuente con ninguno de los medios señalados, las comunicaciones
        se realizarán a través de carta a la dirección informada en esta
        solicitud. Para efectos del envío de las comunicaciones a que se refiere
        la póliza, se entenderá vigente el último dato de contacto proporcionado
        por el asegurado a la compañía o al Contratante, aunque éste sea
        distinto al proporcionado al momento de la contratación, sin perjuicio
        de tener la oportunidad de confirmar de modo expreso tales datos, en
        forma previa a manifestar la aceptación.
      </li>
      <li class="mb-4">
        El Asegurado declara que la información proporcionada para efectos de la
        contratacion del seguro es correcta y fidedigna. Así mismo declara haber
        recibido y contar con la información completa y suficiente para la
        correcta contratación del Seguro.
      </li>
    </ul>
  </BModal>
</template>
