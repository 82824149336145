<script lang="ts" setup>
import { ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import storeAuth from '@store/auth';
import { User } from '../../types/user.type';

const router = useRouter();

const store = storeAuth();
const filterMenu = ref();

const menu: any = [
  {
    title: 'Compañías GPS',
    icon: '/icons/ajustes.svg',
    name: 'CompaniasGps',
  },
  // {
  //   title: 'Camiones',
  //   icon: '/icons/ajustes.svg',
  //   name: 'trucks',
  // },
  // {
  //   title: 'Trailers',
  //   icon: '/icons/ajustes.svg',
  //   name: 'trailers',
  // },
  // {
  //   title: 'Conductores',
  //   icon: '/icons/ajustes.svg',
  //   name: 'drivers',
  // },
  {
    title: 'Usuarios',
    icon: '/icons/ajustes.svg',
    name: 'Usuarios',
  },
  {
    title: 'Agentes',
    icon: '/icons/ajustes.svg',
    name: 'Agentes',
  },
  {
    title: 'Plantillas',
    icon: '/icons/ajustes.svg',
    name: 'Plantillas',
  },
  {
    title: 'Paises',
    icon: '/icons/ajustes.svg',
    name: 'Paises',
  },
];

watch(
  () => store.user as User,
  (newUser: User) => {
    const userPermissions = newUser?.permission;    

    if (!userPermissions) return;

    filterMenu.value = menu.filter((item: any) =>
      userPermissions.includes(item.name)
    );
  },
  {
    immediate: true,
  }
);

const handlerIsOpen = ref<boolean>(false);

const handleRedirect = (e: any) => {
  router.push({ name: e.name });
};
</script>
<template>
  <div
    :class="'d-flex flex-row'"
    :style="{
      padding: handlerIsOpen ? '0px 16rem 0px 0px' : '0px 3rem 0px 0px',
    }"
  >
    <router-view></router-view>
    <ActionBar
      :menu="filterMenu"
      :iconOpen="'/icons/flecha_left_primary.svg'"
      :iconClose="'/icons/flecha_right_primary.svg'"
      :colorTitle="'color-secondary'"
      :backgroundItem="'#ff436f'"
      @isOpen="handlerIsOpen = !handlerIsOpen"
      @navigate="handleRedirect"
    />
  </div>
</template>
