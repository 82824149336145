import { today } from '../../../helpers';

export const inputsStep1 = [
  {
    key: 'email',
    title: 'Email',
    type: 'email',
    validations: {
      rules: {
        required: true,
        email: true,
      },
    },

    placeholder: 'Email',
    value: null,
    cols: 4,
  },
  {
    key: 'phone',
    title: 'Telefono',
    type: 'text',
    validations: {
      rules: {
        required: true,
        max: 12,
        min: 8,
      },
    },

    placeholder: 'Telefono',
    value: null,
    cols: 4,
  },
  {
    key: 'asset',
    title: 'Tipo de mercancia transportada',
    type: 'select',
    validations: {
      rules: {
        required: true,
      },
    },
    selectLabel: null,
    placeholder: 'Elije el tipo',
    value: null,
    cols: 4,
    options: [],
  },
];

export const inputsStep2MX = [
  {
    key: 'rfc',
    title: 'RFC del cliente',
    type: 'text',
    validations: {
      rules: {
        required: true,
      },
    },
    placeholder: 'RFC del cliente',
    value: null,
    cols: 4,
    customClass: ' text-uppercase',
    hidden: false,
    formatValue: (e: string) => e?.toUpperCase() ?? '',
    errors: [],
  },
];

export const inputsStep2CL = [
  {
    key: 'rut',
    title: 'RUT del cliente',
    type: 'text',
    validations: {
      rules: {
        required: true,
      },
    },
    placeholder: 'RUT del cliente (sin guiones)',
    value: null,
    cols: 12,
    customClass: 'w-25 text-uppercase',
    hidden: false,
    errors: [],
  },
];

export const inputsStep2AR = [
  {
    key: 'cuit',
    title: 'CUIT del cliente',
    type: 'number',
    validations: {
      rules: {
        required: true,
      },
    },
    placeholder: 'CUIT del cliente (sin guiones)',
    value: null,
    cols: 4,
    customClass: 'text-uppercase',
    hidden: false,
    formatValue: (e: string) => e?.replace(/[^0-9]/g, '') ?? '',
    errors: [],
  },
];

export const inputsStep3MX = [
  {
    key: 'tradeName',
    title: 'Nombre del cliente',
    type: 'text',
    validations: {
      rules: {
        required: true,
      },
    },
    placeholder: 'Nombre del cliente',
    value: null,
    cols: 4,
    customClass: 'text-uppercase',
    formatValue: (e: string) => e?.toUpperCase() ?? '',
  },
  {
    key: 'email',
    title: 'Email del Cliente',
    type: 'email',
    validations: {
      rules: {
        required: true,
        email: true,
      },
    },
    placeholder: 'Email del Cliente',
    value: null,
    cols: 4,
  },
  {
    key: 'phone1',
    title: 'Telefono del Cliente',
    type: 'text',
    validations: {
      rules: {
        required: true,
        max: 12,
        min: 8,
      },
    },
    placeholder: 'Telefono del Cliente',
    value: null,
    cols: 4,
  },
  {
    key: 'companyGPSId',
    title: 'Empresa GPS',
    selectLabel: null,
    type: 'select',
    validations: {
      rules: {
        required: true,
      },
    },
    placeholder: 'Elije la empresa gps',
    value: null,
    cols: 4,
    options: [],
  },
  {
    key: 'address',
    title: 'Dirección del Cliente',
    type: 'text',
    validations: {
      rules: {
        required: true,
      },
    },
    placeholder: 'Dirección del Cliente',
    value: null,
    cols: 4,
  },
  {
    key: 'postCode',
    title: 'C.P.',
    type: 'text',
    validations: {
      rules: {
        required: true,
      },
    },
    placeholder: 'Codigo Postal',
    value: null,
    cols: 4,
    listenChange: true,
  },
  {
    key: 'colony',
    title: 'Colonia',
    selectLabel: null,
    type: 'select',
    validations: {
      rules: {
        required: true,
      },
    },
    placeholder: 'Elije la colonia',
    value: null,
    cols: 4,
    options: [],
  },
  {
    key: 'city',
    title: 'Ciudad',
    type: 'text',
    placeholder: 'Ciudad',
    value: null,
    cols: 4,
    disabled: true,
  },
  {
    key: 'state',
    title: 'Estado',
    type: 'text',
    placeholder: 'Estado',
    value: null,
    cols: 4,
    disabled: true,
  },
];

export const inputsStep3CL = [
  {
    key: 'tradeName',
    title: 'Nombre del cliente',
    type: 'text',
    validations: {
      rules: {
        required: true,
      },
    },
    placeholder: 'Nombre del cliente',
    value: null,
    cols: 4,
    formatValue: (e: string) => e?.toUpperCase() ?? '',
  },
  {
    key: 'email',
    title: 'Email del Cliente',
    type: 'email',
    validations: {
      rules: {
        required: true,
        email: true,
      },
    },
    placeholder: 'Email del Cliente',
    value: null,
    cols: 4,
  },
  {
    key: 'phone1',
    title: 'Teléfono del Cliente',
    type: 'text',
    placeholder: 'Teléfono del Cliente',
    value: null,
    cols: 4,
  },
  {
    key: 'companyGPSId',
    title: 'Empresa GPS',
    selectLabel: null,
    type: 'select',
    validations: {
      rules: {
        required: true,
      },
    },
    disabled: false,
    placeholder: 'Elije la empresa gps',
    value: null,
    cols: 4,
    options: [],
  },
  {
    key: 'address',
    title: 'Dirección del Cliente',
    type: 'text',
    validations: {
      rules: {
        required: true,
      },
    },
    placeholder: 'Dirección del Cliente',
    value: null,
    cols: 4,
  },
  {
    key: 'postCode',
    title: 'C.P.',
    type: 'text',
    validations: {
      rules: {
        required: true,
      },
    },
    placeholder: 'Codigo Postal',
    value: null,
    cols: 4,
  },
  {
    key: 'region',
    title: 'Región',
    selectLabel: null,
    type: 'select',
    validations: {
      rules: {
        required: true,
      },
    },
    placeholder: 'Elije una región',
    value: null,
    cols: 4,
    options: [],
    listenSelect: true,
  },
  {
    key: 'comuna',
    title: 'Comuna',
    type: 'select',
    validations: {
      rules: {
        required: true,
      },
    },
    placeholder: 'Elije una comuna',
    value: null,
    options: [],
    cols: 4,
  },
];

export const inputsStep3AR = [
  {
    key: 'tradeName',
    title: 'Nombre del cliente',
    type: 'text',
    validations: {
      rules: {
        required: true,
      },
    },
    placeholder: 'Nombre del cliente',
    value: null,
    cols: 4,
    formatValue: (e: string) => e?.toUpperCase() ?? '',
  },
  {
    key: 'email',
    title: 'Email del Cliente',
    type: 'email',
    validations: {
      rules: {
        required: true,
        email: true,
      },
    },
    placeholder: 'Email del Cliente',
    value: null,
    cols: 4,
  },
  {
    key: 'phone1',
    title: 'Teléfono del Cliente',
    type: 'text',
    placeholder: 'Teléfono del Cliente',
    value: null,
    cols: 4,
  },
  {
    key: 'companyGPSId',
    title: 'Empresa GPS',
    selectLabel: null,
    type: 'select',
    disabled: false,
    placeholder: 'Elije la empresa gps',
    value: null,
    cols: 4,
    options: [],
  },
  {
    key: 'address',
    title: 'Dirección del Cliente',
    type: 'text',
    validations: {
      rules: {
        required: true,
      },
    },
    placeholder: 'Dirección del Cliente',
    value: null,
    cols: 4,
  },
  {
    key: 'postCode',
    title: 'C.P.',
    type: 'text',
    validations: {
      rules: {
        required: true,
      },
    },
    placeholder: 'Codigo Postal',
    value: null,
    cols: 4,
  },
  {
    key: 'province',
    title: 'Provincia',
    selectLabel: null,
    type: 'select',
    validations: {
      rules: {
        required: true,
      },
    },
    placeholder: 'Elije una provincia',
    value: null,
    cols: 4,
    options: [],
    listenSelect: true,
  },
  {
    key: 'city',
    title: 'Localidad',
    type: 'select',
    validations: {
      rules: {
        required: true,
      },
    },
    placeholder: 'Elije una localidad',
    value: null,
    options: [],
    cols: 4,
  },
];

export const inputsStep4MX = [
  {
    key: 'assetId',
    title: 'Tipo de Mercancía',
    type: 'text',
    validations: {
      rules: {
        required: true,
      },
    },
    placeholder: 'Tipo de Mercancías',
    value: null,
    cols: 12,
    disabled: true,
  },
  {
    key: 'indemnificationLimit',
    title: 'Límite de indemnización',
    type: 'number',
    validations: {
      rules: {
        required: true,
      },
    },
    placeholder: 'Límite de indemnización',
    value: null,
    cols: 6,

    disabled: true,
  },
  {
    key: 'indemnificationLimit-calc',
    title: 'Precio',
    type: 'text',
    value: null,
    validations: {
      rules: {
        required: true,
      },
    },
    placeholder: 'Precio + IVA',
    disabled: true,
    cols: 6,
  },
  {
    key: 'sourceCityZipCodeId',
    title: 'Ciudad de origen',
    type: 'number',
    validations: {
      rules: {
        required: true,
      },
    },
    placeholder: 'Codigo postal',
    value: null,
    cols: 6,

    listenChange: true,
  },
  {
    key: 'sourceCityZipCodeIdCalc',
    title: 'Ciudad de origen',
    type: 'text',
    validations: {
      rules: {
        required: true,
      },
    },
    placeholder: 'Ciudad de Origen',
    value: null,
    cols: 6,

    disabled: true,
  },
  {
    key: 'destinationCityZipCodeId',
    title: 'Ciudad destino final',
    type: 'number',
    validations: {
      rules: {
        required: true,
      },
    },
    placeholder: 'Codigo postal',
    value: null,
    cols: 6,

    listenChange: true,
  },
  {
    key: 'destinationCityZipCodeIdCalc',
    title: 'Ciudad destino final',
    type: 'text',
    validations: {
      rules: {
        required: true,
      },
    },
    placeholder: 'Ciudad destino final',
    value: null,
    cols: 6,
    disabled: true,
  },
  {
    key: 'stops',
    title: 'Paradas entre Origen y Destino Final*',
    selectLabel: null,
    type: 'select',
    placeholder: 'Tiene paradas?',
    value: null,
    cols: 3,
    validations: {
      rules: {
        required: true,
      },
    },
    options: [
      { nombre: 'Si', id: 1 },
      { nombre: 'No', id: 0 },
    ],
    listenSelect: true,
  },
  {
    key: 'stop1',
    title: 'Parada 1',
    type: 'text',
    placeholder: 'Parada 1',
    value: null,
    cols: 3,
    disabled: true,
    validations: {
      rules: {
        required: false,
      },
    },
  },
  {
    key: 'stop2',
    title: 'Parada 2',
    type: 'text',
    placeholder: 'Parada 2',
    value: null,
    cols: 3,
    disabled: true,
  },
  {
    key: 'stop3',
    title: 'Parada 3',
    type: 'text',
    placeholder: 'Parada 3',
    value: null,
    cols: 3,
    disabled: true,
  },
  {
    key: 'startDate',
    title: 'Fecha de inicio del viaje',
    type: 'date',
    placeholder: 'Fecha de inicio',
    value: null,
    cols: 6,
    min: today(),
    validations: {
      rules: {
        required: true,
      },
    },
  },
  {
    key: 'startTime',
    title: 'Hora de inicio del viaje',
    type: 'time',
    placeholder: 'Hora de inicio',
    value: null,
    cols: 6,
    validations: {
      rules: {
        required: true,
      },
    },
    listenFocus: true,
  },
  {
    key: 'endDate',
    title: 'Fecha de fin del Viaje (aproximada)',
    type: 'date',
    placeholder: 'Fecha de fin',
    value: null,
    cols: 6,
    min: today(),
    validations: {
      rules: {
        required: true,
      },
    },
  },
  {
    key: 'endTime',
    title: 'Hora de fin del viaje',
    type: 'time',
    placeholder: 'Hora de fin',
    value: null,
    cols: 6,
    validations: {
      rules: {
        required: true,
      },
    },
  },
  {
    key: 'documentDescription',
    title: 'Documento del viaje',
    type: 'text',
    placeholder: 'Documento del Viaje',
    value: null,
    cols: 6,
    validations: {
      rules: {
        required: true,
      },
    },
  },
  {
    key: 'document',
    title: 'Adjuntar documento',
    type: 'file',
    placeholder: 'Adjuntar documento',
    value: null,
    cols: 6,
    hidden: false,
  },
  {
    key: 'truckPlate',
    title: 'Placa Tractor',
    type: 'text',
    placeholder: 'Placa Tractor',
    value: null,
    cols: 4,
    validations: {
      rules: {
        required: true,
      },
    },
  },
  {
    key: 'box-trailer',
    title: 'Caja de Carga',
    selectLabel: null,
    type: 'select',
    placeholder: 'Caja de Carga',
    value: null,
    cols: 4,
    options: [
      { nombre: 'Si', id: 1 },
      { nombre: 'No', id: 0 },
    ],
    validations: {
      rules: {
        required: true,
      },
    },
    hidden: false,

    listenSelect: true,
  },
  {
    key: 'trailerPlate',
    title: 'Placa caja',
    type: 'text',
    placeholder: 'Placa caja',
    value: null,
    cols: 4,

    validations: {
      rules: {
        required: false,
      },
    },
    disabled: true,
  },
  {
    key: 'driverName',
    title: 'Nombre del conductor',
    type: 'text',
    placeholder: 'Nombre del conductor',
    value: null,
    cols: 4,
    validations: {
      rules: {
        required: true,
      },
    },
  },
  {
    key: 'driverCellphone',
    title: 'Móvil del conductor',
    type: 'text',
    placeholder: 'Móvil del conductor',
    value: null,
    cols: 4,
    validations: {
      rules: {
        required: true,
        max: 12,
        min: 8,
      },
    },
  },
  {
    key: 'driverEmail',
    title: 'Email del conductor',
    type: 'email',
    placeholder: 'Email del conductor',
    value: null,
    cols: 4,
    validations: {
      rules: {
        email: true,
      },
    },
    hidden: false,
  },
];

export const inputsStep4AR = [
  {
    key: 'typeOfMerchandise',
    title: 'Tipo de Mercancía',
    type: 'text',
    validations: {
      rules: {
        required: true,
      },
    },
    placeholder: 'Tipo de Mercancías',
    value: null,
    cols: 12,
    disabled: true,
  },
  {
    key: 'indemnificationLimit',
    title: 'Límite de indemnización',
    type: 'number',
    validations: {
      rules: {
        required: true,
      },
    },
    placeholder: 'Límite de indemnización',
    value: null,
    cols: 6,
    disabled: true,
  },
  {
    key: 'indemnificationLimit-calc',
    title: 'Precio',
    type: 'text',
    value: null,
    validations: {
      rules: {
        required: true,
      },
    },
    placeholder: 'Precio + IVA',
    disabled: true,
    cols: 6,
  },
  {
    key: 'originProvince',
    title: 'Provincia de origen',
    type: 'select',
    validations: {
      rules: {
        required: true,
      },
    },
    options: [],
    placeholder: 'Elije la provincia de origen',
    value: null,
    cols: 6,
    listenSelect: true,
  },
  {
    key: 'originCity',
    title: 'Localidad de origen',
    type: 'select',
    validations: {
      rules: {
        required: true,
      },
    },
    placeholder: 'Elije la localidad de Origen',
    value: null,
    cols: 6,
    options: [],
    disabled: true,
  },
  {
    key: 'destinationProvince',
    title: 'Provincia destino final',
    type: 'select',
    validations: {
      rules: {
        required: true,
      },
    },
    placeholder: 'Elije la provincia de destino',
    value: null,
    options: [],
    cols: 6,
    listenSelect: true,
  },
  {
    key: 'destinationCity',
    title: 'Localidad destino final',
    type: 'select',
    validations: {
      rules: {
        required: true,
      },
    },
    placeholder: 'Elije la localidad de Destino',
    value: null,
    cols: 6,
    options: [],
    disabled: true,
  },
  {
    key: 'stops',
    title: 'Paradas entre Origen y Destino Final*',
    selectLabel: null,
    type: 'select',
    placeholder: 'Tiene paradas?',
    value: null,
    cols: 3,
    validations: {
      rules: {
        required: true,
      },
    },
    options: [
      { nombre: 'Si', id: 1 },
      { nombre: 'No', id: 0 },
    ],
    listenSelect: true,
  },
  {
    key: 'stop1',
    title: 'Parada 1',
    type: 'text',
    placeholder: 'Parada 1',
    value: null,
    cols: 3,
    disabled: true,
    validations: {
      rules: {
        required: false,
      },
    },
  },
  {
    key: 'stop2',
    title: 'Parada 2',
    type: 'text',
    placeholder: 'Parada 2',
    value: null,
    cols: 3,
    disabled: true,
  },
  {
    key: 'stop3',
    title: 'Parada 3',
    type: 'text',
    placeholder: 'Parada 3',
    value: null,
    cols: 3,
    disabled: true,
  },
  {
    key: 'startDate',
    title: 'Fecha de inicio del viaje',
    type: 'date',
    placeholder: 'Fecha de inicio',
    value: null,
    cols: 6,
    min: today(),
    validations: {
      rules: {
        required: true,
      },
    },
  },
  {
    key: 'startTime',
    title: 'Hora de inicio del viaje',
    type: 'time',
    placeholder: 'Hora de inicio',
    value: null,
    cols: 6,
    validations: {
      rules: {
        required: true,
      },
    },
    listenFocus: true,
  },
  {
    key: 'endDate',
    title: 'Fecha de fin del Viaje (aproximada)',
    type: 'date',
    placeholder: 'Fecha de fin',
    value: null,
    cols: 6,
    min: today(),
    validations: {
      rules: {
        required: true,
      },
    },
  },
  {
    key: 'endTime',
    title: 'Hora de fin del viaje',
    type: 'time',
    placeholder: 'Hora de fin',
    value: null,
    cols: 6,
    validations: {
      rules: {
        required: true,
      },
    },
  },
  {
    key: 'documentDescription',
    title: 'Documento del viaje',
    type: 'text',
    placeholder: 'Documento del Viaje',
    value: null,
    cols: 6,
    validations: {
      rules: {
        required: true,
      },
    },
  },
  {
    key: 'document',
    title: 'Adjuntar documento',
    type: 'file',
    placeholder: 'Adjuntar documento',
    value: null,
    cols: 6,
    hidden: false,
  },
  {
    key: 'truckPlate',
    title: 'Placa Tractor',
    type: 'text',
    placeholder: 'Placa Tractor',
    value: null,
    cols: 4,
    validations: {
      rules: {
        required: true,
      },
    },
  },
  {
    key: 'box-trailer',
    title: 'Caja de Carga',
    selectLabel: null,
    type: 'select',
    placeholder: 'Caja de Carga',
    value: null,
    cols: 4,
    options: [
      { nombre: 'Si', id: 1 },
      { nombre: 'No', id: 0 },
    ],
    validations: {
      rules: {
        required: true,
      },
    },
    hidden: false,
    listenSelect: true,
  },
  {
    key: 'trailerPlate',
    title: 'Placa caja',
    type: 'text',
    placeholder: 'Placa caja',
    value: null,
    cols: 4,
    validations: {
      rules: {
        required: false,
      },
    },
    disabled: true,
  },
  {
    key: 'driverName',
    title: 'Nombre del conductor',
    type: 'text',
    placeholder: 'Nombre del conductor',
    value: null,
    cols: 12,
    validations: {
      rules: {
        required: true,
      },
    },
  },
  {
    key: 'driverCellphone',
    title: 'Móvil del conductor',
    type: 'text',
    placeholder: 'Móvil del conductor',
    value: null,
    cols: 12,
    validations: {
      rules: {
        required: true,
        max: 12,
        min: 8,
      },
    },
  },
  {
    key: 'driverEmail',
    title: 'Email del conductor',
    type: 'email',
    placeholder: 'Email del conductor',
    value: null,
    cols: 12,
    validations: {
      rules: {
        email: true,
      },
    },
    hidden: false,
  },
];

export const inputsStep4CL = [
  {
    key: 'typeOfMerchandise',
    title: 'Tipo de Mercancía',
    type: 'text',
    validations: {
      rules: {
        required: true,
      },
    },
    placeholder: 'Tipo de Mercancías',
    value: null,
    cols: 12,
    disabled: true,
  },
  {
    key: 'indemnificationLimit',
    title: 'Límite de indemnización',
    type: 'number',
    validations: {
      rules: {
        required: true,
      },
    },
    placeholder: 'Límite de indemnización',
    value: null,
    cols: 6,
    disabled: true,
  },
  {
    key: 'indemnificationLimit-calc',
    title: 'Precio',
    type: 'text',
    value: null,
    validations: {
      rules: {
        required: true,
      },
    },
    placeholder: 'Precio + IVA',
    disabled: true,
    cols: 6,
  },
  {
    key: 'originRegion',
    title: 'Región de origen',
    type: 'select',
    validations: {
      rules: {
        required: true,
      },
    },
    options: null,
    placeholder: 'Elije la región de origen',
    value: null,
    cols: 6,
    listenSelect: true,
  },
  {
    key: 'originComuna',
    title: 'Comuna de origen',
    type: 'select',
    validations: {
      rules: {
        required: true,
      },
    },
    placeholder: 'Elije la comuna de Origen',
    value: null,
    cols: 6,
    options: [],
    disabled: true,
  },
  {
    key: 'destinationRegion',
    title: 'Región destino final',
    type: 'select',
    validations: {
      rules: {
        required: true,
      },
    },
    placeholder: 'Elije la región de destino',
    value: null,
    options: null,
    cols: 6,
    listenSelect: true,
  },
  {
    key: 'destinationComuna',
    title: 'Comuna destino final',
    type: 'select',
    validations: {
      rules: {
        required: true,
      },
    },
    placeholder: 'Elije la comuna de Destino',
    value: null,
    cols: 6,
    options: [],
    disabled: true,
  },
  {
    key: 'stops',
    title: 'Paradas entre Origen y Destino Final*',
    selectLabel: null,
    type: 'select',
    placeholder: 'Tiene paradas?',
    value: null,
    cols: 3,
    validations: {
      rules: {
        required: true,
      },
    },
    options: [
      { nombre: 'Si', id: 1 },
      { nombre: 'No', id: 0 },
    ],
    listenSelect: true,
  },
  {
    key: 'stop1',
    title: 'Parada 1',
    type: 'text',
    placeholder: 'Parada 1',
    value: null,
    cols: 3,
    disabled: true,
    validations: {
      rules: {
        required: false,
      },
    },
  },
  {
    key: 'stop2',
    title: 'Parada 2',
    type: 'text',
    placeholder: 'Parada 2',
    value: null,
    cols: 3,
    disabled: true,
  },
  {
    key: 'stop3',
    title: 'Parada 3',
    type: 'text',
    placeholder: 'Parada 3',
    value: null,
    cols: 3,
    disabled: true,
  },
  {
    key: 'startDate',
    title: 'Fecha de inicio del viaje',
    type: 'date',
    placeholder: 'Fecha de inicio',
    value: null,
    cols: 6,
    min: today(),
    validations: {
      rules: {
        required: true,
      },
    },
  },
  {
    key: 'startTime',
    title: 'Hora de inicio del viaje',
    type: 'time',
    placeholder: 'Hora de inicio',
    value: null,
    cols: 6,
    validations: {
      rules: {
        required: true,
      },
    },
    listenFocus: true,
  },
  {
    key: 'endDate',
    title: 'Fecha de fin del Viaje (aproximada)',
    type: 'date',
    placeholder: 'Fecha de fin',
    value: null,
    cols: 6,
    min: today(),
    validations: {
      rules: {
        required: true,
      },
    },
  },
  {
    key: 'endTime',
    title: 'Hora de fin del viaje',
    type: 'time',
    placeholder: 'Hora de fin',
    value: null,
    cols: 6,
    validations: {
      rules: {
        required: true,
      },
    },
  },
  {
    key: 'documentDescription',
    title: 'Documento del viaje',
    type: 'text',
    placeholder: 'Documento del Viaje',
    value: null,
    cols: 6,
    validations: {
      rules: {
        required: true,
      },
    },
  },
  {
    key: 'document',
    title: 'Adjuntar documento',
    type: 'file',
    placeholder: 'Adjuntar documento',
    value: null,
    cols: 6,
    hidden: false,
  },
  {
    key: 'truckPlate',
    title: 'Placa Tractor',
    type: 'text',
    placeholder: 'Placa Tractor',
    value: null,
    cols: 4,
    validations: {
      rules: {
        required: true,
      },
    },
  },
  {
    key: 'box-trailer',
    title: 'Caja de Carga',
    selectLabel: null,
    type: 'select',
    placeholder: 'Caja de Carga',
    value: null,
    cols: 4,
    options: [
      { nombre: 'Si', id: 1 },
      { nombre: 'No', id: 0 },
    ],
    validations: {
      rules: {
        required: true,
      },
    },
    hidden: false,

    listenSelect: true,
  },
  {
    key: 'trailerPlate',
    title: 'Placa caja',
    type: 'text',
    placeholder: 'Placa caja',
    value: null,
    cols: 4,

    validations: {
      rules: {
        required: false,
      },
    },
    disabled: true,
  },
  {
    key: 'driverName',
    title: 'Nombre del conductor',
    type: 'text',
    placeholder: 'Nombre del conductor',
    value: null,
    cols: 12,
    validations: {
      rules: {
        required: true,
      },
    },
  },
  {
    key: 'driverCellphone',
    title: 'Móvil del conductor',
    type: 'text',
    placeholder: 'Móvil del conductor',
    value: null,
    cols: 12,
    validations: {
      rules: {
        required: true,
        max: 12,
        min: 8,
      },
    },
  },
  {
    key: 'driverEmail',
    title: 'Email del conductor',
    type: 'email',
    placeholder: 'Email del conductor',
    value: null,
    cols: 12,
    validations: {
      rules: {
        email: true,
      },
    },
    hidden: false,
  },
];
