import axios from 'axios';

interface ApiResponse {
  Valor: string;
  Fecha: string;
}

interface UFResponse {
  UFs: ApiResponse[];
}

interface DolarUFResponse {
  Dolares: ApiResponse[];
  dolar?: {
    valor: number;
  };
  uf?: {
    valor: number;
  };
}

async function fetchData<T>(url: string): Promise<T | null> {
  try {
    const { data, status } = await axios.get<T>(url);
    return status === 200 ? data : null;
  } catch {
    return null;
  }
}

function getYesterdayDate(): { year: number; month: number; day: number } {
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  return {
    year: yesterday.getFullYear(),
    month: yesterday.getMonth() + 1,
    day: yesterday.getDate(),
  };
}

function parseValue(value: string): number {
  return parseFloat(value.replace('.', '').replace(',', '.'));
}

export async function getUF(): Promise<number | null> {
  const url =
    'https://api.cmfchile.cl/api-sbifv3/recursos_api/uf?apikey=7d79918cffcb8486263b98c2ee43450a6ad0dd7d&formato=json';
  const ufData = await fetchData<UFResponse>(url);

  if (ufData?.UFs.length) {
    return parseValue(ufData.UFs[0].Valor);
  }

  console.error('No se pudo obtener el valor de la UF');
  return null;
}

async function fetchDolar(url: string): Promise<{dolar: number, uf: number|null} | null> {
  const dolarData = await fetchData<DolarUFResponse>(url);

  if (dolarData?.dolar && dolarData?.uf) {
    return {
      dolar: dolarData.dolar.valor,
      uf: dolarData.uf.valor,
    };
  }

  if (dolarData?.Dolares && dolarData?.Dolares.length) {
    return {
      dolar: parseValue(dolarData.Dolares[0].Valor),
      uf: await getUF(),
    };
  }

  return null;
}

export async function getDolarChile(): Promise<{dolar: number, uf: number|null} | null> {
  const urlToday = 'https://mindicador.cl/api';
  const urlBackup =
    'https://api.cmfchile.cl/api-sbifv3/recursos_api/dolar?apikey=7d79918cffcb8486263b98c2ee43450a6ad0dd7d&formato=json';

  let dolarValue = await fetchDolar(urlToday);

  if (dolarValue !== null) {
    return dolarValue;
  }

  dolarValue = await fetchDolar(urlBackup);

  if (dolarValue !== null) {
    return dolarValue;
  }

  console.error(
    'No se pudo obtener el valor del dólar de hoy, intentando con el valor del día de ayer',
  );
  const { year, month, day } = getYesterdayDate();
  const urlYesterday = `https://api.cmfchile.cl/api-sbifv3/recursos_api/dolar/${year}/${month}/dias/${day}?apikey=7d79918cffcb8486263b98c2ee43450a6ad0dd7d&formato=json`;

  dolarValue = await fetchDolar(urlYesterday);

  if (dolarValue !== null) {
    return dolarValue;
  }

  console.error('No se pudo obtener el valor del dólar ni hoy ni ayer');
  return null;
}
