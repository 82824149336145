<script lang="ts" setup>
import { ref } from 'vue';
import './Login.scss';
import { useRouter } from 'vue-router';
import { inputsLogin } from '@services/forms/inputsLogin';
import autenticacionRepository from '@repositories/autenticacion.repository';

const router = useRouter();
const autenticacionController = new autenticacionRepository();

const refForm = ref<any>(null);
const errorMessages = ref<Array<string>>([]);
const loading = ref<boolean>(false);
const codeSent = ref<boolean>(false);
const email = ref<string>('');

const handleErrors = (error: any) => {
  if (error.errors) {
    error.errors.forEach((k: any) => {
      errorMessages.value.push(k.msg);
    });
  }
  return errorMessages.value.push(error.message);
};

const sendMail = async () => {
  const { items, isFormValid } = await refForm.value.getValues();
  if (isFormValid) {
    const response = await autenticacionController.sendCodeRecovery(items);
    if (response.msg == 'ok') {
      codeSent.value = true;
      email.value = items.email;
    }
  }
};
</script>

<template>
  <div
    :style="`background-image: url(img/bg_home.png)`"
    class="login-container"
  >
    <div
      class="container d-flex justify-content-center align-items-center h-100"
    >
      <div class="login-container__card p-4">
        <div
          class="d-flex flex-column justify-content-center login-container__firstBox mt-4"
        >
          <div class="text-center my-2 login-container__title">
            Enviar código por correo
          </div>
        </div>
        <div
          class="d-flex flex-column justify-content-center align-items-center login-container__form w-100"
        >
          <template v-if="errorMessages.length > 0">
            <div
              v-for="(item, index) in errorMessages"
              :key="index"
              class="alert alert-danger"
            >
              {{ item }}
            </div>
          </template>
          <div v-if="loading">
            <Overlay
              :show="loading"
              :circleColor="'#40a4c6'"
              :borderColor="'#40a4c6'"
            />
          </div>
          <template v-else-if="!codeSent">
            <div class="d-flex justify-content-center">
              <Form :inputs="[inputsLogin[0]]" ref="refForm" />
            </div>
            <CButton
              class="py-2 w-100 my-3"
              text="Enviar email"
              :background="`background-blue`"
              :disabled="loading"
              @click="sendMail"
              type="button"
            />
          </template>
          <template v-else>
            <p>
              Código enviado a <strong>{{ email }}</strong>
            </p>
            <p>Revisa tu casilla de entrada.</p>
            <CButton
              class="py-2 w-100 my-3"
              text="Volver al login"
              :background="`background-blue`"
              :disabled="loading"
              @click="router.push({ name: 'Login' })"
              type="button"
            />
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
