<script setup lang="ts">
import { ref, onMounted } from 'vue';
import moment from 'moment';
import NumerationPolicyRepository from '../../repositories/numerationPolicies.repository';

const numerationPolicyRepository = new NumerationPolicyRepository();
const numerationPoliciesData = ref([]);
const paginationData = ref();
const currentPage = ref<number>();

const getNumeration = async (page: number) => {
  const { data } = await numerationPolicyRepository.getNumerationPolicies({
    page,
  });
  numerationPoliciesData.value = data.numerationPolicies ?? [];
  paginationData.value = data.pagination;
};

onMounted(async () => {
  getNumeration(1);
});

const fields: any = ref([
  {
    key: 'assetID',
    title: 'Nueva Numeración',
    type: 'text',
    placeholder: 'Nueva Numeración',
    value: null,
    cols: 2,
    validations: {
      rules: {
        required: true,
      },
    },
  },
  {
    key: 'email1',
    title: 'Email 1',
    type: 'email',
    placeholder: 'Email 1',
    value: null,
    cols: 2,
  },
  {
    key: 'email2',
    title: 'Email 2',
    type: 'email',
    placeholder: 'Email 2',
    value: null,
    cols: 2,
  },
  {
    key: 'email3',
    title: 'Email 3',
    type: 'email',
    placeholder: 'Email 3',
    value: null,
    cols: 2,
  },

  {
    key: 'apiKeyId',
    title: 'Compañía',
    type: 'select',
    placeholder: 'Elije la compañia',
    value: null,
    selectLabel: null,
    cols: 2,
    options: [
      { nombre: 'Vink', id: 1 },
      { nombre: 'ControlMatch', id: 2 },
    ],
    validations: {
      rules: {
        required: true,
      },
    },
  },
]);

const fieldsTable: any = ref([
  {
    key: 'id',
    label: 'Id',
    sortable: true,
  },
  {
    key: 'assetID',
    label: 'Nueva numeración',
  },
  {
    key: 'createdAt',
    label: 'Fecha de inicio',
    formatter: (e: any) => moment(e).format('DD-MM-YYYY HH:mm'),
  },
  {
    key: 'endAt',
    label: 'Fecha de fin',
    formatter: (e: any) =>
      !e ? 'Sin fecha de fin' : moment(e).format('DD-MM-YYYY HH:mm'),
  },
  {
    key: 'email1',
    label: 'Email 1',
  },
  {
    key: 'email2',
    label: 'Email 2',
  },
  {
    key: 'email3',
    label: 'Email 3',
  },
  {
    key: 'apiKeyId',
    label: 'Compañia',
    formatter: (e: any) => (e === 1 ? 'Vink' : 'ControlMatch'),
  },
]);

const handleOnSubmit = async ({ items, isFormValid }: any) => {
  if (!isFormValid) return;

  const newNumerationPolicy = {
    ...items,
    apiKeyId: items.apiKeyId.id,
  };

  await numerationPolicyRepository.createNumerationPolicies(
    newNumerationPolicy
  );
  getNumeration(1);
};

const onChangePage = async (page: any) => {
  getNumeration(page);
  currentPage.value = page;
};
</script>

<template>
  <Layout title="Actualizar poliza Chubb" :textColor="'color-secondary'">
    <template #layout>
      <Form
        ref="refTableForm"
        :inputs="fields"
        @onSubmit="handleOnSubmit"
        customClass="poliza__form"
      >
        <template #buttons>
          <CButton class="background-blue button__form" style="padding: 0.55rem !important;">
            <template #content> Actualizar numeración </template>
          </CButton>
        </template>
      </Form>
      <Table
        :fields="fieldsTable"
        :items="numerationPoliciesData"
        @update:model-value-page="onChangePage"
        :totalRows="paginationData?.count"
        :perPage="20"
        :currentPage="paginationData?.current_page"
      >
      </Table>
    </template>
  </Layout>
</template>
