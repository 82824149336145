<script setup lang="ts">
import { toRefs, ref } from 'vue';
import { formatterPrice, formatDateAndHourSpanish } from '../../helpers';
import { getStatus } from '../../services/utils/Formatters';
import OperationsRepository from '@repositories/operations.repository';
import Store from '@store/dataProvider';
import { storeToRefs } from 'pinia';
import { IPolicy } from '../../types/policy.type';

const store = Store();
const { operations }: any = storeToRefs(store);

const operationsRepository = new OperationsRepository();

const emit = defineEmits<{
  (e: 'updateCurrentPolicyClient', value: IPolicy): void;
  (e: 'updateCurrentPolicy', value: IPolicy): void;
}>();

const props = defineProps({
  policyData: {
    type: Object as () => IPolicy,
    default: () => ({
      id: '',
      createdAt: '',
      manager: { email: '' },
      client: { tradeName: '', chubbPersonaId: '' },
      travelStartDate: '',
      travelEndDate: '',
      indemnificationLimit: 0,
      clientId: 0,
      sourceCityZipCode: { MunicipioNombre: '', code: '' },
      destinationCityZipCode: { MunicipioNombre: '', code: '' },
      stop1: undefined,
      stop2: undefined,
      stop3: undefined,
      companyGPS: { description: '' },
      documentDescription: '',
      asset: { description: '' },
      truckPlate: '',
      trailerPlate: '',
      driverName: '',
      driverCellphone: '',
      driverEmail: '',
      status: '',
    }),
  },
});

const statusInformation = ref<string | null>(null);
const loading = ref<boolean>(false);
const { policyData } = toRefs(props);

const handleCreateClientInChubb = async (id: number) => {
  let index = operations.value.findIndex(
    (value: any) => value.id === policyData.value.id
  );

  loading.value = true;
  statusInformation.value = 'Cargando cliente como persona en chubb';
  try {
    const { data } = await operationsRepository.createClientInChubb(id);

    emit('updateCurrentPolicyClient', data.data.client);
    store.setProp({
      key: 'operations',
      index,
      value: { ...operations.value[index], client: data.data.client },
    });

    loading.value = false;
    statusInformation.value = null;
  } catch (error) {
    statusInformation.value = 'Ocurrió un error';
    loading.value = false;
  }
};

const downloadDocument = async () => {
  const response = await operationsRepository.downloadClientDocument({
    clientId: policyData.value.clientId,
    policyId: policyData.value.id,
    documentPath: policyData.value.documentPath,
  });  

  if (!response) {
    statusInformation.value = 'Ocurrió un error al descargar la imagen';
  }
};

const handleQuotePolicy = async () => {
  let index = operations.value.findIndex(
    (value: any) => value.id === policyData.value.id
  );

  loading.value = true;
  statusInformation.value = 'Cotizando Póliza';
  try {
    const { data } = await operationsRepository.quotePolicy(
      policyData.value.id
    );
    emit('updateCurrentPolicy', data);
    store.setProp({
      key: 'operations',
      index,
      value: data,
    });
    loading.value = false;
    statusInformation.value = null;
  } catch (error) {
    statusInformation.value = 'Ocurrió un error';
    loading.value = false;
  }
};

const handleGetLinkPayment = async () => {
  try {
    statusInformation.value = 'Generando URL';
    const { data } = await operationsRepository.getPaymentURL(
      policyData.value.id
    );
    window.open(data, '_blank');
    statusInformation.value = null;
  } catch (error) {
    statusInformation.value = 'Ocurrió un error';
  }
};

const handleIssuePolicy = async () => {
  let index = operations.value.findIndex(
    (value: any) => value.id === policyData.value.id
  );

  loading.value = true;
  statusInformation.value = 'Emitiendo Póliza';
  try {
    const { data } = await operationsRepository.createIssuePolicy(
      policyData.value.id
    );

    emit('updateCurrentPolicy', data);
    store.setProp({
      key: 'operations',
      index,
      value: data,
    });
    loading.value = false;
    statusInformation.value = null;
  } catch (error) {
    statusInformation.value = 'Ocurrió un error';
    loading.value = false;
  }
};

const handleGetPDFPolicy = async () => {
  let index = operations.value.findIndex(
    (value: any) => value.id === policyData.value.id
  );

  loading.value = true;
  statusInformation.value = 'Generando PDF';

  try {
    await operationsRepository.getPDFPolicy(policyData.value.id);

    store.setProp({
      key: 'operations',
      index,
      value: { ...operations.value[index], pdfStatus: 1, status: 3 },
    });
    policyData.value.pdfStatus = 1;
    policyData.value.status = 3;
    loading.value = false;
    statusInformation.value = null;
  } catch (error) {
    statusInformation.value = 'Ocurrió un error, intentelo mas tarde';
    loading.value = false;
  }
};

const handleDownloadPDFPolicy = async () => {
  loading.value = true;
  statusInformation.value = 'Descargando PDF';

  try {
    await operationsRepository.downloadPDFPolicy({
      clientId: policyData.value.clientId,
      policyId: policyData.value.id,
      policyNumberId: policyData.value.polizaId,
    });

    loading.value = false;
    statusInformation.value = null;
  } catch (error) {
    statusInformation.value = 'Ocurrió un error, intentelo mas tarde';
    loading.value = false;
  }
};

const handleSolvedManually = async () => {
  let index = operations.value.findIndex(
    (value: any) => value.id === policyData.value.id
  );

  loading.value = true;
  statusInformation.value = 'Resolviendo manualmente';
  try {
    const { data } = await operationsRepository.solvedManually(
      policyData.value.id
    );

    emit('updateCurrentPolicy', data);
    store.setProp({
      key: 'operations',
      index,
      value: data,
    });
    loading.value = false;
    statusInformation.value = null;
  } catch (error) {
    statusInformation.value = 'Ocurrió un error';
    loading.value = false;
  }
};

const handleSendChubbpdf = async () => {
  let index = operations.value.findIndex(
    (value: any) => value.id === policyData.value.id
  );
  loading.value = true;
  statusInformation.value = 'Enviando e-mail al cliente';
  try {
    await operationsRepository.sendPDFtoClient(policyData.value.id);

    store.setProp({
      key: 'operations',
      index,
      value: { ...operations.value[index], status: 4 },
    });

    policyData.value.status = 4;

    loading.value = false;

    statusInformation.value = 'Póliza enviada';

    setTimeout(() => {
      statusInformation.value = null;
    }, 2000);
  } catch (error) {
    statusInformation.value = 'Ocurrió un error';
    loading.value = false;
  }
};
</script>

<template>
  <div>
    <p>
      <strong>Fecha de Creación:</strong>
      {{ formatDateAndHourSpanish(policyData.createdAt) }}
    </p>
    <p>
      <strong>Creada por:</strong>
      {{ policyData.manager.email }}
    </p>
    <p>
      <strong>Cliente:</strong>
      {{ policyData.client.tradeName }}
    </p>
    <p>
      <strong>Inicio del viaje:</strong>
      {{ formatDateAndHourSpanish(policyData.travelStartDate) }}
    </p>
    <p>
      <strong>Fin del viaje:</strong>
      {{ formatDateAndHourSpanish(policyData.travelEndDate) }}
    </p>
    <p>
      <strong>Suma asegurada:</strong>
      {{ formatterPrice(policyData.indemnificationLimit) }}
    </p>
    <p>
      <strong>Desde:</strong>
      {{ policyData.sourceCityZipCode?.MunicipioNombre }}
      <strong>CP:</strong> {{ policyData.sourceCityZipCode?.code }}
    </p>
    <p>
      <strong>Hasta:</strong>
      {{ policyData.destinationCityZipCode?.MunicipioNombre }}
      <strong>CP:</strong> {{ policyData.destinationCityZipCode?.code }}
    </p>
    <p v-if="policyData.stop1">
      <strong>Parada 1:</strong>
      {{ policyData.stop1 }}
    </p>
    <p v-if="policyData.stop2">
      <strong>Parada 2:</strong>
      {{ policyData.stop2 }}
    </p>
    <p v-if="policyData.stop3">
      <strong>Parada 3:</strong>
      {{ policyData.stop3 }}
    </p>
    <p>
      <strong>Compañia GPS:</strong>
      {{ policyData.companyGPS.description }}
    </p>
    <div class="d-flex">
      <p class="m-0 mx-2">
        <strong>Documento:</strong>
        {{ policyData.documentDescription }}
      </p>
      <CButton
        v-if="policyData.documentPath"
        class="background-blue button__form m-0"
        @click="downloadDocument"
      >
        <template #content> Descargar Documento </template>
      </CButton>
    </div>
    <p>
      <strong>Tipo de mercadería: </strong>
      {{ policyData.asset.description }}
    </p>
    <p>
      <strong>Camión: </strong>
      {{ policyData.truckPlate }}
    </p>
    <p>
      <strong>Caja: </strong>
      {{ policyData.trailerPlate }}
    </p>
    <p>
      <strong>Chofer: </strong>
      {{ policyData.driverName }}
    </p>
    <p>
      <strong>Teléfono Chofer: </strong>
      {{ policyData.driverCellphone }}
    </p>
    <p>
      <strong>Email Chofer: </strong>
      {{ policyData.driverEmail }}
    </p>
    <p v-if="policyData.versionId">
      <strong>Chubb cotización: </strong>
      {{ policyData.cotizacionId }}
      <strong>Version Id: </strong>
      {{ policyData.versionId }}
    </p>
    <div class="d-flex mb-2">
      <p class="m-0 mx-2">
        <strong>Estado cotización: </strong>
        {{ getStatus(policyData.status) }}
      </p>
      <CButton
        v-if="policyData.packageStatus === 1"
        class="background-blue button__form m-0"
        @click="handleGetLinkPayment"
      >
        <template #content> Link de pago </template>
      </CButton>
    </div>
    <p v-if="policyData.polizaId">
      <strong>Chubb poliza: </strong>
      {{ policyData.polizaId }}
    </p>
    <p v-if="policyData.claveId">
      <strong>Clave Id: </strong>
      {{ policyData.claveId }}
    </p>
    <p v-if="policyData.numeroConsecutivo">
      <strong>Clave Id: </strong>
      {{ policyData.numeroConsecutivo }}
    </p>
  </div>

  <template v-if="policyData.status !== 5">
    <p v-if="statusInformation">{{ statusInformation }}</p>
    <CButton
      class="background-blue button__form w-100"
      style="padding: 0.55rem !important"
      v-if="!policyData.client.chubbPersonaId"
      @click="handleCreateClientInChubb(policyData.clientId)"
      :loading="loading"
    >
      <template #content v-if="!loading"> Cargar cliente Chubb </template>
    </CButton>
    <CButton
      class="background-blue button__form w-100"
      style="padding: 0.55rem !important"
      v-if="policyData.status === 0"
      @click="handleQuotePolicy()"
      :loading="loading"
    >
      <template #content v-if="!loading"> Cotizar Póliza </template>
    </CButton>
    <CButton
      class="background-blue button__form w-100"
      style="padding: 0.55rem !important"
      v-if="policyData.status === 1"
      @click="handleIssuePolicy"
      :loading="loading"
    >
      <template #content v-if="!loading"> Emitir Póliza </template>
    </CButton>
    <template v-if="policyData.polizaId">
      <CButton
        class="background-blue button__form w-100"
        style="padding: 0.55rem !important"
        v-if="policyData.pdfStatus === 0"
        @click="handleGetPDFPolicy"
        :loading="loading"
      >
        <template #content v-if="!loading"> Generar PDF Póliza </template>
      </CButton>
      <CButton
        class="background-blue button__form w-100"
        style="padding: 0.55rem !important"
        v-if="policyData.pdfStatus === 1"
        @click="handleDownloadPDFPolicy"
      >
        <template #content> Descargar PDF Póliza </template>
      </CButton>
      <CButton
        class="background-blue button__form w-100"
        style="padding: 0.55rem !important"
        v-if="policyData.pdfStatus === 1 && policyData.status !== 4"
        @click="handleSendChubbpdf"
      >
        <template #content>Enviar PDF póliza a cliente</template>
      </CButton>
    </template>

    <CButton
      class="background-blue button__form w-100"
      style="padding: 0.55rem !important"
      @click="handleSolvedManually"
      v-if="policyData.status !== 4"
    >
      <template #content> Marcar como resuelta manualmente </template>
    </CButton>
  </template>
</template>

<style scoped>
p {
  margin: 10px;
}
</style>
