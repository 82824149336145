import { ITemplateUI, ITemplateUIResponse } from "../types/templatesUI.type";
import $http from "../services/AxiosInstance";

interface Params {
  page?: number,
}

class TemplatesUIDataProviderRepository {
  async getTemplates(params?: Params): Promise<ITemplateUIResponse> {
    try {
      let url = 'templatesUI';
      if (params) {
        url += `?page=${params.page}`;
      }

      const { data } = await $http.get(url);

      return data;
    } catch (error: any) {
      throw error;
    }
  }

  async createTemplate(template: ITemplateUI): Promise<ITemplateUIResponse> {
    const data: FormData = new FormData();
    data.append('name', template.name);
    data.append('urlDomain', template.urlDomain);
    data.append('primaryColour', template.primaryColour);
    data.append('imagePath', template.imagePath[0].image);

    const templateUI = await $http.post(`/templatesUI`, data);

    return templateUI.data
  }

  async updateTemplate(id: number, template: ITemplateUI): Promise<ITemplateUIResponse> {
    const data: FormData = new FormData();
    data.append('name', template.name);
    data.append('urlDomain', template.urlDomain);
    data.append('primaryColour', template.primaryColour);
    if (template.imagePath) {
      data.append('imagePath', template.imagePath[0].image);
    }

    const templateUI = await $http.patch(`/templatesUI/${id}`, data);

    return templateUI.data
  }

  async getNameTemplate(path: string): Promise<ITemplateUIResponse> {
    const templateUI = await $http.get(`/templatesUI/name/urlDomain?urlDomain=${path}`);
    return templateUI.data.name
  }

  async blockTemplate(id: number): Promise<ITemplateUIResponse> {
    const templateUI = await $http.delete(`/templatesUI/${id}`);
    return templateUI
  }

  async unBlockTemplate(id: number): Promise<ITemplateUIResponse> {
    const templateUI = await $http.patch(`/templatesUI/restore/${id}`);
    return templateUI
  }

}
export default TemplatesUIDataProviderRepository;
