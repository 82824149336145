import DataProvider from '../store/dataProvider';

//types
import { ModelDataProvider, TypeDataProvider } from "src/types/dataProvider.type";
import { ICompaniasGPSResponse } from "src/types/companiasGPS.type";
import { IAgentsResponse } from "src/types/agents.type";

// repositories
import DataProviderRepository from "@repositories/dataProvider.repository";
import AgentsDataProviderRepository from "@repositories/agents.repository";
import TemplatesUIDataProviderRepository from '@repositories/templatesUI.repository';

const dataProviderController = new DataProviderRepository()
const agentsProviderController = new AgentsDataProviderRepository()

export default function useDataProvider() {

  const data = DataProvider();

  interface IGetterObject {
    companiasGPS: () => Promise<ICompaniasGPSResponse>;
    agents: () => Promise<IAgentsResponse>;
  }
  const getDataProviders = async (models: Array<ModelDataProvider>) => {
    let getterObject = {
      companiasGPS: () => (dataProviderController.indexCompaniasGPS()),
      agents: () => (agentsProviderController.indexAgents()),
    }
    models.forEach(async (model: string) => {
      if (data[model as keyof TypeDataProvider]?.length === 0) {
        const getData = getterObject[model as keyof IGetterObject];
        const response = await getData()

        if (Array.isArray(response)) {
          data.setProp({ key: model, value: response })
        }
        if (response.errors) {
          alert('Ha ocurrido un error al traer los proveedores de data')
        }
      }
    })
  }

  return {
    getDataProviders
  }
}
