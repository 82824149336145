<script setup lang="ts">
import { onMounted, ref } from 'vue';

// types
import { IPermission, IRole } from '../../../types/user.type';

//composables
import { useMessage } from '../../../composables/useMessage';
import { useToast } from '../../../composables/useToast';

//repositories
import UserApi from '@repositories/user.repository';

const { $toast } = useToast();
const { messageHTML, showMessage } = useMessage();

const userApiRepository = new UserApi();

const emit = defineEmits<{
  (e: 'closeModal'): void;
}>();

const permissionData = ref<IPermission[]>([]);
const roleName = ref<string>('');
const roleDescription = ref<string>('');
const toogleViewRoles = ref<boolean>(true);
const RolesData = ref<IRole[]>([]);
const roleSelected = ref<IRole>();
const permissionsActives = ref<IPermission[]>([]);

onMounted(async () => {
  const { data } = await userApiRepository.getPermissions();
  if (Array.isArray(data)) {
    permissionData.value = data;
  }
});

onMounted(async () => {
  const data = await userApiRepository.getRoles();
  RolesData.value = data;
});

const handleCreateRol = async () => {
  if (!roleName.value || !roleDescription.value) {
    showMessage('Debes completar los campos', 'error');
    return;
  }

  const selectedPermissions = permissionData.value.map((permission: any) => ({
    ...permission,
    selected: permission.selected ?? false,
  }));

  if (!selectedPermissions.some((permission: any) => permission.selected)) {
    showMessage(
      'Debes seleccionar al menos un permiso para crear el rol.',
      'error',
    );
    return;
  }

  const newRole = {
    title: roleName.value,
    description: roleDescription.value,
    permissions: selectedPermissions,
  };
  try {
    await userApiRepository.createRole(newRole);
    $toast?.success(`Rol creado con exito`);
    emit('closeModal');
  } catch (error: any) {
    showMessage(error.message, 'error');
  }
};

const handleToggleButtonClick = () => {
  toogleViewRoles.value = !toogleViewRoles.value;
};

const handleSelectValue = async (rol: IRole) => {
  roleSelected.value = rol;

  const { data } = await userApiRepository.getPermissionsByRoleId(rol.id);

  const filterPermissionsActives = permissionData.value.map((item) => {
    const foundItem = data.find((d: IPermission) => d.id === item.id);
    return {
      ...item,
      selected: foundItem ? true : false,
    };
  });

  permissionsActives.value = filterPermissionsActives;
};

const handleUpdateRoles = async () => {
  const selectedPermissions = permissionsActives.value.filter(
    (permission: any) => permission.selected,
  );
  if (!roleSelected.value) return;
  try {
    await userApiRepository.updateRole(roleSelected.value.id, {
      permissions: selectedPermissions,
    });

    showMessage('Rol Actualizado con exito', 'info');
  } catch (error: any) {
    showMessage(error.message, 'error');
  }
};
</script>

<template>
  <div>
    <CButton
      class="background-pink button__form mt-3 w-100"
      style="padding: 0.5rem !important"
      type="button"
      @click="handleToggleButtonClick"
    >
      <template #content>{{
        toogleViewRoles ? 'Ir a actualizar rol' : 'Ir a crear rol'
      }}</template>
    </CButton>
  </div>
  <div v-if="toogleViewRoles">
    <form @submit.prevent="handleCreateRol">
      <div class="d-flex">
        <div class="w-100 mx-1">
          <label class="m-1 w-100">Nombre del rol</label>
          <Input placeholder="Nombre" class="mb-2" v-model="roleName" />
        </div>
        <div class="w-100 mx-1">
          <label class="m-1">Descripción</label>
          <Input
            placeholder="Descripción"
            class="mb-2"
            v-model="roleDescription"
          />
        </div>
      </div>
      <label class="m-1">Permisos</label>
      <div
        v-for="permission in permissionData"
        :key="permission.id"
        class="d-flex align-items-center"
      >
        <CheckBox
          :option="false"
          @change="permission.selected = !permission.selected"
        />
        <p class="m-1">{{ permission.descripcion }}</p>
      </div>
      <div v-if="messageHTML" v-html="messageHTML" />
      <CButton
        class="w-100 fs-6 p-2 mt-2"
        background="background-blue"
        style="padding: 0.5rem !important"
        type="submit"
      >
        <template #content>Crear rol </template>
      </CButton>
    </form>
  </div>
  <div v-if="!toogleViewRoles">
    <Select
      :options="RolesData"
      selectLabel=""
      @select="handleSelectValue($event)"
      :value="roleSelected"
      :listenSelect="true"
      placeholder="Seleccionar rol"
      class="my-2"
    />
    <form @submit.prevent="handleUpdateRoles">
      <div>
        <div
          v-for="permission in permissionsActives"
          :key="permission.id"
          class="d-flex align-items-center"
        >
          <CheckBox
            :option="false"
            @change="permission.selected = !permission.selected"
            :value="!permission.selected"
          />
          <p class="m-1">{{ permission.descripcion }}</p>
        </div>
        <div v-if="messageHTML" v-html="messageHTML" />
        <CButton class="w-100 fs-6 p-2 mt-2" background="background-blue">
          <template #content>
            <div>
              <span>Actualizar rol</span>
            </div>
          </template>
        </CButton>
      </div>
    </form>
  </div>
</template>
