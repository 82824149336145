import $http from '../services/AxiosInstance';

export interface IRegion {
  id: number;
  nombre: string;
  name: string;
}
interface IComuna {
  id: number;
  nombre: string;
  codigoSISComuna: number;
  region_id: number;
}

class GeoRefChileRepository {
  static async getRegiones(): Promise<IRegion[]> {
    try {
      let url = '/chileRegiones/regiones';

      const response = await $http.get(url);
      return response.data.map((region: IRegion) => ({
        id: region.id,
        nombre: region.name,
      }));
    } catch (errors: any) {
      throw Error;
    }
  }
  static async getComunas(regionId: number): Promise<IComuna[]> {
    try {
      let url = `/chileRegiones/comunas/${regionId}`;

      const response = await $http.get(url);
      return response.data.map((comuna: IComuna) => ({
        id: comuna.id,
        nombre: comuna.nombre,
      }));
    } catch (errors: any) {
      throw Error;
    }
  }
}

export default GeoRefChileRepository;
