import FileSaver from 'file-saver';

export const downloadPDFBase64 = (response: any, fileName: string) => {
    // Extrae los datos del archivo en formato Base64 del cuerpo de la respuesta
    const base64Data = response.data;
  
    // Decodifica el archivo Base64 a una cadena binaria
    const binaryData = atob(base64Data);
  
    // Convierte la cadena binaria a un arreglo de bytes
    const byteArray = new Uint8Array(binaryData.length);
    for (let i = 0; i < binaryData.length; i++) {
      byteArray[i] = binaryData.charCodeAt(i);
    }
  
    // Crea un Blob con los datos del archivo
    const blob = new Blob([byteArray], { type: 'application/pdf' });
  
    // Utiliza FileSaver para iniciar la descarga del archivo
    FileSaver.saveAs(blob, fileName);
  }
  