export const formatterPrice = (price: number) => {
    const numberStr = price.toString();
 
    const parts = numberStr.split(".");
    let integerPart = parts[0];
    let decimalPart = parts[1] || "";

    integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    
    const formattedNumber = decimalPart ? `$ ${integerPart},${decimalPart}` : `$ ${integerPart}`;
    
    return formattedNumber;
}