<script lang="ts" setup>
import './Home.scss';
import Steps from './components/Steps.vue'
import Form from './components/FormHome.vue'
import Footer from './components/Footer.vue'
import WhatsappButton from './components/WhatsappButton.vue';

const perksList = [
  'Sin pagos o contratos anuales',
  'Compra online hasta 90 minutos antes de tu viaje',
  'Seguridad y monitoreo activo durante todo el viaje',
  'Sin deducibles ni descuentos de ninguna clase',
  'Atención personalizada',
];
</script>

<template>
    <div class='landing'>
      <h1 class='landing__title'>Seguro de Mercancias por trayecto</h1>
      <h2 class='landing__subtitle'>¿Necesitas saber el costo de tu seguro por viaje?</h2>
      <h2 class='landing__subtitle'>CONOCE SU PRECIO EN MENOS DE UN MINUTO</h2>  
      <div class="landing__container">
        <div class="landing__form-container">
          <div class="landing__info-perks">
            <h3 class="landing__perks-title">DESCUBRE LOS BENEFICIOS</h3>
            <div v-for="perk in perksList" :key="perk" class="landing__perks-container">
              <img src="/img/check.png" class="landing__perks-image"/>
              <p class="landing__perks-text">{{ perk }}</p>
            </div>
            <h4 class="landing__perks-subtitle">PRECIO ÚNICO POR CADA VIAJE</h4>
          </div>
          <Form />
        </div>
        <Steps />
      </div>
      <Footer />
      <WhatsappButton />
    </div>
  </template>
