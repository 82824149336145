<script setup lang="ts">
import moment from 'moment';
import { ref, onMounted } from 'vue';

import LogsPoliciesApiRepository from '../../repositories/logsPoliciesApi.repository';
import { IPagination } from '../../types/pagination.type';

const logsPoliciesApiRepository = new LogsPoliciesApiRepository();
const logsPoliciesApiData = ref([]);
const paginationData = ref<IPagination>();
const inputSearch = ref<string>('');
const showModal = ref<boolean>(false);
const logPolicieApiDetail = ref<any>('');
const currentPage = ref<number>();
const searchFormRef = ref<any>(null);
const showParseJsonView = ref<any>(false);

const getLogsPoliciesApi = async (page: number, search: string) => {
  const data = await logsPoliciesApiRepository.getlogsPoliciesApi({
    page,
    search,
  });
  logsPoliciesApiData.value = data.logsPoliciesApi ?? [];
  paginationData.value = data.pagination;
};

onMounted(async () => {
  getLogsPoliciesApi(1, inputSearch.value);
});

const fields: any = ref([
  {
    key: 'search',
    title: 'Buscar',
    type: 'text',
    placeholder: 'Buscar Log',
    value: null,
    cols: 3,
  },
]);

const fieldsTable: any = ref([
  {
    key: 'id',
    label: 'Id',
    sortable: true,
  },
  {
    key: 'appName',
    label: 'Cliente',
  },
  {
    key: 'rfc',
    label: 'RFC',
  },
  {
    key: 'createdAt',
    label: 'Fecha de envio',
    formatter: (e: any) =>
      e ? moment(e).format('DD-MM-YYYY HH:mm') : 'Sin fecha',
  },
  {
    key: 'responseErrorApi',
    label: 'Estado',
    formatter: (e: any) => e ?? 'PDF Enviado',
  },
  {
    key: 'seeEmail',
    label: 'Ver',
  },
]);
const onChangePage = async (page: any) => {
  getLogsPoliciesApi(page, inputSearch.value);
  currentPage.value = page;
};

const handleOpenModal = (item: any) => {
  showModal.value = true;
  logPolicieApiDetail.value = item;
};
const handleCloseModal = () => {
  showModal.value = false;
  logPolicieApiDetail.value = '';
  showParseJsonView.value = false;
};
const handleOnSubmit = async ({ items }: any) => {
  inputSearch.value = items.search;
  getLogsPoliciesApi(1, items.search);
};
const handleReset = async () => {
  if (!searchFormRef.value) return;
  await searchFormRef.value.resetValues();
  inputSearch.value = '';
  getLogsPoliciesApi(1, inputSearch.value);
};
const handleShowParseJson = async () => {
	showParseJsonView.value = !showParseJsonView.value
};
</script>

<template>
  <Layout title="Logs Polizas Api" :textColor="'color-secondary'">
    <template #layout>
      <div class="form__logEmail">
        <Form
          ref="searchFormRef"
          @onSubmit="handleOnSubmit"
          :inputs="fields"
          customClass="poliza__form"
        >
          <template #buttons>
            <CButton
              class="background-blue button__form"
              style="padding: 0.6rem !important; margin: 0.6rem"
            >
              <template #content> Buscar </template>
            </CButton>
            <CButton
              class="background-blue button__form"
              style="padding: 0.6rem !important; margin: 0.6rem"
              type="button"
              @click="handleReset"
            >
              <template #content> Limpiar filtro </template>
            </CButton>
          </template>
        </Form>
      </div>
      <Table
        :fields="fieldsTable"
        :items="logsPoliciesApiData"
        @update:model-value-page="onChangePage"
        :totalRows="paginationData?.count"
        :perPage="20"
        :currentPage="paginationData?.current_page"
      >
        <template v-if="true" v-slot:cell(seeEmail)="logsData">
          <div>
            <button
              class="background-blue border-0 text-white rounded-4"
              @click="handleOpenModal(logsData.data.item)"
            >
              <img
                src="/icons/eye.svg"
                style="width: 30px; height: 30px; cursor: pointer"
              />
            </button>
          </div>
        </template>
      </Table>
      <BModal
        :modelValue="showModal"
        hide-footer
        :centered="true"
        @hide="handleCloseModal"
        title="Log poliza API"
        size="xl"
        static
      >
        <div class="modal-content">
          <div class="modal-body" style="overflow-y: auto">
            <CButton
              class="background-blue w-100"
              style="padding: 0.6rem !important; margin-bottom: 2rem;"
              type="button"
              @click="handleShowParseJson"
            >
              <template #content>{{showParseJsonView? 'Ver JSON' : 'Ver formateado' }}</template>
            </CButton>
			<div v-if="showParseJsonView" v-for="item in Object.entries(logPolicieApiDetail)">
			  {{ item[0] }}: <strong> {{ item[1] }}</strong>
			</div>
            <div v-else>
              {{ logPolicieApiDetail.requestApi }}
            </div>
          </div>
        </div>
      </BModal>
    </template>
  </Layout>
</template>

<style scoped>
.form__logEmail {
  width: 50%;
}
</style>
