import $http from '../services/AxiosInstance';

interface Params {
  page: number;
  search: string;
}

class LogsMailRepository {
  async getlogsMail(params: Params) {
    try {
      let url = `/logMail?page=${params.page}`;
      if (params.search) {
        url += `&search=${params.search}`;
      }
      const response = await $http.get(url);
      return response.data;
    } catch (errors: any) {
      throw Error;
    }
  }
  async reSendEmail(id: number) {
    try {
      const response = await $http.post('/logMail', { id });
      return response.data;
    } catch (errors: any) {
      throw Error;
    }
  }
}

export default LogsMailRepository;
