<script setup lang="ts">
import { ref, onMounted, onUnmounted } from 'vue';
import {
  inputsStep2AR,
  inputsStep3AR,
  inputsStep4AR,
} from './utils/inputsForm';
import { useToast } from '../../composables/useToast';
import { useMessage } from '../../composables/useMessage';
import { validationCUIT } from '../../helpers/validationCUIT';
import { IFinalCalculation } from '../../helpers/finalCalculationAR';
import { wrongDepartureDateTime } from '../../helpers';
import {
  findClientByTributaryCode,
  findCompaniesGPS,
  createOrUpdateClient,
  createPolicy,
  getPricing,
  createPolicyForTrygerLatam,
} from '../../repositories/landing.repository';
import GeoRefArgentinaRepository, {
  IProvince,
} from '../../repositories/geoRefArgentina.repository';

const { $toast } = useToast();
const { messageHTML, showMessage } = useMessage();

const props = defineProps({
  dataForm1: {
    type: Object,
    required: true,
  },
});

const fieldsStep2: any = ref(inputsStep2AR);
const fieldsStep3: any = ref(inputsStep3AR);
const fieldsStep4: any = ref(inputsStep4AR);
const inputsHidden = ref<boolean>(true);
const dataForm: any = ref({});
const refFormClientData = ref<any>(null);
const refFormTravelData = ref<any>(null);
const loading = ref<boolean>(false);
const provincesARG = ref<IProvince[]>([]);
const finalCalculation = ref<IFinalCalculation | null>(null);

const emit = defineEmits<{
  (e: 'firstView'): void;
}>();

onMounted(async () => {
  if (!props.dataForm1) {
    inputsHidden.value = true;
    emit('firstView');
  }
});

onMounted(async () => {
  const provinces = await GeoRefArgentinaRepository.getprovinces();
  provincesARG.value = provinces;
  const comapaniesGPS = await findCompaniesGPS();
  fieldsStep3.value = fieldsStep3.value.map((field: any) => {
    if (field.key === 'companyGPSId') {
      field.options = comapaniesGPS;
    }
    if (field.key === 'province') {
      field.options = provinces;
    }
    return field;
  });
  fieldsStep4.value = fieldsStep4.value.map((field: any) => {
    if (field.key === 'destinationProvince') {
      field.options = provinces;
    }
    if (field.key === 'originProvince') {
      field.options = provinces;
    }
    return field;
  });
});

onUnmounted(() => {
  fieldsStep3.value = inputsStep3AR.map((e) => (e.value = null));
  fieldsStep4.value = inputsStep4AR.map((e) => (e.value = null));
  inputsHidden.value = true;
  dataForm.value = {};
});

const handleOnSubmitCUIT = async (e: any) => {
  const isValidCUIT = validationCUIT(e.items.cuit);

  if (!e.isFormValid || !isValidCUIT) {
    return (fieldsStep2.value[0].errors = ['CUIT es invalido']);
  }
  //   loading.value = true;
  const { client } = await findClientByTributaryCode(e.items.cuit);

  if (client) {
    dataForm.value = { client };
  }

  if (client) {
    const updatesClientInputs = [
      { key: 'tradeName', value: client.tradeName },
      { key: 'email', value: props?.dataForm1?.email ?? null },
      { key: 'phone1', value: client.phone1 },
      {
        key: 'companyGPSId',
        value: {
          id: client.companyGPS?.id,
          nombre: client.companyGPS?.description,
        },
      },
      { key: 'address', value: client.address },
      { key: 'province', value: { nombre: client.regionProvince } },
      { key: 'city', value: { nombre: client.location } },
      { key: 'postCode', value: client.postCode },
    ];

    updateFieldsInArray(fieldsStep3.value, updatesClientInputs, null);
  }
  const updatesTravelInputs = [
    {
      key: 'typeOfMerchandise',
      value: props?.dataForm1?.asset?.nombre ?? null,
    },
    {
      key: 'indemnificationLimit',
      value: props?.dataForm1?.indemnificationLimit ?? null,
    },
    {
      key: 'indemnificationLimit-calc',
      value: `${props?.dataForm1?.endPrice} + IVA` ?? null,
    },
  ];

  updateFieldsInArray(fieldsStep4.value, updatesTravelInputs, null);
  inputsHidden.value = false;

  if (!client) {
    const updatesClientInputs = [
      { key: 'email', value: props?.dataForm1?.email ?? null },
    ];
    updateFieldsInArray(fieldsStep3.value, updatesClientInputs, null);

    dataForm.value = { client: { rfc: e.items.rfc } };
  }else{
	finalCalculation.value = await getPricing(
    props?.dataForm1?.indemnificationLimit,
    e.items.cuit,
    client.regionProvince!,
  );
  }
};

const handleGetCities = async () => {
  const { items } = await refFormClientData.value.getValues();
  const preservedValues = {
    tradeName: items.tradeName,
    email: items.email,
    phone1: items.phone1,
    companyGPSId: items.companyGPSId,
    address: items.address,
    province: items.province,
  };

  if (items.province.id !== 99) {
    const cities = await GeoRefArgentinaRepository.getCities(items.province.id);
    await refFormClientData.value.setValue({ key: 'city', value: null });
    updateFieldsInArray(
      fieldsStep3.value,
      [
        {
          key: 'city',
          type: 'select',
          options: cities,
          value: null,
          customClass: 'inputSelect',
        },
      ],
      preservedValues,
    );
  } else {
    await refFormClientData.value.setValue({ key: 'city', value: null });
    updateFieldsInArray(
      fieldsStep3.value,
      [
        {
          key: 'city',
          type: 'text',
          value: null,
          customClass: 'inputLanding',
        },
      ],
      preservedValues,
    );
  }

  finalCalculation.value = await getPricing(
    props?.dataForm1?.indemnificationLimit,
    dataForm.value.client.cuit,
    items.province.nombre,
  );
};

const handleSelectChange = async (e: any) => {
  const updateFields: {
    key: string;
    disabled?: boolean;
    'validations.rules.required'?: boolean;
    options?: any[];
  }[] = [];

  const updateCommonFields = (key: string, isEnabled: boolean) => {
    updateFields.push(
      { key, disabled: !isEnabled },
      { key, 'validations.rules.required': isEnabled },
    );
  };

  const handleCityUpdate = async (cityKey: string) => {
    const cities = await GeoRefArgentinaRepository.getCities(e.value.id);
    await refFormTravelData.value.setValue({ key: cityKey, value: null });

    const updateCityFields = [
      {
        key: cityKey,
        type: e.value.id !== 99 ? 'select' : 'text',
        options: e.value.id !== 99 ? cities : undefined,
        disabled: false,
      },
    ];
    updateFieldsInArray(fieldsStep4.value, updateCityFields, null);
  };

  switch (e.input.key) {
    case 'box-trailer':
      updateCommonFields('trailerPlate', !!e.value.id);
      break;

    case 'originProvince':
      await handleCityUpdate('originCity');
      return;

    case 'destinationProvince':
      await handleCityUpdate('destinationCity');
      return;

    default:
      ['stop1', 'stop2', 'stop3'].forEach((stop) =>
        updateCommonFields(stop, !!e.value.id),
      );
      break;
  }

  updateFieldsInArray(fieldsStep4.value, updateFields, null);
};
const updateFieldsInArray = (
  array: any,
  updates: any,
  preservedValues: any,
) => {
  for (const update of updates) {
    const field = array.find((field: any) => field.key === update.key);
    if (field) {
      if ('type' in update) {
        field.type = update.type;
      }
      if ('options' in update) {
        field.options = update.options;
      }
      if ('value' in update) {
        field.value = update.value;
      }
      if ('disabled' in update) {
        field.disabled = update.disabled;
      }
    }
  }

  if (preservedValues) {
    for (const key in preservedValues) {
      const field = array.find((field: any) => field.key === key);
      if (field) {
        field.value = preservedValues[key];
      }
    }
  }
};

const handleOnSubmitPolicies = async ({
  items: refFormTravelDataItems,
  isFormValid: refFormTravelDataValid,
}: any) => {
  const { items: refFormClientDataItems, isFormValid: refFormClientDataValid } =
    await refFormClientData.value.getValues();

  // Validación de campos requeridos
  if (!refFormClientDataValid)
    return showMessage('El formulario debe estar completo', 'error');
  if (!refFormTravelDataValid)
    return showMessage('El formulario debe estar completo', 'error');

  // Desestruccturacion de propiedades
  const {
    typeOfMerchandise,
    indemnificationLimit,
    originProvince,
    destinationProvince,
    originCity,
    destinationCity,
    stops,
    stop1,
    stop2,
    stop3,
    truckPlate,
    trailerPlate,
    driverName,
    documentDescription,
    driverCellphone,
    driverEmail,
    startDate,
    endDate,
    startTime,
    endTime,
    document,
  } = refFormTravelDataItems;

  const {
    address,
    companyGPSId,
    email,
    phone1,
    tradeName,
    city,
    province,
    postCode,
  } = refFormClientDataItems;

  // Validación de datos
  const isOkDepartureDateTime = wrongDepartureDateTime(startDate, startTime);

  if (isOkDepartureDateTime) {
    return showMessage(
      'La hora de salida no puede ser menor a dentro de 90 minutos.',
      'error',
    );
  }

  if (tradeName.split(' ').length < 2) {
    return showMessage(
      'Debe proporcionar al menos un nombre y un apellido',
      'error',
    );
  }

  // Formato de la data para el request
  const createOrUpdateClientData = {
    tradeName,
    address,
    email,
    phone1,
    cuit: dataForm.value.client.cuit,
    regionProvince: province.nombre,
    location: city.nombre ?? city,
    postCode: postCode,
    companyGPSId: companyGPSId.id,
    urlDomain: window.location.origin,
  };

  const travelStartDate = new Date(`${startDate}T${startTime}`).toISOString();
  const travelEndDate = new Date(`${endDate}T${endTime}`).toISOString();
  const assetId = props.dataForm1.asset.id;
  const parseProvince =
    province.nombre === 'Ciudad Autónoma de Buenos Aires'
      ? 'Buenos aires'
      : province.nombre;

  const createPolicyData: any = {
    tradeName,
    assetId,
    phone1,
    typeOfMerchandise,
    indemnificationLimit,
    originRegionProvince: originProvince.nombre,
    destinationRegionProvince: destinationProvince.nombre,
    originLocation: originCity.nombre,
    destinationLocation: destinationCity.nombre ?? destinationCity,
    stop1,
    stop2,
    stop3,
    trailerPlate,
    truckPlate,
    driverName,
    documentDescription,
    driverCellphone,
    driverEmail,
    travelStartDate,
    travelEndDate,
    hasStops: stops.nombre,
    companyGPSId: companyGPSId.id,
    document: document?.length ? document[0].image : null,
    clientName: tradeName,
    tributaryCode: dataForm.value.client.cuit,
    address,
    postalCode: postCode,
    location: city.nombre,
    regionProvince: parseProvince,
    email,
    endPrice: finalCalculation?.value?.primaTotal,
	isAuth: true,
  };
  try {
    loading.value = true;
    // Request: 1 - si el cliente no existe lo crea, sino lo actualiza, 2 - crea la poliza
    const client = await createOrUpdateClient(
      dataForm.value.client.id,
      createOrUpdateClientData,
      'AR',
    );

    const urlForPayment = await createPolicyForTrygerLatam(
      client.data.client.id,
      client.data.manager.id,
      createPolicyData,
    );
    loading.value = false;
    if (urlForPayment) {
      $toast?.success(`Operación registrada con exito`);
      emit('firstView');
    }
  } catch (error) {
    loading.value = false;
    showMessage('Ocurrió un Error.', 'error');
  }
};
</script>

<template>
  <Layout title="Nueva Contratación" :textColor="'color-secondary'">
    <template #layout>
      <Form
        :inputs="fieldsStep2"
        @onSubmit="handleOnSubmitCUIT"
        v-if="inputsHidden"
      >
        <template #buttons>
          <div class="d-flex">
            <CButton background="background-blue" style="font-size: 1rem">
              <template #content> Comprobar CUIT </template>
            </CButton>
          </div>
        </template>
      </Form>
      <template v-if="!inputsHidden">
        <h4 class="color-pink">
          Datos del cliente (CUIT: {{ dataForm?.client?.cuit ?? null }})
        </h4>
        <Form
          ref="refFormClientData"
          :inputs="fieldsStep3"
          @select="handleGetCities"
        ></Form>
        <h4 class="my-3 color-pink">Datos del viaje</h4>
        <Form
          ref="refFormTravelData"
          :inputs="fieldsStep4"
          @onSubmit="handleOnSubmitPolicies"
          @select="handleSelectChange"
        >
          <template #content>
            <h1>dsada</h1>
          </template>

          <template #buttons>
            <div class="my-2" v-if="messageHTML" v-html="messageHTML" />
            <div v-if="finalCalculation" class="container p-0 my-2">
              <b-card class="rounded-4">
                <h2 class="text-center text-primary">
                  Cálculo Prima Final y Pago
                </h2>
                <b-row class="mt-3">
                  <b-col>
                    <b-list-group>
                      <b-list-group-item
                        class="d-flex justify-content-between align-items-center bg-light"
                      >
                        <span><strong>Monto imponible:</strong></span>
                        <span class="font-weight-bold"
                          >${{ finalCalculation.montoImponible }}</span
                        >
                      </b-list-group-item>
                      <b-list-group-item
                        class="d-flex justify-content-between align-items-center"
                      >
                        <span
                          >Taxes: *se aplican a cada cobertura por
                          separado</span
                        >
                      </b-list-group-item>

                      <b-list-group-item
                        class="d-flex justify-content-between align-items-center"
                      >
                        <span>IVA:</span>
                        <span class="font-weight-bold"
                          >${{ finalCalculation.IVA }}</span
                        >
                      </b-list-group-item>

                      <b-list-group-item
                        class="d-flex justify-content-between align-items-center bg-primary text-white"
                      >
                        <span><strong>Prima Total:</strong></span>
                        <span class="font-weight-bold"
                          >${{ finalCalculation.primaTotal }}</span
                        >
                      </b-list-group-item>
                    </b-list-group>
                  </b-col>
                </b-row>
              </b-card>
            </div>
            <CButton
              class="p-4 w-100 px-2"
              background="background-blue"
              style="font-size: 1.5rem"
            >
              <template #content>
                <div>
                  <BSpinner v-if="loading" />
                  <span v-else>REGISTRAR PÓLIZA</span>
                </div>
              </template>
            </CButton>
          </template>
        </Form>
      </template>
    </template>
  </Layout>
</template>
