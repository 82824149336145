import UserApi from "@repositories/user.repository";

export function useAuth() {
  const handleAuthenticated = async () => {
    try {     
        const userRepository = new UserApi()
        const authUser = await userRepository.getAuth()
                
        return authUser;
      } catch (error) {       
        return null
      }
    }
    return { handleAuthenticated }
  }

