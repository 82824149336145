<script lang="ts" setup>
import { reactive, watch } from 'vue';
import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/antd.css';

const props = defineProps({
  countryInitial: {
    type: String,
    required: true,
  },
  dolarPrice: {
    type: Number,
    default: null,
  },
  UFvalue: {
    type: Number,
    default: null,
  },
});

function createSliderConfig(
  value: number,
  min: number,
  max: number,
  marks: Record<number, any>,
  interval: number,
) {
  return {
    value,
    options: {
      dotSize: 20,
      width: 'auto',
      height: 4,
      contained: false,
      direction: 'ltr',
      min,
      max,
      interval: interval,
      disabled: false,
      clickable: false,
      duration: 0.5,
      adsorb: false,
      lazy: false,
      tooltip: 'always',
      tooltipPlacement: 'top',
      useKeyboard: false,
      enableCross: true,
      fixed: false,
      order: true,
      marks,
      process: true,
    },
  };
}

// Mascara slider chile

const chValue = Math.round((1000 * props.dolarPrice) / props.UFvalue);
const chMin = Math.round((1000 * props.dolarPrice) / props.UFvalue);
const chMax = Math.round((100000 * props.dolarPrice) / props.UFvalue);

const marksCH = {
  [chMin]: {
    label: '$ ' + chMin.toLocaleString(),
    labelStyle: {
      color: '#000',
      fontSize: '1.2rem',
      marginTop: '20px',
    },
  },
  [chMax]: {
    label: '$ ' + chMax.toLocaleString(),
    labelStyle: {
      color: '#000',
      fontSize: '1.2rem',
      marginTop: '20px',
    },
  },
};

// Mascara slider mexico

const marksMX = {
  200000: {
    label: '$ 200.000',
    labelStyle: {
      color: '#000',
      fontSize: '1.2rem',
      marginTop: '20px',
    },
  },
  2500000: {
    label: '$ 2.500.000',
    labelStyle: {
      color: '#000',
      fontSize: '1.2rem',
      marginTop: '20px',
    },
  },
};

// Mascara slider Argentina

const arValue = Math.round(props.dolarPrice * 1000);
const arMin = Math.round(props.dolarPrice * 1000);
const arMax = Math.round(props.dolarPrice * 250000);

const marksAR = {
  [arMin]: {
    label: '$ ' + arMin.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.'),
    labelStyle: {
      color: '#000',
      fontSize: '1.2rem',
      marginTop: '20px',
    },
  },
  [arMax]: {
    label: '$ ' + arMax.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.'),
    labelStyle: {
      color: '#000',
      fontSize: '1.2rem',
      marginTop: '20px',
    },
  },
};

const getSliderConfig = (countryInitial: string) => {
  switch (countryInitial) {
    case 'CHILE':
      return createSliderConfig(chValue, chMin, chMax, marksCH, 1);
    case 'MEXICO':
      return createSliderConfig(200000, 200000, 2500000, marksMX, 10000);
    default:
      return createSliderConfig(arValue, arMin, arMax, marksAR, 1);
  }
};

const sliderData = reactive(getSliderConfig(props.countryInitial));

watch(
  () => props.countryInitial,
  (newCountryInitial) => {
    const newSliderConfig = getSliderConfig(newCountryInitial);
    Object.assign(sliderData, newSliderConfig);
  },
);
</script>

<!-- :marks="sliderData.options.marks" -->
<template>
  <vue-slider
    @dragging="(val: number) => $emit('value', val)"
    v-model="sliderData.value"
    v-bind="sliderData.options"
    :tooltip-formatter="
      (val: number) => '$ ' + val.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    "
  />
</template>
