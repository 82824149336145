import moment from 'moment';

export const today = () => {
  return moment().format('YYYY-MM-DD')
}

export const formatDateAndHourSpanish = (date: string) => {
  return moment(date).format('DD-MM-YYYY HH:mm')

}  
