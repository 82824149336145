export function validationRut(rut: string): boolean {
  // Eliminar puntos y guión
  rut = rut.replace(/[.-]/g, '');

  // Validar formato
  if (!/^\d{7,8}[0-9Kk]$/.test(rut)) {
    return false;
  }

  // Separar cuerpo y dígito verificador
  const cuerpo: string = rut.slice(0, -1);
  let dv: string = rut.slice(-1).toUpperCase();

  // Calcular dígito verificador
  let suma: number = 0;
  let multiplo: number = 2;

  for (let i: number = cuerpo.length - 1; i >= 0; i--) {
    suma += multiplo * parseInt(cuerpo.charAt(i));
    multiplo = multiplo < 7 ? multiplo + 1 : 2;
  }

  const dvEsperado: number = 11 - (suma % 11);
  dv = dv === 'K' ? '10' : dv === '0' ? '11' : dv;

  // Comparar el dígito verificador calculado con el proporcionado
  return dv === dvEsperado.toString();
}
