export const fieldsAgents = [
  {
    key: 'id',
    label: 'ID Agente',
    sortable: true,
  },
  {
    key: 'name',
    label: 'Nombre',
    sortable: true,
  },
  {
    key: 'email',
    label: 'Email',
    sortable: true,
  },
  {
    key: 'rfc',
    label: 'Código RFC',
  },
  {
    key: 'edit',
    label: 'Editar',
    thClass: 'text-center'
  },
  {
    key: 'delete',
    label: 'Eliminar',
    thClass: 'text-center'
  }
]