<script lang="ts" setup>
import { watch, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import './App.scss';
import storeAuth from '@store/auth';
import { User } from './types/user.type';

const URLBACKEND = import.meta.env.VITE_APP_API;

const store = storeAuth();

const route = useRoute();
const router = useRouter();

const filterMenu = ref();
const menu = {
  top: [
    {
      title: 'Nueva Operacion',
      image: '/icons/new-operation.svg',
      visible: true,
      path: '/panel/nueva-operacion',
    },
    {
      title: 'Operaciones',
      image: '/icons/operations.svg',
      visible: true,
      path: '/panel/operaciones',
    },
    {
      title: 'Listar Polizas',
      image: '/icons/lista.svg',
      path: '/panel/polizas',
      visible: true,
    },
    {
      title: 'Ajustes',
      image: '/icons/ajustes.svg',
      visible: true,
      path: '/panel/ajustes/companias-gps',
    },
    {
      title: 'Actualizar poliza Chubb',
      image: '/icons/update.svg',
      path: '/panel/numeracion-polizas',
      visible: true,
    },
    {
      title: 'Seguimiento web',
      image: '/icons/tracking.svg',
      path: '/panel/seguimiento-web',
      visible: true,
    },
    {
      title: 'Logs Emails',
      image: '/icons/email-search.svg',
      path: '/panel/log-emails',
      visible: true,
    },
    {
      title: 'Logs Polizas API',
      image: '/icons/document-search.svg',
      path: '/panel/log-policies-api',
      visible: true,
    },
  ],
  above: [
    // {
    //     title: 'Perfil',
    //     image: '/icons/perfil.svg',
    //     visible : true,
    //     name: { name: 'Perfil'}
    // },
    {
      title: 'NOC logs',
      image: '/icons/error.svg',
      visible: true,
      name: { name: 'Noc' },
    },
    {
      title: 'Cerrar sesión',
      image: '/icons/logout.svg',
      visible: true,
      name: { name: 'Logout' },
    },
  ],
};

watch(
  () => store.user as User,
  (newUser: User) => {
    const userPermissions = newUser?.permission;

    if (!userPermissions) return;

    const filteredTopMenu = menu.top.filter((item) =>
      userPermissions.includes(item.title),
    );

    const filterAboveMenu = menu.above.filter((item) => {
      if (!newUser.isDev) {
        return item.title !== 'NOC logs';
      }
      return true;
    });

    filterMenu.value = {
      top: filteredTopMenu,
      above: filterAboveMenu,
    };
  },
  {
    immediate: true,
  },
);

const hideLayouts = [
  'Login',
  'Logout',
  'Home',
  'Contratacion',
  'Recover password',
  'Send mail recovery',
];

const handleNavigation = (data: any) => {
  if (data.path) {
    return router.push({ path: data.path });
  }
  if (data.name) {
    router.push(data.name);
  }
};
</script>

<template>
  <div class="AppRoot-fullPage">
    <NavBar
      v-if="!hideLayouts.includes(route.name as string) && store.user"
      :logo="
        store.user.template && !store.user.template.deletedAt
          ? `${URLBACKEND}/${store.user.template.imagePath}`
          : '/img/tryger-logo.png'
      "
      title=""
      :className="'NavBar__tryger'"
      :background="
        store.user.template && !store.user.template.deletedAt
          ? store.user.template.primaryColour
          : '#505050'
      "
    />
    <div class="AppMain">
      <div class="AppMain__layer">
        <SideBar
          v-if="!hideLayouts.includes(route.name as string) && store.user"
          :menu="filterMenu"
          @navigate="handleNavigation"
          :background="
            store.user.template && !store.user.template.deletedAt
              ? store.user.template.primaryColour
              : '#505050'
          "
        />
        <main class="AppMain__layer__PageAndTopBar">
          <div class="HomePageContent">
            <div class="Scrollable--vertical HomePageContent__contentContainer">
              <router-view></router-view>
            </div>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>

<style>
.Sidebar__Top {
  max-height: 90% !important;
}
</style>
