<script setup lang="ts">
import { ref, onMounted, computed } from 'vue';
import CountriesRepository from '../../repositories/countries.repository';
import Store from '@store/dataProvider';
import { storeToRefs } from 'pinia';
import { IPagination } from '../../types/pagination.type';
import { ICountry } from '../../types/countries.type';
import { useToast } from '../../composables/useToast';

const store = Store();
const { $toast } = useToast();
const { countries: countriesPinia }: any = storeToRefs(store);
const countries = ref<ICountry[]>([]);
const currentPage = ref<number>();
const paginationData = ref<IPagination>();
const modalType = ref<string | null>(null);
const modalTitle = ref<string | undefined>();
const isModalOpen = ref<boolean>(false);
const selectedCountry = ref<ICountry | null>(null);
const isViewActive = ref<boolean>(true);
const fieldsTableInactive: any = ref([
  {
    key: 'id',
    label: 'Id',
    sortable: true,
  },
  {
    key: 'description',
    label: 'Pais',
  },
  {
    key: 'urlDomain',
    label: 'Url de dominio',
  },
  {
    key: 'restore',
    label: 'Restaurar',
  },
]);
const fieldsTableActive: any = ref([
  {
    key: 'id',
    label: 'Id',
    sortable: true,
  },
  {
    key: 'description',
    label: 'Pais',
  },
  {
    key: 'urlDomain',
    label: 'Url de dominio',
  },
  {
    key: 'countryInfo',
    label: 'Editar',
  },
  {
    key: 'delete',
    label: 'Eliminar',
  },
]);

// Load Countries ABM
const getCountriesFromAPI = async (page: number, deleted: boolean = false) => {
  try {
    const { data } = await CountriesRepository.getcountries({ page, deleted });
    if (data && Array.isArray(data?.countries)) {
      paginationData.value = data.pagination;
      return data.countries;
    }
    return [];
  } catch (error) {
    console.error('Error while fetching countries:', error);
    return [];
  }
};

const updateCountries = (newCountries: ICountry[]) => {
  countries.value = newCountries;
  store.setProp({ key: 'countries', value: newCountries });
};

const loadCountries = async () => {
  const newCountries = await getCountriesFromAPI(1);
  updateCountries(newCountries);
};

onMounted(loadCountries);

// Create inputs from modals
const fieldsInputCountry = computed(() => {
  return [
    {
      key: 'description',
      title: 'Nombre del país',
      type: 'text',
      validations: {
        rules: {
          required: true,
        },
      },
      placeholder: 'Nuevo país',
      value: selectedCountry.value?.description,
      cols: 12,
    },
    {
      key: 'urlDomain',
      title: 'Url de dominio',
      type: 'text',
      validations: {
        rules: {
          required: true,
          verifyExpreRegular: /^(ftp|http|https):\/\/[^ "]+(?<!\/)$/,
        },
      },
      placeholder: 'https://example.com',
      value: selectedCountry.value?.urlDomain,
      cols: 12,
    },
  ];
});

// UI
const onChangePage = async (page: number) => {
  getCountriesFromAPI(page);
  currentPage.value = page;
};

const handleOpenModalCreate = () => {
  modalTitle.value = 'Agregar país';
  isModalOpen.value = true;
  modalType.value = 'modalCreateCountry';
};

const handleClose = () => {
  modalTitle.value = undefined;
  isModalOpen.value = false;
  modalType.value = null;
  selectedCountry.value = null;
};

const handleActive = async () => {
  const countries = await getCountriesFromAPI(1);
  updateCountries(countries);
  isViewActive.value = true;
};

const handleInactive = async () => {
  const countriesDeleted = await getCountriesFromAPI(1, true);
  updateCountries(countriesDeleted);
  isViewActive.value = false;
};

const handleOpenModalDialog = (item: ICountry) => {
  modalTitle.value = isViewActive.value
    ? `Borrar el Pais con id: ${item.id}`
    : `Restaurar el Pais con id: ${item.id}`;
  modalType.value = 'modalDialog';
  selectedCountry.value = item;
  isModalOpen.value = true;
};

//CRUD
const handleCreateCountry = async ({ items, isFormValid }: any) => {
  if (!isFormValid) return;
  try {
    const data = await CountriesRepository.createCountry(items);
    store.setProp({ key: 'countries', value: [...countries.value, data] });
    $toast?.success(`País agregado con exito`);
  } catch (error) {
    $toast?.error('Ocurrió un error al intentar crear');
  }

  handleClose();
};

const handleSelectCountry = (countryData: ICountry) => {
  modalTitle.value = 'Actualizar template';
  (isModalOpen.value = true), (modalType.value = 'modalUpdateCountry');
  selectedCountry.value = countryData;
};

const handleUpdateCountry = async ({ items, isFormValid }: any) => {
  if (!isFormValid || !selectedCountry.value) return;
  const countryForUpdate = {
    ...items,
    id: selectedCountry.value.id,
  };

  try {
    const data = await CountriesRepository.updateCountry(countryForUpdate);
    let index = store.countries.findIndex((value: any) => value.id === data.id);
    if (index > -1) {
      store.setProp({ key: 'countries', index, value: data });
    }
    $toast?.success(`País actualizado con exito`);
  } catch (error) {
    $toast?.error('Ocurrió un error al intentar actualizar');
  }

  handleClose();
};

const handleDeleteRestoreCountry = async () => {
  if (selectedCountry?.value?.id) {
    let response = null;
    try {
      if (isViewActive.value) {
        response = await CountriesRepository.deleteCountry(
          selectedCountry.value.id,
        );
      } else {
        response = await CountriesRepository.restoreCountry(
          selectedCountry.value.id,
        );
      }

      if (response === 200) {
        store.deleteItem({
          key: 'countries',
          value: selectedCountry?.value?.id,
        });
      }
    } catch (error) {
      if (isViewActive.value) {
        return $toast?.error('Error al intentar eliminar');
      }
      $toast?.error('Error al intentar restaurar');
    }

    handleClose();
  }
};
</script>

<template>
  <Layout title="Paises" :textColor="'color-secondary'">
    <template #layout>
      <div class="d-flex justify-content-between">
        <div>
          <CButton
            class="background-blue button__form"
            style="padding: 0.5rem !important; margin-right: 15px"
            type="button"
            @click="handleActive"
          >
            <template #content>Activos</template>
          </CButton>
          <CButton
            class="background-pink button__form"
            style="padding: 0.5rem !important"
            type="button"
            @click="handleInactive"
          >
            <template #content>Inactivos</template>
          </CButton>
        </div>
        <CButton
          class="background-pink button__form"
          style="padding: 0.5rem !important"
          type="button"
          @click="handleOpenModalCreate"
        >
          <template #content> Agregar país </template>
        </CButton>
      </div>

      <Table
        :fields="isViewActive ? fieldsTableActive : fieldsTableInactive"
        :items="countriesPinia"
        @update:model-value-page="onChangePage"
        :totalRows="paginationData?.count"
        :perPage="20"
        :currentPage="paginationData?.current_page"
      >
        <template v-slot:cell(countryInfo)="countryData">
          <div
            @click="handleSelectCountry(countryData.data.item)"
            class="button-icon"
          >
            <img src="/icons/editar.svg" />
          </div>
        </template>
        <template v-slot:cell(delete)="operationsData">
          <div
            @click="handleOpenModalDialog(operationsData.data.item)"
            class="button-icon"
          >
            <img src="/icons/basura.svg" alt="eliminar" />
          </div>
        </template>
        <template v-slot:cell(restore)="operationsData">
          <div
            @click="handleOpenModalDialog(operationsData.data.item)"
            class="button-icon restore"
          >
            <img src="/icons/restaurar.svg" alt="restore" />
          </div>
        </template>
      </Table>

      <BModal
        :modelValue="isModalOpen"
        hide-footer
        :centered="true"
        @hide="handleClose"
        static
        :title="modalTitle"
      >
        <template v-if="modalType === 'modalCreateCountry'">
          <Form
            ref="refTableForm"
            :inputs="fieldsInputCountry"
            @onSubmit="handleCreateCountry"
          >
            <template #buttons>
              <div class="d-flex">
                <CButton class="w-100 fs-6 p-2" background="background-blue">
                  <template #content>
                    <div>
                      <span>Agregar país</span>
                    </div>
                  </template>
                </CButton>
              </div>
            </template>
          </Form>
        </template>

        <template v-if="modalType === 'modalUpdateCountry'">
          <Form
            ref="refTableForm"
            :inputs="fieldsInputCountry"
            @onSubmit="handleUpdateCountry"
          >
            <template #buttons>
              <div class="d-flex">
                <CButton class="w-100 fs-6 p-2" background="background-blue">
                  <template #content>
                    <div>
                      <span>Actualizar</span>
                    </div>
                  </template>
                </CButton>
              </div>
            </template>
          </Form>
        </template>

        <template v-if="modalType === 'modalDialog'">
          <h5 class="text-center">
            {{
              isViewActive
                ? `¿Está seguro que desea borrar el país ${selectedCountry?.description}?`
                : `¿Está seguro que desea restaurar el país ${selectedCountry?.description}?`
            }}
          </h5>
          <div class="d-flex mt-3">
            <CButton
              class="w-100 fs-6 p-2"
              background="background-pink"
              @click="handleDeleteRestoreCountry"
            >
              <template #content>
                <div>
                  <span>Confirmar</span>
                </div>
              </template>
            </CButton>
          </div>
        </template>
      </BModal>
    </template>
  </Layout>
</template>
