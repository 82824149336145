export interface IPasswordValidate {
  containNumber: RegExp,
  containUppercase: RegExp,
  containLowercase: RegExp,
  containSymbol: RegExp,
  minLength: RegExp,
}

export const insertHtmlValidations = (passwordValidate: IPasswordValidate): void => {
  const div = document.querySelector('.inputs-password')
  const newDiv = document.createElement('div');
  newDiv.setAttribute('class', 'list-validations')
  div?.appendChild(newDiv)
  for (const property in passwordValidate) {
    const newSpan = document.createElement('span');
    newSpan.setAttribute('class', property)
    newDiv?.appendChild(newSpan)
  }
}

export const validateFormat = (e: string = "", passwordValidate: IPasswordValidate) => {
  for (const property of Object.keys(passwordValidate) as (keyof IPasswordValidate)[]) {
    if (passwordValidate[property].test(e)) {
      const span = document.querySelector(`.${property}`);
      span?.setAttribute('class', `${property} valid`);
    } else {
      const span = document.querySelector(`.${property}`);
      span?.setAttribute('class', property);
    }
  }
  return e;
}
