import { RouteRecordRaw } from "vue-router";
import Login from "@/views/Autenticacion/Login/Login.vue";
import Logout from "@/views/Autenticacion/Logout/Logout.vue";
import Home from "@/views/Landing/Home.vue";
import ContratacionMX from "@/views/Landing/ContratacionMX.vue";
import ContratacionAR from "@/views/Landing/ContratacionAR.vue";
import ContratacionCL from "@/views/Landing/ContratacionCL.vue";
import SendMailRecovery from "./../views/Autenticacion/Login/SendMailRecovery.vue"
import RecuperarPassword from "./../views/Autenticacion/Login/RecuperarPassword.vue"
import getCountryInitial from '../helpers/getCountryInitial';

type DomainComponents = {
  [key: string]: typeof ContratacionMX | typeof ContratacionAR | typeof ContratacionCL;
};

const currentDomain = getCountryInitial()

const domainComponents:DomainComponents = {
  ARGENTINA: ContratacionAR,
  CHILE: ContratacionCL,
  MEXICO: ContratacionMX
};

const PublicRoutes: Array<RouteRecordRaw> = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/send-mail-recovery',
    name: 'Send mail recovery',
    component: SendMailRecovery,
  },

  {
    path: '/recuperar-password',
    name: 'Recuperar password',
    component: RecuperarPassword,
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
  },
  {
    path: '/contratacion',
    name: 'Contratacion',
    component: domainComponents[currentDomain]
  },
  {
    path: '/thanks',
    name: 'Thanks',
    component: ()=> import('../views/Landing/Thanks.vue')
  },
  {
    path: '/fail',
    name: 'Fail',
    component: ()=> import('../views/Landing/PaymentFail.vue')
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Logout,
  },
  {
    path: "",
    redirect: '/home'
  }
];

export default PublicRoutes;
