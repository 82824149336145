import $http from '../services/AxiosInstance';
import { ErrorApi } from '../types/api.type';
import { IAssets, IAudit, ICompanyGPS, IZipCode } from 'src/types/landing.type';
import { downloadPDFBase64 } from '../helpers';
import getCountryInitial from '../helpers/getCountryInitial';
import { IClient } from 'src/types/client.type';
import { LocationQueryValue } from 'vue-router';
import { IFinalCalculation } from 'src/helpers/finalCalculationAR';

enum HeaderType {
  AR = 'AR',
  CL = 'CL',
  MX = 'MX'
}

interface ResGetAssets extends ErrorApi {
  assets?: Array<IAssets>;
  nombre?: string;
  value?: number;
}

interface ResCreateAudit extends ErrorApi {
  assets?: Array<IAssets>;
  nombre?: string;
  value?: number;
}

interface ResFindClientByTributaryCode extends ErrorApi {
  locations?: ResZipCodes | null;
  client?: IClient | null;
}

interface ResCompanyGPS extends ErrorApi {
  companiesGPS?: ICompanyGPS[];
}

interface ResZipCodes extends ErrorApi {
  data?: IZipCode[];
  message?: string;
}

const getAssets = async (country: string | null | undefined = null): Promise<ResGetAssets> => {
  try {
    const params = country ? { params: { country } } : {};
    const { data } = await $http.get('/assets', params);
    const assets = data.data.map((asset: IAssets) => ({
      nombre: asset.description,
      id: asset.id,
    }));
    return assets;
  } catch (error: any) {
    return { error };
  }
};

const createAudits = async (params: IAudit): Promise<ResCreateAudit> => {
  try {
    const { data } = await $http.post('/audits', params);
    return data;
  } catch (error: any) {
    return { error };
  }
};

const findClient = async (email: string): Promise<IClient> => {
  try {
    const { data } = await $http.get('/clients/search', {
      params: {
        email: email,
      },
    });
    return data;
  } catch (error: any) {
    throw error;
  }
};

const findZipCode = async (
  zipCode: number,
  isForSelect: boolean,
): Promise<ResZipCodes> => {
  try {
    const { data: location } = await $http.get('/chubb/location', {
      params: {
        zipCode: zipCode,
      },
    });

    let locations = location;

    if (isForSelect) {
      locations = location.data.map((loc: any) => ({
        nombre: loc.ColoniaDsc,
        id: loc.id,
      }));
    }
    return locations;
  } catch (error: any) {
    return { error };
  }
};

const findClientByTributaryCode = async (
  code: string,
  country: string | null = null
): Promise<ResFindClientByTributaryCode> => {
  try {
    const countryInitial = country ?? getCountryInitial();
    const { data } = await $http.get(`/clients/searchClient/${code}`);
 
    if (countryInitial === 'MEXICO') {
      if (!data.data) return { locations: null, client: null };

      if (!data.data.postCode || !data.data.neighborhood) {
        return { locations: null, client: data.data };
      }

      const locations = await findZipCode(data.data.postCode, true);

      return { locations, client: data.data };
    }

    return { client: data.data };
  } catch (error: any) {
    return { error };
  }
};

const findCompaniesGPS = async (): Promise<ICompanyGPS[]> => {
  try {
    const { data } = await $http.get('/companiesGPS/all');
    const companiesGPS = data.map((companyGPS: ICompanyGPS) => ({
      nombre: companyGPS.description,
      slug: companyGPS.slug,
      id: companyGPS.id,
    }));

    return companiesGPS;
  } catch (error: any) {
    return { error } as any;
  }
};

const createOrUpdateClient = async (id: string | undefined, body: any, headerType: keyof typeof HeaderType) => {
  try {
    const header = {
      headers: {
        'xcountry': headerType
      }
    }
    if (!id) {
      const { data } = await $http.post('/clients', body, header);
      return data;
    } else {
      const { data } = await $http.put(`/clients/${id}`, body, header);
      return data;
    }
  } catch (error: any) {
    return { error };
  }
};

const createPolicy = async (clientId: number, MagenerId: number, body: any) => {
  const formData = new FormData();

  // Agregar cada campo del objeto createPolicyData al FormData
  for (const key in body) {
    if (body.hasOwnProperty(key)) {
      formData.append(key, body[key]);
    }
  }

  const policy = await $http.post(
    `/clients/${clientId}/managers/${MagenerId}/policies`,
    formData,
    {
      headers: {
        'xcountry': 'MX'
      }
    }
  );

  return policy.data.id;
};

const createPolicyForTrygerLatam = async (
  clientId: number,
  MagenerId: number,
  body: any,
) => {
  const formData = new FormData();

  // Agregar cada campo del objeto createPolicyData al FormData
  for (const key in body) {
    if (body.hasOwnProperty(key)) {
      formData.append(key, body[key]);
    }
  }

  const { data } = await $http.post(
    `/clients/${clientId}/managers/${MagenerId}/policies`,
    formData,
  );

  return data.paymentHubUrl;
};
const commitPolicyForTrygerLatam = async (
  policyId: string | LocationQueryValue[],
  guid: string | LocationQueryValue[],
  invoiceId: string | LocationQueryValue[],
) => {
  const body = {
    guid,
    invoiceId,
  };

  await $http.put(`/clients/commitPolicies/${policyId}`, body);
};

const getPricing = async (
  indemnificationLimit: number,
  tributaryCode: string,
  regionProvince: string,
): Promise<IFinalCalculation> => {
  const { data } = await $http.post('/clients/pricing', {
    indemnificationLimit,
    tributaryCode,
    regionProvince,
  });

  return data;
};

const getPDFCoverage = async (policyId: any) => {
  try {
    const policyIdString = policyId.toString();
    const response = await $http.get(`/clients/policies/${policyIdString}/pdf`);
    downloadPDFBase64(response, policyIdString);
  } catch (error: any) {
    return { error };
  }
};

export {
  getAssets,
  createAudits,
  findClient,
  findClientByTributaryCode,
  findCompaniesGPS,
  findZipCode,
  createOrUpdateClient,
  createPolicy,
  getPDFCoverage,
  createPolicyForTrygerLatam,
  commitPolicyForTrygerLatam,
  getPricing,
};
