import $http from '../services/AxiosInstance';

interface Params {
  page: number;
  search: string;
}

interface LogResponseBackend {
  appName: string,
  createdAt: string,
  id: number,
  requestApi: string,
  updatedAt: string
  responseErrorApi?: string
}

class LogsPoliciesApiRepository {
  async getlogsPoliciesApi(params: Params) {
    try {
      let url = `/logsPoliciesApi?page=${params.page}`;
      if (params.search) {
        url += `&search=${params.search}`;
      }
      const response = await $http.get(url);

      const parseResponse = response.data.logsPoliciesApi.map((log: LogResponseBackend) => ({
        id: log.id,
        ...JSON.parse(log.requestApi),
        appName: log.appName,
        createdAt: log.createdAt,
        requestApi: log.requestApi,
        responseErrorApi: log.responseErrorApi
      }));

      return {
        logsPoliciesApi: parseResponse,
        pagination: response.data.pagination
      };
    } catch (errors: any) {
      throw Error;
    }
  }
}

export default LogsPoliciesApiRepository;
