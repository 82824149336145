import { createApp } from "vue";
import { createPinia } from "pinia";
import App from "./App.vue";
import router from "./router/Router";

import CorporationVueLibrary from 'corporation-vue-library';
import 'corporation-vue-library/dist/style.css'

import 'bootstrap-vue-next/dist/bootstrap-vue-next.css'
import 'bootstrap/dist/css/bootstrap.css'
import BootstrapVueNext from 'bootstrap-vue-next'

import 'vue-toast-notification/dist/theme-sugar.css';
import ToastPlugin from 'vue-toast-notification';

import BaseApiUrl from '@services/BaseApiUrl';


const pinia = createPinia();
const app = createApp(App)
  .use(pinia)
  .use(router)
  .use(CorporationVueLibrary)
  .use(BootstrapVueNext)
  .use(ToastPlugin)
  .mount("#app");

app.$.appContext.config.globalProperties.$api = BaseApiUrl