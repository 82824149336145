<script setup lang="ts">
import moment from 'moment';
import { ref, onMounted } from 'vue';
import AuditsRepository from '../../repositories/audits.repository';

const auditsRepository = new AuditsRepository();
const auditsData = ref([]);
const paginationData = ref();
const currentPage = ref<number>();

const getAuditsAPI = async (page: number) => {
  const { data } = await auditsRepository.getAudits({
    page,
  });
  auditsData.value = data.audits ?? [];
  paginationData.value = data.pagination;
};

onMounted(async () => {
  getAuditsAPI(1);
});

const fieldsTable: any = ref([
  {
    key: 'id',
    label: 'Id',
    sortable: true,
  },
  {
    key: 'asset',
    label: 'Tipo de Mercancia',   
  },
  {
    key: 'email',
    label: 'Email',
  },
  {
    key: 'phone',
    label: 'Phone',
  },
  {
    key: 'createdAt',    
    label: 'Fecha y Hora',
    formatter: (e: any) => moment(e).format('DD-MM-YYYY HH:mm'),
  },
]);
const onChangePage = async (page: any) => {
  getAuditsAPI(page);
  currentPage.value = page;
};

const downloadExcel = () => {
  auditsRepository.getAuditsExcel();
};
</script>

<template>
  <Layout title="Seguimiento web" :textColor="'color-secondary'">
    <template #layout>
      <CButton
        class="background-blue button__form"
        style="padding: 1rem !important"
        type="button"
        @click="downloadExcel"
      >
        <template #content> Descargar Excel </template>
      </CButton>
      <Table
        :fields="fieldsTable"
        :items="auditsData"
        @update:model-value-page="onChangePage"
        :totalRows="paginationData?.count"
        :perPage="20"
        :currentPage="paginationData?.current_page"
      >
      </Table>
    </template>
  </Layout>
</template>
