<script setup lang="ts">
import { ref, onMounted, onUnmounted } from 'vue';
import { inputsStep2MX, inputsStep3MX, inputsStep4MX } from './utils/inputsForm';
import { useToast } from '../../composables/useToast';
import { useMessage } from '../../composables/useMessage';
import {
  evaluateNames,
  validationRFC,
  wrongDepartureDateTime,
} from '../../helpers';
import {
  findClientByTributaryCode,
  findCompaniesGPS,
  findZipCode,
  createOrUpdateClient,
  createPolicy,
} from '../../repositories/landing.repository';

const { $toast } = useToast();
const { messageHTML, showMessage } = useMessage();

const props = defineProps({
  dataForm1: {
    type: Object,
    required: true,
  },
});

const fieldsStep2: any = ref(inputsStep2MX);
const fieldsStep3: any = ref(inputsStep3MX);
const fieldsStep4: any = ref(inputsStep4MX);
const inputsHidden = ref<boolean>(true);
const dataForm: any = ref({});
const refFormClientData = ref<any>(null);
const loading = ref<boolean>(false);

const emit = defineEmits<{
  (e: 'firstView'): void;
}>();

onMounted(async () => {
  if (!props.dataForm1) {
    inputsHidden.value = true;
    emit('firstView');
  }
});

onMounted(async () => {
  const comapaniesGPS = await findCompaniesGPS();
  fieldsStep3.value = fieldsStep3.value.map((field: any) => {
    if (field.key === 'companyGPSId') {
      field.options = comapaniesGPS;
    }
    return field;
  });
});

onUnmounted(() => {
  fieldsStep3.value = inputsStep3MX.map((e) => (e.value = null));
  fieldsStep4.value = inputsStep4MX.map((e) => (e.value = null));
  inputsHidden.value = true;
  dataForm.value = {};
});

const handleOnSubmitRFC = async (e: any) => {
  const isValidRfc = validationRFC(e.items.rfc);

  if (!e.isFormValid || !isValidRfc) {
    return (fieldsStep2.value[0].errors = ['rfc es invalido']);
  }

  const { locations, client } = await findClientByTributaryCode(e.items.rfc, 'MEXICO');

  if (client) {
    dataForm.value = { client };
  }

  if (locations && client) {
    const updatesClientInputs = [
      { key: 'tradeName', value: client.tradeName },
      { key: 'email', value: props?.dataForm1?.email ?? null },

      { key: 'phone1', value: client.phone1 },
      {
        key: 'companyGPSId',
        value: {
          id: client.companyGPS?.id,
          nombre: client.companyGPS?.description,
        },
      },
      { key: 'address', value: client.address },
      { key: 'postCode', value: client.postCode },
      {
        key: 'colony',
        options: locations,
        value: {
          id: client.neighborhood.id,
          nombre: client.neighborhood.description,
        },
      },
      { key: 'city', value: client.city.description },
      { key: 'state', value: client.state.description },
    ];

    updateFieldsInArray(fieldsStep3.value, updatesClientInputs, null);
  }

  const updatesTravelInputs = [
    { key: 'assetId', value: props?.dataForm1?.asset?.nombre ?? null },
    {
      key: 'indemnificationLimit',
      value: props?.dataForm1?.indemnificationLimit ?? null,
    },
    {
      key: 'indemnificationLimit-calc',
      value: `${props?.dataForm1?.endPrice} + IVA` ?? null,
    },
  ];

  updateFieldsInArray(fieldsStep4.value, updatesTravelInputs, null);
  inputsHidden.value = false;

  if (!client) {
    const updatesClientInputs = [
      { key: 'email', value: props?.dataForm1?.email ?? null },
    ];
    updateFieldsInArray(fieldsStep3.value, updatesClientInputs, null);

    dataForm.value = { client: { rfc: e.items.rfc } };
  }
};

const handleChangeColony = async (e: any) => {
  const formProps = await refFormClientData.value.getValues();

  const location = await findZipCode(e.value, false);

  if (!location?.data?.length) return;

  const locations = location.data.map((loc: any) => ({
    nombre: loc.ColoniaDsc,
    id: loc.id,
  }));

  const preservedValues = {
    tradeName: formProps.items.tradeName,
    email: formProps.items.email,
    phone1: formProps.items.phone1,
    companyGPSId: formProps.items.companyGPSId,
    address: formProps.items.address,
  };

  updateFieldsInArray(
    fieldsStep3.value,
    [
      { key: 'colony', options: locations, value: { id: null } },
      { key: 'state', value: location.data[0].EstadoNombre },
      { key: 'city', value: location.data[0].MunicipioNombre },
      { key: 'postCode', value: location.data[0].code },
    ],
    preservedValues
  );

  dataForm.value.client = {
    ...dataForm.value.client,
    state: {
      id: location.data[0].EstadoID,
      description: location.data[0].EstadoNombre,
    },
    city: {
      id: location.data[0].MunicipioId,
      description: location.data[0].MunicipioNombre,
    },
    country: {
      id: location.data[0].PaisID,
      description: location.data[0].PaisNombre,
    },
  };
};

const handleChange = async (e: any) => {
  if (e.value.length < 4 || e.value.length > 7) return;

  const location = await findZipCode(e.value, false);

  // Si no existe el codigo postal dejamos una bandera en el input
  if (!location.data?.length) {
    if (e.input.key === 'sourceCityZipCodeId') {
      fieldsStep4.value.find(
        (field: any) => field.key === 'sourceCityZipCodeIdCalc'
      ).value = 'CP INVALIDO';
    } else {
      fieldsStep4.value.find(
        (field: any) => field.key === 'destinationCityZipCodeIdCalc'
      ).value = 'CP INVALIDO';
    }
    return;
  }

  // se estable el nombre de la locacion en el input
  if (e.input.key === 'sourceCityZipCodeId') {
    fieldsStep4.value.find(
      (field: any) => field.key === 'sourceCityZipCodeIdCalc'
    ).value = location.data[0].MunicipioNombre;
    dataForm.value.client = {
      ...dataForm.value.client,
      sourceCityZipCodeId: location.data[0].id,
    };
  } else {
    fieldsStep4.value.find(
      (field: any) => field.key === 'destinationCityZipCodeIdCalc'
    ).value = location.data[0].MunicipioNombre;
    dataForm.value.client = {
      ...dataForm.value.client,
      destinationCityZipCodeId: location.data[0].id,
    };
  }
};

const handleSelectChange = (e: any) => {
  const updateFields = [];

  if (e.input.key === 'box-trailer') {
    updateFields.push(
      { key: 'trailerPlate', disabled: !e.value.id },
      { key: 'trailerPlate', 'validations.rules.required': e.value.id }
    );
  } else {
    updateFields.push(
      { key: 'stop1', disabled: !e.value.id },
      { key: 'stop1', 'validations.rules.required': e.value.id },
      { key: 'stop2', disabled: !e.value.id },
      { key: 'stop3', disabled: !e.value.id }
    );
  }

  updateFieldsInArray(fieldsStep4.value, updateFields, null);
};

const updateFieldsInArray = (
  array: any,
  updates: any,
  preservedValues: any
) => {
  for (const update of updates) {
    const field = array.find((field: any) => field.key === update.key);
    if (field) {
      if ('value' in update) {
        field.value = update.value;
      }
      if ('options' in update) {
        field.options = update.options;
      }
      if ('disabled' in update) {
        field.disabled = update.disabled;
      }
    }
  }

  if (preservedValues) {
    for (const key in preservedValues) {
      const field = array.find((field: any) => field.key === key);
      if (field) {
        field.value = preservedValues[key];
      }
    }
  }
};

const handleOnSubmitPolicies = async ({
  items: refFormTravelDataItems,
  isFormValid: refFormTravelDataValid,
}: any) => {
  const { items: refFormClientDataItems, isFormValid: refFormClientDataValid } =
    await refFormClientData.value.getValues();

  // Validación de campos requeridos
  if (!refFormClientDataValid) return;
  if (!refFormTravelDataValid) return;

  // Desestruccturacion de propiedades
  const {
    truckPlate,
    trailerPlate,
    driverName,
    documentDescription,
    driverCellphone,
    driverEmail,
    startDate,
    endDate,
    startTime,
    endTime,
    stops,
    stop1,
    stop2,
    stop3,
    destinationCityZipCodeIdCalc,
    sourceCityZipCodeIdCalc,
    document,
  } = refFormTravelDataItems;

  const { address, companyGPSId, email, phone1, tradeName, postCode, colony } =
    refFormClientDataItems;

  // Validación de datos
  const isOkDepartureDateTime = wrongDepartureDateTime(startDate, startTime);

  if (isOkDepartureDateTime) {
    return showMessage(
      'La hora de salida no puede ser menor a dentro de 90 minutos.',
      'error'
    );
  }

  if (
    destinationCityZipCodeIdCalc === 'CP INVALIDO' ||
    sourceCityZipCodeIdCalc === 'CP INVALIDO'
  ) {
    return showMessage('Codigo postal no valido', 'error');
  }

  const validationRfcAndName = evaluateNames(
    dataForm.value.client.rfc,
    tradeName
  );

  const { namesError, ...restEvaluateNames } = validationRfcAndName;

  if (namesError) {
    return showMessage(
      'Ingrese su nombre completo, seguido de su apellido paterno y luego su apellido materno.',
      'error'
    );
  }

  // Formato de la data para el request
  const createOrUpdateClientData = {
    ...restEvaluateNames,
    namesError,
    address,
    email,
    tradeName,
    phone1,
    countryId: dataForm.value.client.country.id,
    cityId: dataForm.value.client.city.id,
    rfc: dataForm.value.client.rfc,
    stateId: dataForm.value.client.state.id,
    neighborhoodId: colony.id,
    postCode: postCode,
    companyGPSId: companyGPSId.id,
    urlDomain: window.location.origin,
  };

  const travelStartDate = new Date(`${startDate}T${startTime}`).toISOString();
  const travelEndDate = new Date(`${endDate}T${endTime}`).toISOString();
  const assetId = props.dataForm1.asset.id;

  const createPolicyData = {
	rfc: dataForm.value.client.rfc,
	tradeName,
    truckPlate,
    trailerPlate,
    driverName,
    documentDescription,
    assetId,
    driverCellphone,
    driverEmail,
    travelStartDate,
    travelEndDate,
    stop1,
    stop2,
    stop3,
    indemnificationLimit: props.dataForm1.indemnificationLimit,
    sourceCityZipCodeId: dataForm.value.client.sourceCityZipCodeId,
    destinationCityZipCodeId: dataForm.value.client.destinationCityZipCodeId,
    companyGPSId: companyGPSId.id,
    hasStops: stops.nombre,
    file: document,
	isAuth: true,
  };

  try {
    loading.value = true;

    // Request: 1 - si el cliente no existe lo crea, sino lo actualiza, 2 - crea la poliza
    const client = await createOrUpdateClient(
      dataForm.value.client.id,
      createOrUpdateClientData,
	  'MX'
    );

    const newPolicyId = await createPolicy(
      client.data.client.id,
      client.data.manager.id,
      createPolicyData
    );
    loading.value = false;
    if (newPolicyId) {
      $toast?.success(`Operación registrada con exito`);
      emit('firstView');
    }
  } catch (error: any) {
    loading.value = false;
    showMessage(`Ocurrió un Error. ${error.message}`, 'error');
  }
};
</script>

<template>
  <Layout title="Nueva Contratación" :textColor="'color-secondary'">
    <template #layout>
      <Form
        :inputs="fieldsStep2"
        @onSubmit="handleOnSubmitRFC"
        v-if="inputsHidden"
      >
        <template #buttons>
          <div class="d-flex">
            <CButton background="background-blue" style="font-size: 1rem">
              <template #content> Comprobar RFC </template>
            </CButton>
          </div>
        </template>
      </Form>
      <template v-if="!inputsHidden">
        <h4 class="color-pink">
          Datos del cliente (RFC: {{ dataForm?.client?.rfc ?? null }})
        </h4>
        <Form
          ref="refFormClientData"
          :inputs="fieldsStep3"
          @change="handleChangeColony"
        ></Form>
        <h4 class="my-3 color-pink">Datos del viaje</h4>
        <Form
          ref="refFormTravelData"
          :inputs="fieldsStep4"
          @onSubmit="handleOnSubmitPolicies"
          @change="handleChange"
          @select="handleSelectChange"
        >
          <template #buttons>
            <div class="my-2" v-if="messageHTML" v-html="messageHTML" />
            <CButton
              class="p-4 w-100 px-2"
              background="background-blue"
              style="font-size: 1.5rem"
            >
              <template #content>
                <div>
                  <BSpinner v-if="loading" />
                  <span v-else>REGISTRAR PÓLIZA</span>
                </div>
              </template>
            </CButton>
          </template>
        </Form>
      </template>
    </template>
  </Layout>
</template>
