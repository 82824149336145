import { User } from 'src/types/user.type';

const isCanAccess = (to: any, user: User) => {
  if (to.name === 'Noc' && user.isDev) {
    return true;
  }

  return user.permission.includes(to.name);
};

export default isCanAccess;
