<script lang="ts" setup>
import { ref, onMounted, reactive, onBeforeMount } from 'vue';
import './Login.scss';
import { useToast } from './../../../composables/useToast';
import { useRouter } from 'vue-router';
import autenticacionRepository from '@repositories/autenticacion.repository';
import {
  insertHtmlValidations,
  validateFormat,
} from './../../Ajustes/Usuarios/handleValidator';

const router = useRouter();
const { $toast } = useToast();
const autenticacionController = new autenticacionRepository();

const refForm = ref<any>(null);
const errorMessages = ref<string>('');
const loading = ref<boolean>(false);
const qrCode = ref<string>('');
const token = ref<string>('');

const passwordValidate: any = reactive({
  containNumber: /.*\d+.*/,
  containUppercase: /[A-Z]/,
  containLowercase: /[a-z]/,
  containSymbol: /.*[\W_].*/,
  minLength: /^.{8,}$/,
});
onBeforeMount(async () => {
  const queryUrl = router.currentRoute.value.query;
  if (queryUrl?.token && typeof queryUrl?.token === 'string') {
    const response = await autenticacionController.verifyToken(queryUrl.token);
    response?.token
      ? (token.value = response.token)
      : router.push({ name: 'Login' });
  } else {
    router.push({ name: 'Login' });
  }
});
onMounted(async () => {
  insertHtmlValidations(passwordValidate);
});

const fieldsUpdatePassword: any = ref([
  {
    key: 'password',
    title: 'Contraseña',
    type: 'password',
    validations: {
      rules: {
        required: true,
        min: 8,
        verifyExpreRegular:
          /^(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\-])(?=.*[A-Z])(?=.*[a-z])(?=.*\d).+$/,
      },
    },
    placeholder: 'Ejemplo.123',
    value: null,
    cols: 12,
    customClass: 'inputs-password',
    formatValue: (e: any) => validateFormat(e, passwordValidate),
  },
  {
    key: 'repeatPassword',
    title: 'Repetir Contraseña',
    type: 'password',
    validations: {
      rules: {
        required: true,
      },
    },
    placeholder: 'Repetir contraseña',
    value: null,
    cols: 12,
  },
]);

const handleSubmit = async () => {
  const { items, isFormValid } = await refForm.value.getValues();
  if (!isFormValid) return false;
  if (items.password !== items.repeatPassword) {
    return (errorMessages.value = 'Las contraseñas no coinciden');
  }
  const response = await autenticacionController.changePassowrd({
    token: token.value,
    password: items.password,
  });

  if (response?.msg == 'La contraseña fue actualizada correctamente') {
    $toast?.success(`Contraseña actualizada`);
    router.push({ name: 'Login' });
  } else {
    $toast?.error(`Hubo un error`);
    router.push({ name: 'Login' });
  }
};
</script>

<template>
  <div
    :style="`background-image: url(img/bg_home.png)`"
    class="login-container"
  >
    <div
      class="container d-flex justify-content-center align-items-center h-100"
    >
      <div class="login-container__card p-4">
        <div
          class="d-flex flex-column justify-content-center login-container__firstBox mt-4"
        >
          <div class="text-center my-2 login-container__title">
            Enviar codigo de recuperación
          </div>
        </div>
        <div
          class="d-flex flex-column justify-content-center align-items-center login-container__form"
        >
          <template v-if="errorMessages.length > 0">
            <div class="alert alert-danger">
              {{ errorMessages }}
            </div>
          </template>
          <div v-if="loading">
            <Overlay
              :show="loading"
              :circleColor="'#40a4c6'"
              :borderColor="'#40a4c6'"
            />
          </div>
          <template v-else-if="!qrCode">
            <div class="d-flex justify-content-center">
              <Form :inputs="fieldsUpdatePassword" ref="refForm" />
            </div>
            <CButton
              class="py-2 w-100 my-3 mb-4"
              text="Cambiar contraseña"
              :background="`background-blue`"
              :disabled="loading"
              @click="handleSubmit"
              type="button"
            />
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
