import { IPagination, IPolicie } from "src/types/policies.type";
import $http from "../services/AxiosInstance";
import saveAs from 'file-saver';

interface Params {
  page?: number,
  startDate?: string,
  endDate?: string,
  company?: number,
}

export interface getPoliciesResponse {
  policies: {
    policies?: IPolicie[]
    pagination?: IPagination
  }
}

class PolicyRepository {
  async getPolicies(params: Params): Promise<getPoliciesResponse> {

    try {
      const response = await $http.get('/integrations/policyAll', { params });

      return response.data;
    } catch (errors: any) {
      return errors;
    }
  }

  async getExcelPolicies({ toExcel, startDate, endDate, company }: {
    toExcel: boolean;
    startDate: string | null;
    endDate: string | null;
    company: number | null;
  }) {
    async function getImage(url: string, params: any) {
      const headers = { ...params.headers };
      delete headers['Content-Type'];

      const config = {
        headers,
        params,
        responseType: 'blob' as any,
      };

      if (params) {
        config.params = params;
      }

      return await $http.get(url, config);
    }
    try {

      const params = new URLSearchParams();
      params.append('toExcel', String(toExcel));
      //@ts-ignore
      params.append('startDate', startDate);
      //@ts-ignore
      params.append('endDate', endDate);
      //@ts-ignore
      params.append('company', company);

      const { data } = await getImage('/integrations/policyAll', params);

      saveAs(data, `Polizas.xlsx`);
    }
    catch (error) {
      return error;
    }
  }

  async deletePolicy(id: number) {
    try {
      const response = await $http.delete(`/integrations/deletePolicy/${id}`);
      return response.data;
    } catch (errors: any) {
      return errors;
    }
  }
}

export default PolicyRepository;
