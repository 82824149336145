export const evaluateNames = (rfc: string, tradeName: string) => {
    let isClientAPhysicalPerson = false;
    let namesError = false;
    let clientMotherSurname = '';
    let clientFathersSurname = '';
    let clientNames = '';
    let clientBirthOrConstDate = '';
    let taxIdVerificationCode = '';
  
    isClientAPhysicalPerson = rfc.length === 13 ? true : false;
    namesError = false;
    const nameBeforeCleanUp = tradeName
    const cleanedName = nameBeforeCleanUp.replace(/\s\s+/g, ' ').trim().toUpperCase();
  
    if (isClientAPhysicalPerson) {
      const fathersSurnameInitials = rfc.charAt(0) + rfc.charAt(1);
      const mothersSurnameInitial = rfc.charAt(2);
      const arrayOfNames = cleanedName.split(' ');
  
      if (mothersSurnameInitial === arrayOfNames[arrayOfNames.length - 1].charAt(0)) {
        clientMotherSurname = arrayOfNames[arrayOfNames.length - 1];
      } else {
        namesError = true;
      }
  
      if (fathersSurnameInitials === arrayOfNames[arrayOfNames.length - 2].charAt(0) + arrayOfNames[arrayOfNames.length - 2].slice(1).match(/[aeiou]/ig)?.join('').charAt(0)) {
        clientFathersSurname = arrayOfNames[arrayOfNames.length - 2];
      } else {
        namesError = true;
      }
  
      let names = '';
      for (let i = 0; i < arrayOfNames.length - 2; i++) {
        names = names + ' ' + arrayOfNames[i];
      }
      clientNames = names;
  
      const year = parseInt(`${rfc.charAt(4)}${rfc.charAt(5)}`, 10) > parseInt(new Date().getFullYear().toString().substr(-2), 10) ?
        '19' + rfc.charAt(4) + rfc.charAt(5) : '20' + rfc.charAt(4) + rfc.charAt(5);
      const month = rfc.charAt(6) + rfc.charAt(7);
      const day = rfc.charAt(8) + rfc.charAt(9);
      clientBirthOrConstDate = (`${year}-${month}-${day}`);
  
    } else {
      const year = parseInt(`${rfc.charAt(3)}${rfc.charAt(4)}`, 10) > parseInt(new Date().getFullYear().toString().substr(-2), 10) ?
        '19' + rfc.charAt(3) + rfc.charAt(4) : '20' + rfc.charAt(3) + rfc.charAt(4);
      const month = rfc.charAt(5) + rfc.charAt(6);
      const day = rfc.charAt(7) + rfc.charAt(8);
      clientBirthOrConstDate = (`${year}-${month}-${day}`);
    }
    taxIdVerificationCode = (rfc.slice(rfc.length - 3));
  
    return {
      isClientAPhysicalPerson,
      clientMotherSurname,
      clientFathersSurname,
      clientNames,
      clientBirthOrConstDate,
      taxIdVerificationCode,
      namesError
    }
  }