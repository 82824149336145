<script setup lang="ts">
import { ref, onMounted } from 'vue';
import moment from 'moment';

//pinia
import Store from '@store/dataProvider';
import { storeToRefs } from 'pinia';

// composables
import { useToast } from '../../../composables/useToast';
import useAuthStore from '@store/auth';

// types
import { IAgents } from './../../../types/agents.type';
import { IUser } from '../../../types/user.type';
import { ITemplateUI } from '../../../types/templatesUI.type';

//repositories
import TemplatesUIDataProviderRepository from '../../../repositories/templatesUI.repository';
import UserApi from '@repositories/user.repository';
import AgentsDataProviderRepository from '@repositories/agents.repository';

//components
import ModalContentRoles from './ModalContentRoles.vue';
import ModalCreateUser from './ModalCreateUser.vue';
import ModalUpdateUser from './ModalUpdateUser.vue';

const store = Store();
const { users }: any = storeToRefs(store);

const { user } = useAuthStore();
const { $toast } = useToast();

const agentsProviderController = new AgentsDataProviderRepository();
const templatesProviderController = new TemplatesUIDataProviderRepository();

const userRepository = new UserApi();
const usersData = ref<IUser[]>([]);
const agentsData = ref<Array<IAgents>>([]);
const templatesUIData = ref<Array<ITemplateUI>>([]);
const paginationData = ref();
const currentPage = ref<number>();
const isModalOpen = ref<boolean>(false);
const modalType = ref<string | null>(null);
const modalTitle = ref<string>();
const selectedUserInfo = ref<IUser | null>(null);

const getTemplatesUIAPI = async () => {
  const response = await templatesProviderController.getTemplates();
  if (!response) return;
  if (Array.isArray(response?.data)) {
    templatesUIData.value = response.data;
  } else {
    templatesUIData.value = [];
  }
};
const getAgentsAPI = async () => {
  const response = await agentsProviderController.indexAgents();
  if (!response) return;
  if (Array.isArray(response?.data)) {
    agentsData.value = response.data;
  } else {
    agentsData.value = [];
  }
};

const getUsersAPI = async (page: number) => {
  const data = await userRepository.getUsers({
    page,
  });
  if (!data) return;
  if (Array.isArray(data?.users)) {
    usersData.value = data.users;
    paginationData.value = data.pagination;
    store.setProp({ key: 'users', value: usersData.value });
  } else {
    usersData.value = [];
  }
};

onMounted(async () => {
  getUsersAPI(1);
  getAgentsAPI();
  getTemplatesUIAPI();
});

const fieldsTable: any = ref([
  {
    key: 'id',
    label: 'Id',
    sortable: true,
  },
  {
    key: 'email',
    label: 'Email',
  },
  {
    key: 'fullName',
    label: 'Nombre',
  },
  {
    key: 'apiKey',
    label: 'Compañia',
    formatter: (e: any) => e?.appName || null,
  },
  {
    key: 'createdAt',
    label: 'Fecha y Hora',
    formatter: (e: any) => moment(e).format('DD-MM-YYYY HH:mm'),
  },
  {
    key: 'userInfo',
    label: 'Editar',
  },
  {
    key: 'statusBlock',
    label: 'Estado',
  },
]);

const onChangePage = async (page: any) => {
  getUsersAPI(page);
  currentPage.value = page;
};

const handleOpenModalRoles = () => {
  modalTitle.value = 'Administrar roles';
  isModalOpen.value = true;
  modalType.value = 'modalAddRoles';
};
const handleOpenModalUsers = () => {
  modalTitle.value = 'Crear usuario';
  isModalOpen.value = true;
  modalType.value = 'modalAddUsers';
};
const handleClose = () => {
  modalTitle.value = undefined;
  isModalOpen.value = false;
  modalType.value = null;
};

const handleSelectClient = (userInfo: any) => {
  modalTitle.value = 'Actualizar usuario';
  (isModalOpen.value = true), (modalType.value = 'modalUpdateUser');
  selectedUserInfo.value = userInfo;
};

const handleChangeStatusUser = async (e: any) => {
  if (e.id === user?.id) {
    return $toast?.error(`No puedes auto bloquearte`);
  }

  if (!e.deletedAt) {
    try {
      const { data } = await userRepository.blockUser(e.id);
      if (!data) throw new Error();
      let index = store.users.findIndex((value: any) => value.id === data.id);
      if (index > -1) {
        store.setProp({ key: 'users', index, value: data });
      }

      $toast?.success(`Usuario bloqueado con exito`);
      return;
    } catch (error) {
      $toast?.error(`Ocurrio un error`);
    }
    return;
  }
  try {
    const { data } = await userRepository.unBlockUser(e.id);
    if (!data) throw new Error();
    let index = store.users.findIndex((value: any) => value.id === data.id);
    if (index > -1) {
      store.setProp({ key: 'users', index, value: data });
    }

    $toast?.success(`Usuario desbloqueado con exito`);
  } catch (error) {
    $toast?.error(`Ocurrio un error`);
  }
};
</script>

<template>
  <Layout title="Usuarios" :textColor="'color-secondary'">
    <template #layout>
      <div class="d-flex gap-2 justify-content-end">
        <CButton
          class="background-pink button__form"
          style="padding: 0.5rem !important"
          type="button"
          @click="handleOpenModalRoles"
        >
          <template #content> Administrar Roles </template>
        </CButton>
        <CButton
          class="background-pink button__form"
          style="padding: 0.5rem !important"
          type="button"
          @click="handleOpenModalUsers"
        >
          <template #content> Crear usuario </template>
        </CButton>
      </div>
      <Table
        :fields="fieldsTable"
        :items="users"
        @update:model-value-page="onChangePage"
        :totalRows="paginationData?.count"
        :perPage="20"
        :currentPage="paginationData?.current_page"
      >
        <template v-if="true" v-slot:cell(userInfo)="operationsData">
          <div
            @click="handleSelectClient(operationsData.data.item)"
            class="button-icon"
          >
            <img src="/icons/editar.svg" />
          </div>
        </template>
        <template v-if="true" v-slot:cell(statusBlock)="userData">
          <div>
            <img
              @click="handleChangeStatusUser(userData.data.item)"
              class="cursor-pointer"
              :src="
                userData.data.item.deletedAt
                  ? '/icons/user_panel_x.svg'
                  : '/icons/user_panel.svg'
              "
              style="width: 40px"
            />
          </div>
        </template>
      </Table>
      <BModal
        :modelValue="isModalOpen"
        hide-footer
        :centered="true"
        @hide="handleClose"
        static
        :title="modalTitle"
		size="xl"
      >
        <template v-if="modalType === 'modalAddRoles'">
          <ModalContentRoles @closeModal="handleClose" />
        </template>
        <template v-if="modalType === 'modalAddUsers'">
          <ModalCreateUser
            v-if="agentsData && templatesUIData"
            @closeModal="handleClose"
            :agents="agentsData"
            :templatesUI="templatesUIData"
          />
        </template>
        <template v-if="modalType === 'modalUpdateUser'">
          <ModalUpdateUser
            v-if="selectedUserInfo && agentsData && templatesUIData"
            @closeModal="handleClose"
            :userData="selectedUserInfo"
            :agents="agentsData"
            :templatesUI="templatesUIData"
          />
        </template>
      </BModal>
    </template>
  </Layout>
</template>
