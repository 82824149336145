<script setup lang="ts">
import { toRefs } from 'vue';
import { IPolicy } from '../../types/policy.type';
import { formatDateAndHourSpanish } from '../../helpers/functionsMoment';

interface Props {
  policyData: IPolicy;
}
const props = defineProps<Props>();
const { policyData } = toRefs(props);

</script>

<template>
  <p>
    <strong>Chofer: </strong>
    {{ policyData.driverName }}
  </p>
  <p>
    <strong>Teléfono del Chofer: </strong>
    {{ policyData.driverCellphone }}
  </p>
  <p>
    <strong>Placa del Camión: </strong>
    {{ policyData.truckPlate }}
  </p>
  <p>
    <strong>Origen: </strong>
    {{ policyData.originLocation }} ({{ policyData.originRegionProvince }})
  </p>
  <p>
    <strong>Destino: </strong>
    {{ policyData.destinationLocation }} ({{
      policyData.destinationRegionProvince
    }})
  </p>
  <p>
    <strong>Fecha de Inicio del Viaje: </strong>
    {{ formatDateAndHourSpanish(policyData.travelStartDate) }}
  </p>
  <p>
    <strong>Fecha de Fin del Viaje: </strong>
    {{ formatDateAndHourSpanish(policyData.travelEndDate) }}
  </p>
  <p>
    <strong>Descripción del Documento: </strong>
    {{ policyData.documentDescription }}
  </p>
  <p>
    <strong>ID de Cotización: </strong>
    {{ policyData.cotizacionId }}
  </p>
  <p>
    <strong>ID de Póliza: </strong>
    {{ policyData.polizaId }}
  </p>
  <p>
    <strong>N° de poliza: </strong>
    {{ policyData.claveId }}
  </p>
  <p>
    <strong>Precio Total: </strong>
    $ {{ policyData.fullPrice }}
  </p>
  <p>
    <strong>Límite de Indemnización: </strong>
    $ {{ policyData.indemnificationLimit }}
  </p>
  <p>
    <strong>Cliente: </strong>
    {{ policyData.client.tradeName }}
  </p>
  <p>
    <strong>Dirección del Cliente: </strong>
    {{ policyData.client.address }}
  </p>
  <p>
    <strong>Teléfono del Cliente: </strong>
    {{ policyData.client.phone1 }}
  </p>
  <p>
    <strong>CUIT del Cliente: </strong>
    {{ policyData.client.cuit }}
  </p>
  <p>
    <strong>Ubicación del Cliente: </strong>
    {{ policyData.client.location }} ({{ policyData.client.regionProvince }})
  </p>
  <p>
    <strong>Descripción del Activo: </strong>
    {{ policyData.asset.description }}
  </p>
  <p>
    <strong>Tipo de Riesgo: </strong>
    {{ policyData.asset.riskType.description }}
  </p>
  <p>
    <strong>Compañía GPS: </strong>
    {{ policyData.companyGPS.description }}
  </p>
  <p>
    <strong>País: </strong>
    {{ policyData.country.description }}
  </p>
</template>
