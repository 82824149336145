export function validationCUIT(cuit: string): boolean {
  if (cuit.length !== 11) return false;

  let multipliers = [5, 4, 3, 2, 7, 6, 5, 4, 3, 2];
  let sum = 0;
  let checkDigit = parseInt(cuit[10], 10);

  for (let i = 0; i < 10; i++) {
    sum += parseInt(cuit[i], 10) * multipliers[i];
  }

  let calculatedCheckDigit = 11 - (sum % 11);
  if (calculatedCheckDigit === 11) calculatedCheckDigit = 0;
  if (calculatedCheckDigit === 10) calculatedCheckDigit = 9;

  return checkDigit === calculatedCheckDigit;
}

