<script lang="ts" setup>
import './Steps.scss';

const steps = [
  'COTIZA',
  'COMPLETA EL DETALLE DEL VIAJE',
  'SOLICITA LA COBERTURA DEL SEGURO',
  'HAZ EL PAGO ONLINE',
  'OBTÉN LA PÓLIZA DEL SEGURO',
];
</script>

<template>
  <div class="steps">
    <h3 class="steps__title">OBTENER UN SEGURO DE MERCANCÍAS NUNCA HA SIDO TAN FÁCIL Y RÁPIDO</h3>
    <div class="steps__container">
      <div
        :class="{
          steps__box: true,
          'steps__box--light': index % 2 === 0,
          'steps__box--hide-arrow': index === steps.length - 1,
        }"
        v-for="(step, index) in steps"
        :key="step"
      >
        <div class="steps__number">{{ index + 1 }}</div>
        <div class="steps__description">{{ step }}</div>
        <div class="steps__arrow-container">
          <img src="/img/arrow.png" class="steps__arrow-img" />
        </div>
      </div>
    </div>
  </div>
</template>
