<script lang="ts" setup>
import './FormHome.scss';
import { ref, onMounted, computed } from 'vue';
import { calculatePolicyPrice } from '../../../helpers';
import Slider from '../components/Slider.vue';
import { useRoute, useRouter } from 'vue-router';
import {
  getAssets,
  createAudits,
  findClient,
} from '../../../repositories/landing.repository';
import storePolicy from '@store/policy';
import getCountryInitial from '../../../helpers/getCountryInitial';
import getImageFlag from '../../../helpers/getImageFlag';
import getDolarArgentina from '../../../helpers/exchangeArgentina';
import { getDolarChile } from '../../../helpers/exchangeChile';
import { useToast } from '../../../composables/useToast';

const store = storePolicy();

const countryInitial = ref<string>('');
const router = useRouter();
const route = useRoute();
const refTableForm = ref(null);
const toogleIndemnizationLimit = ref<boolean>(false);
const endPrice = ref<number>(0);
const indemnificationLimit = ref<number>(200000);
const dolarPrice = ref<number | null>(null);
const UFvalue = ref<number | null>(null);
const isButtonDisabled = ref<boolean>(false);
const { $toast } = useToast();

const fetchDolarArgentina = async () => {
  try {
    const cotizations = await getDolarArgentina();
    dolarPrice.value = cotizations;
    store.setProp({ key: 'pricing', value: { dolar: dolarPrice.value } });
    handleSliderChange(dolarPrice.value * 1000);
  } catch (error) {
    $toast?.error('Hay un error en la cotización de divisas');
  }
};

const fetchDolarChile = async () => {
  try {
    const cotizations = await getDolarChile();
    if (!cotizations || !cotizations.dolar || !cotizations.uf) {
      $toast?.error('Hay un error en la cotización de divisas');
      return;
    }

    dolarPrice.value = cotizations.dolar;
    UFvalue.value = cotizations.uf;
    store.setProp({
      key: 'pricing',
      value: { dolar: dolarPrice.value, uf: UFvalue.value },
    });

    if (!dolarPrice.value || !UFvalue.value) {
      isButtonDisabled.value = true;
    } else {
      handleSliderChange(Math.round((1000 * dolarPrice.value) / UFvalue.value));
    }
  } catch (error) {
    $toast?.error('Hay un error en la cotización de divisas');
  }
};

const handleCountrySpecificLogic = async (country: string) => {
  if (country === 'ARGENTINA') {
    await fetchDolarArgentina();
  } else if (country === 'CHILE') {
    await fetchDolarChile();
  } else if (country === 'MEXICO') {
    endPrice.value = calculatePolicyPrice(200000);
  }
};

const fetchAssets = async () => {
  try {
    const assets = await getAssets();
    fields.value = fields.value.map((field: any) => {
      if (field.key === 'asset') {
        field.options = assets;
      }
      return field;
    });
  } catch (error) {
    $toast?.error('Error fetching assets');
  }
};

onMounted(async () => {
  countryInitial.value = getCountryInitial();
  await handleCountrySpecificLogic(countryInitial.value);
  await fetchAssets();
});
computed(() => fields.value);

const fields: any = ref([
  {
    key: 'email',
    title: 'Email',
    type: 'email',
    validations: {
      rules: {
        required: true,
        email: true,
      },
    },

    placeholder: 'Email',
    value: null,
    cols: 12,
    customClass: 'inputLanding',
    preppendClass: 'inputLanding-custom-user bg-white',
    iconLeft: '/img/user.png',
    listenFocus: true,
  },
  {
    key: 'phone',
    title: 'Telefono',
    type: 'text',
    validations: {
      rules: {
        required: true,
        max: 12,
        min: 8,
      },
    },

    placeholder: 'Telefono',
    value: null,
    cols: 12,
    customClass: 'inputLanding',
    preppendClass: 'inputLanding-custom-phone bg-white',
    iconLeft: getImageFlag(window.location.origin),
  },
  {
    key: 'asset',
    title: 'Tipo de mercancia transportada',
    type: 'select',
    validations: {
      rules: {
        required: true,
      },
    },
    selectLabel: null,
    placeholder: 'Elije el tipo',
    value: null,
    cols: 12,
    customClass: 'inputLanding',
    options: [],
  },
]);

const handleOnSubmit = ({ items, isFormValid }: any) => {
  if (!isFormValid) return;
  if (!toogleIndemnizationLimit.value) {
    if (countryInitial.value === 'ARGENTINA') {
    //   isButtonDisabled.value = true;
    }
    return (toogleIndemnizationLimit.value = true);
  }
  const newAudit = {
    email: items.email,
    phone: items.phone,
    asset: items.asset.nombre,
  };

  createAudits(newAudit);
  findClient(newAudit.email);

  const searchParams = route.query;
  const dataStore = {
    ...newAudit,
    asset: items.asset,
    indemnificationLimit: Number(indemnificationLimit.value.toFixed(0)),
    endPrice: endPrice.value,
    queryParams: searchParams,
  };

  store.setProp({ key: 'data', value: dataStore });

  router.push({ name: 'Contratacion' });
};
const handleSliderChange = (data: number) => {
 
  // Calculo de precio final para Argentina

  if (countryInitial.value === 'ARGENTINA') {
    indemnificationLimit.value = data;

	const primaNeta = (data * 0.315) / 100;
	const recargosFinacieros = (primaNeta * 6 / 100);

    if (!dolarPrice.value) {
      return (isButtonDisabled.value = true);
    }
    if (
      (data / 1000 <= dolarPrice.value || data <= Math.round(dolarPrice.value * 250000)) ||
      !toogleIndemnizationLimit.value
    ) {
      isButtonDisabled.value = false;
    } else {
      isButtonDisabled.value = true;
    }

	const endPriceValue = primaNeta + recargosFinacieros;
	return endPrice.value = parseFloat(endPriceValue.toFixed(2));

  }

  // Calculo de precio final para Chile
  if (countryInitial.value === 'CHILE') {
    indemnificationLimit.value = data;
    const value = data * 0.31;

    return (endPrice.value = parseFloat(value.toFixed(2)));
  }

  // Calculo de precio final para Mexico
  indemnificationLimit.value = data;
  const value = calculatePolicyPrice(data);
  endPrice.value = parseFloat(value.toFixed(2));
};

const handleBlur = (data: any) => {
  createAudits({ email: data.value, asset: null, phone: null });
};

const handleValidateSlider = () => {
  if (countryInitial.value === 'CHILE' && dolarPrice.value && UFvalue.value) {
    return true;
  } else if (countryInitial.value === 'ARGENTINA' && dolarPrice.value) {
    return true;
  } else if (countryInitial.value === 'MEXICO') {
    return true;
  }

  isButtonDisabled.value = false;
  return false;
};
</script>

<template>
  <div class="form">
    <div className="form__container">
      <h3 class="form__title">COTIZA GRATIS</h3>
      <Form
        ref="refTableForm"
        :inputs="fields"
        @onSubmit="handleOnSubmit"
        @blur="handleBlur"
      >
        <template #buttons>
          <div v-if="toogleIndemnizationLimit">
            <h3 class="form__title">
              ¿Que tipo de límite de indemnización necesita?
            </h3>
            <div class="form__limitIndemnization">
              <p class="form__subtitle">Seleccione límite de indemnización</p>
              <div class="form__slider" v-if="handleValidateSlider()">
                <Slider
                  @value="handleSliderChange"
                  :countryInitial="countryInitial"
                  :dolarPrice="dolarPrice ?? undefined"
                  :UFvalue="UFvalue ?? undefined"
                />
              </div>
              <div class="form__endPrice-container">
                <p class="form__endPrice">
                  <span>Precio Final:</span>
                  <span class="form__endPrice-span">$ {{ endPrice }}</span> +
                  impuestos
                </p>
              </div>
            </div>
            <span class="form__span" v-if="countryInitial === 'MEXICO'"
              >* Valores expresados en pesos mexicanos (MXN)</span
            >
            <span class="form__span" v-if="countryInitial === 'ARGENTINA'"
              >* Valores expresados en pesos argentinos (ARS).</span
            >
            <span class="form__span" v-if="countryInitial === 'ARGENTINA'"
              > Precio final aproximado puede variar de forma minima</span
            >
            <span class="form__span" v-if="countryInitial === 'CHILE'"
              >* Valores expresados en unidad de fomento (UF)</span
            >
          </div>
          <div class="d-flex">
            <button
              class="p-4 w-100 my-2 px-2 background-green color-white border-0 rounded-4"
              style="font-weight: bold; font-size: 2rem"
              :disabled="isButtonDisabled"
              type="submit"
            >
              QUIERO EL SEGURO
            </button>
          </div>
        </template>
      </Form>
    </div>
  </div>
</template>
