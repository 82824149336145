export function calculatePolicyPrice(indemnificationLimit: number): number {
    let tasa = 0;
    if (indemnificationLimit < 2000000) {
      tasa = 0.31500;
    } else if (indemnificationLimit < 2500000) {
      tasa = 0.3308;
    } else if (indemnificationLimit < 3000000) {
      tasa = 0.35720;
    } else if (indemnificationLimit < 3500000) {
      tasa = 0.39290;
    } else if (indemnificationLimit < 4000000) {
      tasa = 0.44010;
    } else if (indemnificationLimit < 4500000) {
      tasa = 0.50170;
    } else {
      tasa = 0.58200;
    }  
    return (indemnificationLimit * (tasa / 100) + 150);
  }