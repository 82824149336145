<script setup lang="ts">
import { toRefs } from 'vue';
import { IPolicy } from '../../types/policy.type';

const props = defineProps({
  clientData: {
    type: Object as () => IPolicy,
    default: () => ({
      client: {
        tradeName: '',
        rfc: '',
        cuit: '',
        address: '',
        postCode: '',
        state: {
          description: '',
        },
        city: {
          description: '',
        },
        neighborhood: {
          description: '',
        },
        phone1: '',
        phone2: '',
        chubbTranId: '',
        chubbPersonaId: '',
        chubbDireccionId: '',
      },
    }),
  },
});

const { clientData } = toRefs(props);
const { client } = clientData.value;
</script>

<template>
  <template v-if="client.rfc">
    <p><strong>Nombre:</strong> {{ client.tradeName }}</p>
    <p><strong>RFC:</strong> {{ client.rfc }}</p>
    <p><strong>Dirección:</strong> {{ client.address }}</p>
    <p><strong>CP:</strong> {{ client.postCode }}</p>
    <p><strong>Estado:</strong> {{ client.state.description }}</p>
    <p><strong>Municipio:</strong> {{ client.city.description }}</p>
    <p><strong>Colonia:</strong> {{ client.neighborhood.description }}</p>
    <p><strong>Teléfono 1:</strong> {{ client.phone1 }}</p>
    <p v-if="client.phone2"><strong>Teléfono 2:</strong> {{ client.phone2 }}</p>
    <p><strong>ChubbTranId:</strong> {{ client.chubbTranId }}</p>
    <p><strong>ChubbPersonaId:</strong> {{ client.chubbPersonaId }}</p>
    <p><strong>ChubbDireccionId:</strong> {{ client.chubbDireccionId }}</p>
  </template>
  <template v-else-if="client.cuit">
    <p><strong>Nombre:</strong> {{ client.tradeName }}</p>
    <p><strong>CUIT:</strong> {{ client.cuit }}</p>
    <p><strong>Dirección:</strong> {{ client.address }}</p>
    <p><strong>CP:</strong> {{ client.postCode }}</p>
    <p><strong>Provincia:</strong> {{ client.regionProvince }}</p>
    <p><strong>Localidad:</strong> {{ client.location }}</p>
    <p><strong>Teléfono 1:</strong> {{ client.phone1 }}</p>
    <p v-if="client.phone2"><strong>Teléfono 2:</strong> {{ client.phone2 }}</p>
  </template>
</template>
