
import { ref } from "vue";
const USER = import.meta.env.VITE_USER_DEVELOMENT
const PASSWORD = import.meta.env.VITE_PASSWORD_DEVELOMENT

export const inputsLogin = ref<Array<any>>([
  {
    key: "email",
    type: "email",
    validations: {
      rules: {
        required: true
      },
    },
    placeholder: "Email",
    //! USER solo existirá en un entorno de desarrollo     
    value: USER ?? '',
    state: null,
    customClass: 'my-2 lg-input shadow-input',
    listenFocus: true,
    preppendClass: 'input-group-prepend shadow-preppend',
    iconLeft: '/icons/user_perfil.svg'
  },
  {
    key: "password",
    type: "password",
    validations: { rules: { required: true } },
    placeholder: "password",
    //! PASSWORD solo existirá en un entorno de desarrollo               
    value: PASSWORD ?? '',
    state: null,
    customClass: 'my-2 lg-input shadow-input input-password',
    listenFocus: true,
    preppendClass: 'input-group-prepend shadow-preppend',
    iconLeft: '/icons/ojo.svg'
  },
])
export const twoFactorLogin = ref<Array<any>>([
  {
    key: "codeTwoFactor",
    type: "text",
    validations: {
      rules: {
        required: true
      },
    },
    placeholder: "******",
    value: '',
    state: null,
    customClass: 'my-2 lg-input shadow-input',
    listenFocus: true,
    preppendClass: 'input-group-prepend shadow-preppend',
    iconLeft: '/icons/user_perfil.svg'
  },
])
