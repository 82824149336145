import $http from "../services/AxiosInstance";

interface Params {
  page?: number,
}
interface Body {
  email1?: string,
  email2?: string,
  email3?: string,
  apiKeyId: number,
  assetID: number,
}

class NumerationPolicyRepository {
  async getNumerationPolicies(params: Params) {    
    try {
      const response = await $http.get(`/integrations/asset/numeration?page=${params.page}`);

      return response.data;
    } catch (errors: any) {
      return errors;
    }
  }
  async createNumerationPolicies(body: Body) {
    try {
      const response = await $http.post('/integrations/asset/numeration', { ...body });

      return response.data;
    } catch (errors: any) {
      return errors;
    }
  }


}

export default NumerationPolicyRepository;
