<script lang='ts' setup>
import storeAuth from "@store/auth";
import { useRouter } from 'vue-router';
import autenticacionRepository from "@repositories/autenticacion.repository";

const { logout } = storeAuth()

const router = useRouter()

const autenticacionController = new autenticacionRepository()

const closeSession = async () => {
    try {
        const result = await autenticacionController.logout()
        logout()
        router.push({name:'Login'})
    } catch (error) {
        router.push({name:'Login'})
    }
}
closeSession()

</script>
<template>
    <div class="container d-flex justify-content-center align-items-center align-content-center h-100">
        <Overlay :show="true" />
    </div>
</template>