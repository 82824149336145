<script setup lang="ts">
const openWhatsappPage = () => {
  const whatsappAgent = '+525546194648';
  window.open(
    `https://wa.me/${whatsappAgent}?text=Hola,%20te%20contacto%20por%20medio%20de%20la%20APP%20de%20Tryger.`,
    '_blank'
  );
};
</script>

<template>
  <div class="whatsappButton" @click="openWhatsappPage">
    <img
      src="/icons/whatsapp.svg"
      alt="logo-whatsapp"
      class="whatsappButton__img"
    />
    <div class="whatsappButton__text-container">
      <p class="whatsappButton__text">¿Tienes dudas?</p>
      <p class="whatsappButton__text">Contacta a nuestro <span>EQUIPO</span></p>
    </div>
  </div>
</template>

<style scoped>
.whatsappButton {
  background-color: #25b350;
  width: 19rem;
  position: fixed;
  bottom: 10px;
  right: 10px;
  padding: 0.5rem 1rem;
  border-radius: 10px;
  display: flex;
  gap: 1rem;
  cursor: pointer;
}
@media (min-width: 768px) {
  .whatsappButton {
    right: 40px;
  }
}
.whatsappButton__img {
  width: 30px;
}
.whatsappButton__text {
  margin: 0px;
  color: #fff;
}
.whatsappButton__text span {
  text-decoration: underline;
  font-weight: bold;
}
</style>
