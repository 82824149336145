const APP_CLIENT_ARG: string = import.meta.env.VITE_APP_CLIENT_ARG;
const APP_CLIENT_CHI: string = import.meta.env.VITE_APP_CLIENT_CHI;
const APP_CLIENT_MEX: string = import.meta.env.VITE_APP_CLIENT_MEX;
const APP_CLIENT: string = import.meta.env.VITE_APP_CLIENT;
const currentDomain = window.location.origin;

interface DomainToCountryInitialMap {
  [key: string]: string;
}

const domainToCountryInitialMap: DomainToCountryInitialMap = {
  [APP_CLIENT]: 'MEXICO',
  [APP_CLIENT_MEX]: 'MEXICO',
  [APP_CLIENT_CHI]: 'CHILE',
  [APP_CLIENT_ARG]: 'ARGENTINA',
};

const getCountryInitial = (): string => {
  return domainToCountryInitialMap[currentDomain];
};

export default getCountryInitial;
