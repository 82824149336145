import $http from "../services/AxiosInstance";
import { Routes } from "@services/utils/Routes";
import { IAgentsResponse, IAgents } from "src/types/agents.type";

class AgentsDataProviderRepository {
  async createAgent(agents: IAgents): Promise<IAgentsResponse> {
    try {
      return await $http.post(Routes.AGENTES.INDEX, agents);
    } catch (error: any) {
      return {
        errores: true,
        errors: error?.errors ?? [],
        message: error?.message ?? null
      }
    }
  }
  async updateAgent(item: IAgents, id: number): Promise<IAgentsResponse> {
    try {
      const response = await $http.patch(`${Routes.AGENTES.UPDATE}/${id}`, item);
      return response.data;
    } catch (error: any) {
      return {
        errores: true,
        errors: error?.errors ?? [],
        message: error?.message ?? null
      }
    }
  }
  async indexAgents(page?: number, deleted?: boolean): Promise<IAgentsResponse> {
    try {
      const response = await $http.get(`${Routes.AGENTES.INDEX}${!!page ? '?page=' + page : ''}${!!deleted ? '&deleted=true' : ''}`);
      return response.data;
    } catch (errors: any) {
      return errors;
    }
  }
  async deleteAgent(id: number, restore: boolean): Promise<IAgents> {
    try {
      const response = !restore
        ? await $http.delete(`${Routes.AGENTES.DELETE}/${id}`)
        : await $http.patch(`${Routes.AGENTES.RESTORE}/${id}`);
      return response.data;
    } catch (errors: any) {
      return errors;
    }
  }
}

export default AgentsDataProviderRepository;
