<script lang="ts" setup>
import { ref, reactive, onMounted } from 'vue';
import { authorization } from '@services/Autorizathion';

// hooks
import useDataProvider from '../../hooks/useDataProvider';

// Pinia
import { storeToRefs } from 'pinia';
import dataProvider from '@store/dataProvider';

// utils
import { Routes } from '@services/utils/Routes';

const storeDataProvider = dataProvider();
const { companiasGPS }: any = storeToRefs(storeDataProvider);
const { getDataProviders } = useDataProvider();

onMounted(() => {
  getDataProviders(['companiasGPS']);
});

const fields: any = ref([
  {
    key: 'id',
    label: '# Compañia',
    sortable: true,
  },
  {
    key: 'description',
    label: 'Nombre',
  },
  {
    key: 'email_1',
    label: 'Email 1',
  },
  {
    key: 'email_2',
    label: 'Email 2',
  },
  {
    key: 'email_3',
    label: 'Email 3',
  },
  {
    key: 'email_4',
    label: 'Email 4',
  },
  {
    key: 'slug',
    label: 'Etiqueta',
  },
  {
    key: 'edit',
    label: 'Editar',
  },
  {
    key: 'delete',
    label: 'Eliminar',
  },
]);

const filterInputs = ref<Array<any>>([
  {
    key: 'description',
    type: 'text',
    placeholder: 'Nombre',
    value: '',
    state: null,
    cols: 4,
  },
]);

const formInputs = ref<Array<any>>([
  {
    key: 'description',
    type: 'text',
    placeholder: 'Nombre',
    title: 'Nombre',
    value: null,
    state: null,
    cols: 12,
    validations: {
      rules: {
        required: true,
      },
    },
  },
  {
    key: 'email_1',
    type: 'email',
    placeholder: 'Email',
    title: 'Email 1',
    value: null,
    state: null,
    cols: 12,
  },
  {
    key: 'email_2',
    type: 'email',
    placeholder: 'Email',
    title: 'Email 2',
    value: null,
    state: null,
    cols: 12,
  },
  {
    key: 'email_3',
    type: 'email',
    placeholder: 'Email',
    title: 'Email 3',
    value: null,
    state: null,
    cols: 12,
  },
  {
    key: 'email_4',
    type: 'email',
    placeholder: 'Email',
    title: 'Email 4',
    value: null,
    state: null,
    cols: 12,
  },
  {
    key: 'slug',
    type: 'text',
    placeholder: 'Añade una etiqueta unica a la compañia',
    title: 'Etiqueta',
    value: null,
    state: null,
    cols: 12,
  },
]);

const propTable = reactive({
  fields: fields.value,
  inputs: filterInputs.value,
  url: Routes.COMPANIASGPS.INDEX,
  requestConfiguration: {
    method: 'GET',
    headers: {
      Authorization: authorization(),
    },
  },
  searchIcon: '/icons/search.svg',
  searchable: true,
});

const propModalForm = reactive({
  inputs: formInputs.value,
  urlStore: Routes.COMPANIASGPS.STORE,
  urlUpdate: Routes.COMPANIASGPS.UPDATE,
  urlShow: Routes.COMPANIASGPS.SHOW,
  afterUpdate: (data: any) => {
    let index = storeDataProvider.companiasGPS.findIndex(
      (value: any) => value.id === data.id,
    );
    if (index > -1) {
      storeDataProvider.setProp({ key: 'companiasGPS', index, value: data });
    }
  },
  afterStore: (data: any) => {
    if (Array.isArray(companiasGPS.value)) {
      storeDataProvider.setProp({
        key: 'companiasGPS',
        value: [data, ...storeDataProvider.companiasGPS],
      });
    }
  },

  showRequestConfiguration: {
    method: 'GET',
    headers: {
      Authorization: authorization(),
    },
  },
  updateRequestConfiguration: {
    method: 'PATCH',
    headers: {
      Authorization: authorization(),
      Accept: 'application/json',
      'Content-type': 'application/json; charset=UTF-8',
    },
  },
  storeRequestConfiguration: {
    method: 'POST',
    headers: {
      Authorization: authorization(),
      Accept: 'application/json',
      'Content-type': 'application/json; charset=UTF-8',
    },
  },
  updateDefaultParams: { _method: 'PATCH' },
});

const propAbm = reactive({
  editIcon: `/icons/editar.svg`,
  deleteIcon: `/icons/basura.svg`,
  restoreIcon: `/icons/restaurar.svg`,
  afterDelete: (data: any) => {
    if (Array.isArray(companiasGPS.value) && companiasGPS.value.length > 0) {
      const deleted = companiasGPS.value.filter(
        (val: any) => val.id !== data.data.id,
      );
      storeDataProvider.setProp({ key: 'companiasGPS', value: deleted });
    }
  },
  softDelete: true,
  deleteRequestConfiguration: {
    method: 'DELETE',
    headers: {
      Authorization: authorization(),
    },
  },
});
</script>

<template>
  <Layout title="Compañias GPS" :textColor="'color-secondary'">
    <template #layout>
      <div class="container-fluid">
        <Abm
          :table="propTable"
          :modalForm="propModalForm"
          :urlDelete="Routes.COMPANIASGPS.DELETE"
          :afterDelete="propAbm.afterDelete"
          :editIcon="propAbm.editIcon"
          :deleteIcon="propAbm.deleteIcon"
          :restoreIcon="propAbm.restoreIcon"
          :softDelete="propAbm.softDelete"
          :deleteRequestConfiguration="propAbm.deleteRequestConfiguration"
          @afterUpdate="propModalForm.afterUpdate"
          @afterStore="propModalForm.afterStore"
          @afterDelete="propAbm.afterDelete"
          :searchButtonConfig="{ background: 'background-blue' }"
          :storeButtonConfig="{ background: 'background-blue' }"
          :activeButtonConfig="{ activeClass: 'background-pink' }"
        />
      </div>
    </template>
  </Layout>
</template>
