import $http from "../services/AxiosInstance";
import { Routes } from "@services/utils/Routes";
import { IClienteResponse } from "src/types/client.type";

class ClientsDataProviderRepository {
  async indexClient(): Promise<IClienteResponse> {
    try {
      const response = await $http.get(Routes.CLIENTES.ALL);
      return response.data;
    } catch (errors: any) {
      return errors;
    }
  }
}

export default ClientsDataProviderRepository;
