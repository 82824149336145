<script lang="ts" setup>
import moment from 'moment';
import { ref, onMounted } from 'vue';
import './Polizas.scss';
import PolicyRepository from '../../repositories/policies.repository';
import { IPolicie } from '../../types/policies.type';
import storeAuth from '@store/auth';
import { formatterPrice } from '../../helpers/formatterPrice';

const { user } = storeAuth();

const policyRepository = new PolicyRepository();
const policiesData = ref<IPolicie[]>([]);
const paginationData = ref();
const refTableForm = ref<any>(null);
const isOpenDialog = ref<boolean>(false);
const idDeleteItem = ref<number | null>(null);
const currentPage = ref<number>();

onMounted(async () => {
  const { policies } = await policyRepository.getPolicies({ page: 1 });
  policiesData.value = policies.policies ?? [];
  paginationData.value = policies.pagination;
});

const fields: any = ref([
  {
    key: 'startDate',
    title: 'Fecha de inicio',
    type: 'date',
    placeholder: 'Fecha de inicio',
    value: null,
    cols: 2,
  },
  {
    key: 'endDate',
    title: 'Fecha de fin',
    type: 'date',
    placeholder: 'Fecha de fin',
    value: null,
    cols: 2,
  },
  {
    key: 'company',
    title: 'Compañía',
    type: 'select',
    placeholder: 'Elije la compañia',
    value: null,
    selectLabel: null,
    cols: 2,
    hidden: user?.apiKeyId ? true : false,
    options: [
      { nombre: 'Vink', id: 1 },
      { nombre: 'ControlMatch', id: 2 },
    ],
  },
]);

const fieldsTable: any = ref([
  {
    key: 'id',
    label: '# Operación',
    sortable: true,
  },
  {
    key: 'insuredName',
    label: 'Cliente',
    sortable: true,
  },
  {
    key: 'asset',
    label: 'Id de carga',
    formatter: (e: any) => e?.id || null,
  },
  {
    key: 'policyType',
    label: 'Tipo de carga',
  },
  {
    key: 'asset',
    label: 'Descripción de la carga',
    formatter: (e: any) => e?.description || null,
  },
  {
    key: 'customCatalogIds',
    label: 'ids del catalogo',
  },
  {
    key: 'customCatalog',
    label: 'Description del catalogo',
  },
  {
    key: 'createdAt',
    label: 'Fecha de creación',
    formatter: (e: any) => moment(e).format('DD-MM-YYYY HH:mm'),
  },
  {
    key: 'status',
    label: 'Estatus',
    formatter: (e: any) => (e == 'in_progress' ? 'En progreso' : 'Cancelada'),
  },
  {
    key: 'indemnificationLimit',
    label: 'Suma Asegurada',
    formatter: (e: any) => (e ? formatterPrice(e) : null),
  },
  {
    key: 'actions',
    label: 'Acciones',
  },
]);

const handleDelete = ({ id }: any) => {
  isOpenDialog.value = true;
  idDeleteItem.value = id;
};
const handleConfirmDelete = async () => {
  if (idDeleteItem.value !== null) {
    await policyRepository.deletePolicy(idDeleteItem.value);
  }
  onChangePage(currentPage.value);
  isOpenDialog.value = false;
};

const getPoliciesSubmit = async ({ params, page = 1 }: any) => {
  const { startDate, endDate, company } = params;
  const { policies } = await policyRepository.getPolicies({
    page,
    startDate,
    endDate,
    company: company?.id,
  });
  policiesData.value = policies.policies ?? [];
  paginationData.value = policies.pagination;
};

const onChangePage = async (page: any) => {
  const { items } = await refTableForm.value.getValues();
  getPoliciesSubmit({ params: items, page });
  currentPage.value = page;
};

const downloadExcel = async () => {
  const { items } = await refTableForm.value.getValues();
  const { startDate, endDate, company } = items;
  await policyRepository.getExcelPolicies({
    startDate,
    endDate,
    company: company?.id || null,
    toExcel: true,
  });
};

const handleOnSubmit = async ({ items, isFormValid }: any) => {
  if (!isFormValid) return;
  getPoliciesSubmit({ params: items });
};

const getRowTable = (policiesData: any, type: string) => {
  const catalogue = policiesData?.data?.item?.catalogue;

  if (!catalogue) {
    return null;
  }

  const concatenatedString = catalogue
    .map((item: any) => {
      switch (type) {
        case 'descriptions':
          return [item.description, item.otherDescription]
            .filter(Boolean)
            .join(', ');
        case 'ids':
          return [item.id].filter(Boolean).join(', ');
        default:
          return '';
      }
    })
    .join(', ');

  return concatenatedString || null;
};
</script>
<template>
  <Layout title="Lista de Polizas" :textColor="'color-secondary'">
    <template #layout>
      <div class="poliza">
        <Form
          ref="refTableForm"
          :inputs="fields"
          @onSubmit="handleOnSubmit"
          customClass="poliza__form"
        >
          <template #buttons>
            <CButton
              class="background-blue button__form"
              style="padding: 0.55rem !important"
            >
              <template #content> Filtrar </template>
            </CButton>
            <CButton
              class="background-blue button__form"
              style="padding: 0.55rem !important"
              type="button"
              @click="downloadExcel"
            >
              <template #content> Descargar Excel </template>
            </CButton>
          </template>
        </Form>
        <Table
          :fields="fieldsTable"
          :items="policiesData"
          @update:model-value-page="onChangePage"
          :totalRows="paginationData?.count"
          :perPage="20"
          :currentPage="paginationData?.current_page"
          :hover="true"
        >
          <template v-if="true" v-slot:cell(actions)="policiesData">
            <div>
              <button
                class="background-pink p-1 border-0 w-100 text-white rounded-4"
                @click="handleDelete(policiesData.data.item)"
              >
                CANCELAR
              </button>
            </div>
          </template>
          <template v-if="true" v-slot:cell(customCatalog)="policiesData">
            <div class="d-flex align-self-center">
              <p
                class="truncatedText"
                :title="getRowTable(policiesData, 'descriptions')"
              >
                {{ getRowTable(policiesData, 'descriptions') }}
              </p>
            </div>
          </template>
          <template v-if="true" v-slot:cell(customCatalogIds)="policiesData">
            <div class="d-flex align-self-center">
              <p
                class="truncatedText"
                :title="getRowTable(policiesData, 'ids')"
              >
                {{ getRowTable(policiesData, 'ids') }}
              </p>
            </div>
          </template>
        </Table>
        <Dialog
          :centered="true"
          :visible="isOpenDialog"
          @cancel="isOpenDialog = false"
          @confirm="handleConfirmDelete"
        />
      </div>
    </template>
  </Layout>
</template>

<style scoped>
.truncatedText {
  max-width: 15rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  margin: 0;
}
</style>
