import $http from "../services/AxiosInstance";
import { Routes } from "@services/utils/Routes";
import { ICompaniasGPSResponse } from "src/types/companiasGPS.type";

interface Params {
  models: any
}
class DataProviderRepository {
  async indexCompaniasGPS(params?: Params): Promise<ICompaniasGPSResponse> {
    try {
      const response = await $http.get(Routes.COMPANIASGPS.ALL, { params });
      return response.data;
    } catch (error: any) {
      return {
        errores: true,
        errors: error?.errors ?? [],
        message: error?.message ?? null
      }
    }
  }
}

export default DataProviderRepository;
