<script lang="ts" setup>
import { ref, onMounted } from 'vue';
import { formatDateTime, getStatus } from '@services/utils/Formatters';
import OperationsRepository from '@repositories/operations.repository';
import { BModal } from 'bootstrap-vue-next';
import ModalContentClient from './ModalContentClient.vue';
import ModalContentPolicyMX from './ModalContentPolicyMX.vue';
import ModalContentPolicyAR from './ModalContentPolicyAR.vue';
import Store from '@store/dataProvider';
import { storeToRefs } from 'pinia';
import { IPolicy } from '../../types/policy.type';

const store = Store();
const { operations }: any = storeToRefs(store);

const operationsRepository = new OperationsRepository();
const operationsData = ref([]);
const paginationData = ref();
const currentPage = ref<number>(1);
const isModalOpen = ref<boolean>(false);
const selectedClientInfo = ref<IPolicy | null>(null);
const selectedPolicyInfo = ref<IPolicy | null>(null);
const modalType = ref<string>('');

const getAuditsAPI = async (page: number) => {
  const { data } = await operationsRepository.getOperations({
    page,
  });
  operationsData.value = data.data ?? [];
  paginationData.value = data.pagination;
  store.setProp({ key: 'operations', value: operationsData.value });
};

onMounted(async () => {
  getAuditsAPI(1);
});
const fields: any = ref([
  {
    key: 'id',
    label: '# Operación',
    sortable: true,
  },
  {
    key: 'createdAt',
    label: 'Fecha y hora de creación',
    formatter: (item: string) => formatDateTime(item),
  },
  {
    key: 'client',
    label: 'Cliente',
    formatter: (item: any) => item?.tradeName,
  },
  {
    key: 'companyGPS',
    label: 'Empresa GPS',
    formatter: (item: any) => item?.description,
  },
  {
    key: 'status',
    label: 'Estatus',
    formatter: (item: any) => getStatus(item),
  },
  {
    key: 'clientInfo',
    label: 'Cliente',
  },
  {
    key: 'policyInfo',
    label: 'Poliza',
  },
]);

const onChangePage = async (page: any) => {
  currentPage.value = page;
  getAuditsAPI(page);
};
const handleSelectClient = (clientInfo: any) => {
  selectedClientInfo.value = clientInfo;
  modalType.value = 'modalClient';
  isModalOpen.value = true;
};
const handleSelectPolicy = (policyInfo: any) => {
  selectedPolicyInfo.value = operations.value.find(
    (e: any) => policyInfo.id === e.id,
  );
  modalType.value = 'modalPolicy';
  isModalOpen.value = true;
};

const handleClose = () => {
  isModalOpen.value = false;
  selectedClientInfo.value = null;
  selectedPolicyInfo.value = null;
  modalType.value = '';
};
const updateCurrentPolicyClient = (data: any) => {
  if (!selectedPolicyInfo.value) return;
  selectedPolicyInfo.value.client = data;
};
const updateCurrentPolicy = (data: any) => {
  selectedPolicyInfo.value = data;
};
</script>

<template>
  <Layout title="Operaciones" :textColor="'color-secondary'">
    <template #layout>
      <Table
        :fields="fields"
        :items="store.operations"
        @update:model-value-page="onChangePage"
        :totalRows="paginationData?.count"
        :perPage="20"
        :currentPage="paginationData?.current_page"
      >
        <template v-if="true" v-slot:cell(clientInfo)="operationsData">
          <div @click="handleSelectClient(operationsData.data.item)">
            <img
              src="/icons/user_panel.svg"
              style="width: 40px; cursor: pointer"
            />
          </div>
        </template>
        <template v-if="true" v-slot:cell(policyInfo)="operationsData">
          <div @click="handleSelectPolicy(operationsData.data.item)">
            <img
              src="/icons/policy_panel.svg"
              style="width: 40px; cursor: pointer"
            />
          </div>
        </template>
      </Table>
      <BModal
        :modelValue="isModalOpen"
        hide-footer
        :centered="true"
        @hide="handleClose"
        static
        :title="
          modalType === 'modalClient'
            ? 'Información del cliente'
            : 'Información de póliza'
        "
      >
        <template v-if="modalType === 'modalClient'">
          <ModalContentClient
            :clientData="selectedClientInfo"
            v-if="selectedClientInfo"
          />
        </template>
        <template v-else>
          <ModalContentPolicyMX
            v-if="selectedPolicyInfo && selectedPolicyInfo.country.description==='MEXICO'"
            :policyData="selectedPolicyInfo"
            @updateCurrentPolicyClient="updateCurrentPolicyClient($event)"
            @updateCurrentPolicy="updateCurrentPolicy($event)"
          />
		  <ModalContentPolicyAR
            v-else-if="selectedPolicyInfo"
            :policyData="selectedPolicyInfo"
           
          />
        </template>
      </BModal>
    </template>
  </Layout>
</template>
