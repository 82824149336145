import $http from '../services/AxiosInstance';

export interface IProvince {
  id: string;
  nombre: string;
}
interface ICity {
  id: string;
  nombre: string;
}

class GeoRefArgentinaRepository {
  static async getprovinces(): Promise<IProvince[]> {
    try {
      let url = 'https://apis.datos.gob.ar/georef/api/provincias';

      const response = await $http.get(url);

      const provincias = response.data.provincias.map(
        (provincia: IProvince) => {
          let nombre = provincia.nombre
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toUpperCase();

          if (
            nombre === 'TIERRA DEL FUEGO, ANTARTIDA E ISLAS DEL ATLANTICO SUR'
          ) {
            nombre = 'TIERRA DEL FUEGO';
          }

          return {
            id: provincia.id,
            nombre: nombre,
          };
        },
      );

      provincias.push({ id: 99, nombre: 'EXTERIOR' });

      return provincias;
    } catch (errors: any) {
      throw Error;
    }
  }
  static async getCities(provinceId: string): Promise<ICity[]> {
    try {
      let url = `https://apis.datos.gob.ar/georef/api/localidades?provincia=${provinceId}&campos=id,nombre&max=1000`;

      const response = await $http.get(url);
      response.data.localidades.map((localidad: ICity) => ({
        id: localidad.id,
        nombre: localidad.nombre,
      }));
      const uniqueLocalidades = response.data.localidades.reduce(
        (acc: ICity[], localidad: ICity) => {
          const isDuplicate = acc.some(
            (city) => city.nombre === localidad.nombre,
          );
          if (!isDuplicate) {
            acc.push({ id: localidad.id, nombre: localidad.nombre });
          }
          return acc;
        },
        [],
      );

      return uniqueLocalidades;
    } catch (errors: any) {
      throw Error;
    }
  }
}

export default GeoRefArgentinaRepository;
