<script lang="ts" setup>
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import './Login.scss';
import storeAuth from '@store/auth';

import { inputsLogin, twoFactorLogin } from '@services/forms/inputsLogin';

import AuthenticationApi from '@repositories/autenticacion.repository';
import TemplatesUIDataProviderRepository from '@repositories/templatesUI.repository';

const { login } = storeAuth();
const router = useRouter();

const autenticacionController = new AuthenticationApi();
const templateUIController = new TemplatesUIDataProviderRepository();
const imageLogin = ref<string>('');
const titleLogin = ref<string>('');
const refForm = ref<any>(null);
const refFormTwoFactor = ref<any>(null);
const errorMessages = ref<Array<string>>([]);
const loading = ref<boolean>(false);
const qrCode = ref<string>('');
const userId = ref('');
const passwordVisible = ref<boolean>(false);
  const loginImage = ref<string>('img/avatar.svg');

onMounted(() => {
  imageLogin.value = '/img/bg_home.png';
  addEventViewPassword();
});
onMounted(async () => {
  const fullPath = window.location.origin;
  const  data  = await templateUIController.getNameTemplate(fullPath);  
  titleLogin.value = data.toString();  
});

const resetFocus = () => {
  loginImage.value = 'img/avatar.svg';
};

const handleErrors = (error: any) => {
  if (error.errors) {
    error.errors.forEach((k: any) => {
      errorMessages.value.push(k.msg);
    });
  }
  return errorMessages.value.push(error.message);
};

const handleFocus = (inputKey: any) => {
  if (inputKey.input.key === 'user') {
    loginImage.value = 'img/touch_user.svg';
  } else if (
    inputKey.input.key === 'password' ||
    inputKey.input.key === 'codeTwoFactor'
  ) {
    loginImage.value = 'img/touch_password.svg';
  } else {
    resetFocus();
  }
};

const loginUser = async () => {
  resetFocus();
  if (refForm.value) {
    const { isFormValid, items } = await refForm.value.getValues();
    if (isFormValid) {
      errorMessages.value = [];
      loading.value = true;
      try {
        const result = await autenticacionController.login(items);
        handleLoginResult(result);
      } catch (error) {
        loading.value = false;
      }
    } else {
      errorMessages.value.push('Debes completar todos los campos');
    }
  }
};

const handleLoginResult = (result: any) => {
  loading.value = false;
  const { error, user, token, refreshToken, qrCodeDataUrl } = result;
  if (error) {
    handleErrors(error);
  } else {
    login({ token, user, refreshToken });
    qrCode.value = qrCodeDataUrl;
    userId.value = user.id;
    router.push({ name: 'Operaciones' });
  }
};

const sendCode = async () => {
  if (refFormTwoFactor.value) {
    errorMessages.value = [];
    const { isFormValid, items } = await refFormTwoFactor.value.getValues();
    if (!isFormValid) {
      return;
    }
    const userData = {
      code: items.codeTwoFactor,
      id: userId.value,
    };
    const result = await autenticacionController.login2fa(userData);
    handleLoginResult(result);
  }
};

const addEventViewPassword = () => {
  const divHtml = document.querySelector('.input-password');
  const inputHtml = divHtml?.querySelector('input');
  const imgHtml = divHtml?.querySelector('img');
  imgHtml?.setAttribute('class', 'cursor-pointer');

  imgHtml?.addEventListener('click', function () {
    if (!imgHtml || !inputHtml) {
      return false;
    }
    if (passwordVisible.value) {
      imgHtml.src = '/icons/ojo.svg';
      inputHtml.setAttribute('type', 'password');
    } else {
      imgHtml.src = '/icons/ojo_x.svg';
      inputHtml.setAttribute('type', 'text');
    }
    passwordVisible.value = !passwordVisible.value;
  });
};
</script>

<template>
  <div
    :style="`background-image: url(img/bg_home.png)`"
    class="login-container"
  >
    <div
      class="container d-flex justify-content-center align-items-center h-100"
    >
      <div class="login-container__card p-4">
        <div
          class="d-flex flex-column justify-content-center login-container__firstBox mt-4"
        >
          <div class="text-center my-2 login-container__title">
            ¡Bienvenido a {{ titleLogin || 'Tryger' }}!
          </div>
          <div class="d-flex justify-content-center my-4">
            <img :src="loginImage" />
          </div>
        </div>
        <div
          class="d-flex flex-column justify-content-center align-items-center login-container__form"
        >
          <template v-if="errorMessages.length > 0">
            <div
              v-for="(item, index) in errorMessages"
              :key="index"
              class="alert alert-danger"
            >
              {{ item }}
            </div>
          </template>
          <div v-if="loading">
            <Overlay
              :show="loading"
              :circleColor="'#40a4c6'"
              :borderColor="'#40a4c6'"
            />
          </div>
          <template v-else-if="!qrCode">
            <div class="d-flex justify-content-center">
              <Form :inputs="inputsLogin" @focus="handleFocus" ref="refForm" />
            </div>
            <CButton
              class="py-2 w-100 my-3"
              text="Ingresar"
              :background="`background-blue`"
              :disabled="loading"
              @click="loginUser"
              type="button"
            />
            <div
              class="d-flex justify-content-center contanier-recovery-password mt-3 mb-4"
            >
              <router-link to="/send-mail-recovery">
                <p class="m-0">Olvidé mi contraseña</p>
              </router-link>
            </div>
          </template>

          <template v-else>
            <b-img
              v-if="qrCode && qrCode !== 'ok'"
              :src="`${qrCode}`"
              rounded
              alt="Rounded image"
              class="d-block m-auto w-25"
            />
            <Form
              :inputs="twoFactorLogin"
              @focus="handleFocus"
              ref="refFormTwoFactor"
            />
            <div class="w-25 my-2">
              <CButton
                class="py-2 w-100"
                text="Enviar código"
                :background="`background-blue`"
                :disabled="loading"
                @click="sendCode"
                type="button"
              />
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
