<template>
	<div>
	  <form>
		<div ref="recaptcha"></div>
	  </form>
	</div>
  </template>

<script setup lang="ts">
import { ref, onMounted } from 'vue';

const emits = defineEmits(['onExpired', 'tokenCaptcha']);
const siteKey = import.meta.env.VITE_CAPTCHA_SITE_KEY;
const recaptcha = ref(null);
const recaptchaId = ref(null);

const renderRecaptcha = () => {
	// @ts-ignore
	if (window.grecaptcha) {
	  // @ts-ignore
    recaptchaId.value = window.grecaptcha.render(recaptcha.value, {
      sitekey: siteKey,
      callback: onVerify,
	  'expired-callback': onExpired
    });
  } else {
    setTimeout(renderRecaptcha, 100);
  }
};

const onExpired = () => {
  emits('onExpired');
};

const onVerify = (token: string) => {
  emits('tokenCaptcha', token);
};

onMounted(renderRecaptcha);
</script>
