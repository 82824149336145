<script setup lang="ts">
import { onBeforeUnmount, ref, toRefs } from 'vue';

// Piñia
import StoreAuth from '@store/auth';
import Store from '@store/dataProvider';

// Composables
import { useToast } from '../../../composables/useToast';
import { useMessage } from '../../../composables/useMessage';

// Types
import { ITemplateUI } from '../../../types/templatesUI.type';

// Repositories
import TemplatesUIDataProviderRepository from '../../../repositories/templatesUI.repository';

const URLBACKEND = import.meta.env.VITE_APP_API;
const store = Store();
const storeAuthProvider = StoreAuth();

const { $toast } = useToast();
const { messageHTML, showMessage } = useMessage();

const templatesUIRepository = new TemplatesUIDataProviderRepository();

const props = defineProps({
  templateUI: {
    type: Object as () => ITemplateUI,
    default: () => ({
      templateUI: {
        name: '',
        urlDomain: '',
        imagePath: '',
        primatyColor: '',
      },
    }),
  },
});

const emit = defineEmits<{
  (e: 'closeModal'): void;
}>();

const { templateUI } = toRefs(props);

const refTableForm = ref<any>(null);
const fields: any = ref([
  {
    key: 'name',
    title: 'Nombre',
    type: 'text',
    validations: {
      rules: {
        required: true,
      },
    },
    placeholder: 'Nombre de la plantilla',
    value: templateUI.value.name,
    cols: 12,
  },
  {
    key: 'urlDomain',
    title: 'Url de dominio',
    type: 'text',
    validations: {
      rules: {
        required: true,
        verifyExpreRegular: /^(ftp|http|https):\/\/[^ "]+(?<!\/)$/,
      },
    },
    placeholder: 'https://example.com',
    value: templateUI.value.urlDomain,
    cols: 12,
  },
  {
    key: 'primaryColour',
    title: 'Color principal',
    type: 'color',
    validations: {
      rules: {
        required: true,
      },
    },
    placeholder: 'Color principal',
    value: templateUI.value.primaryColour,
    cols: 12,
  },
  {
    key: 'imagePath',
    title: 'Logo',
    type: 'file',
    placeholder: 'Elije el logo',
    value: null,
    cols: 12,
  },
]);

const handleOnSubmit = async ({ items, isFormValid }: any) => {
  if (!isFormValid) return;

  try {
    const { data } = await templatesUIRepository.updateTemplate(
      templateUI.value.id,
      items
    );
    if (!data) throw new Error();
    let index = store.templatesUI.findIndex(
      (value: any) => value.id === data.id
    );
    if (index > -1) {
      store.setProp({ key: 'templatesUI', index, value: data });
    }
    if (
      storeAuthProvider.user?.template &&
      storeAuthProvider.user.template.id === data.id
    ) {
      storeAuthProvider.setProp({
        key: 'user',
        index: 'template',
        value: data,
      });
    }
    $toast?.success(`Plantilla actualizada con exito`);
    emit('closeModal');
  } catch (error: any) {
    showMessage(error.message, 'error');
  }
};

onBeforeUnmount(() => {
  fields.value.forEach((field: any) => {
    field.value = null;
  });
});
</script>

<template>
  <Form ref="refTableForm" :inputs="fields" @onSubmit="handleOnSubmit">
    <template #buttons>
      <p class="label-global">Logo actual</p>
      <img
        class="w-50 m-2 mt-0 background-gray"
        :src="`${URLBACKEND}/${templateUI.imagePath}`"
        alt="imagen"
      />
      <div v-if="messageHTML" v-html="messageHTML" />
      <div class="d-flex">
        <CButton class="w-100 fs-6 p-2" background="background-blue">
          <template #content>
            <div>
              <span>Actualizar</span>
            </div>
          </template>
        </CButton>
      </div>
    </template>
  </Form>
</template>
