import $http from '../services/AxiosInstance';

interface Params {
  page: number;
}

class NocRepository {
  async getlogsMail(params: Params) {
    try {
      let url = `/noc?page=${params.page}`;

      const response = await $http.get(url);
      return response.data;
    } catch (errors: any) {
      throw Error;
    }
  }
}

export default NocRepository;
