import { defineStore } from 'pinia';
import { SetProp } from '../types/auth.type';
import { IPolicyStore } from 'src/types/policy.type';

export const usePolicyStore = defineStore('policyStore', {
  state: (): IPolicyStore => ({
    data: {
      asset: null,
      email: null,
      endPrice: null,
      indemnificationLimit: null,
      phone: null,
    },
    client: null,
    pricing: {
      dolar:  null,
      uf: null
    },
  }),
  actions: {
    setProp(payload: SetProp) {
      return new Promise((resolve) => {
        if (payload.index !== undefined) {
          if (payload.prop !== undefined) {
            // @ts-ignore
            this[payload.key as keyof IPolicyStore][payload.index][payload.prop] = payload.value;
            // @ts-ignore
            resolve(this[payload.key as keyof IPolicyStore][payload.index][payload.prop]);
          } else {
            // @ts-ignore
            this[payload.key as keyof IPolicyStore][payload.index] = payload.value;
            // @ts-ignore
            resolve(this[payload.key as keyof IPolicyStore][payload.index]);
          }
        } else {
          // @ts-ignore
          this[payload.key as keyof IPolicyStore] = payload.value;
          resolve(this[payload.key as keyof IPolicyStore]);
        }
      });
    },
  },
  getters: {
    getState: (state) => (key: string): any => {
      return state[key as keyof object];
    },
  },
});

export default usePolicyStore;
